import OrganizationErrorKey from "constants/organization-error-keys";
import OrganizationSupportRecord from "models/view-models/organization-support-record";
import OrganizationSupportService from "utilities/services/organizations/organization-supports-service";
import { useCallback, useEffect, useState } from "react";

export interface UseOrganizationSupportOptions {
    organizationId: number;
}

export default function useOrganizationSupport(
    options: UseOrganizationSupportOptions
) {
    const { organizationId } = options;
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | undefined>();
    const [organizationSupport, setOrganizationSupport] = useState<
        OrganizationSupportRecord | undefined
    >();

    const get = useCallback(async () => {
        try {
            if (organizationId <= 0) {
                return;
            }

            setIsLoading(true);

            const result = await OrganizationSupportService.get({
                organizationId,
            });

            if (result.resultObject === undefined) {
                setError(OrganizationErrorKey.NO_ORGANIZATION_FOUND_ERROR);
            }

            setOrganizationSupport(result.resultObject);
        } catch (error) {
            setError(OrganizationErrorKey.NO_ORGANIZATION_FOUND_ERROR);
        } finally {
            setIsLoading(false);
        }
    }, [organizationId]);

    useEffect(() => {
        get();
    }, [get]);

    return {
        get,
        isLoading,
        organizationSupport,
        error,
    };
}
