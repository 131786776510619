import GroupRecord from "models/view-models/group-record";
import LocalStorageKey from "utilities/enumerations/local-storage-keys";
import RoleRecord from "models/view-models/role-record";
import RoleType from "utilities/enumerations/role-type";
import StringUtils from "utilities/string-utils";
import UserLoginRecord from "models/view-models/user-login-record";
import UserRecord from "models/view-models/user-record";
import UserRoleGroupRecord from "models/view-models/user-role-group-record";
import UserRoleOrganizationRecord from "./user-role-organization-record";
import UserRoleRecord from "models/view-models/user-role-record";
import UserSettingRecord from "models/view-models/user-setting-record";
import moment from "moment";
import type { Identity } from "models/interfaces/identity";
import { CollectionUtils } from "utilities/collection-utils";
import { LocalStorageUtils } from "utilities/local-storage-utils";
import { Record } from "immutable";
import { RecordUtils } from "@rsm-hcd/javascript-core";
import { UserSettingTypes } from "utilities/types/user-setting-types";
import { UserSettingsKeys } from "constants/user-settings-keys";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

/**
 * Additional navigation properties to attach to related entities during construction
 */
interface IdentityNavigationProperties {
    groups?: Array<GroupRecord>;
    roles?: Array<RoleRecord>;
    userRoleGroups?: Array<UserRoleGroupRecord>;
    userRoleOrganizations?: Array<UserRoleOrganizationRecord>;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const defaultValues: Identity = RecordUtils.defaultValuesFactory<Identity>({
    user: undefined,
    userLogin: undefined,
    userRoleOrganizations: undefined,
    userRoles: [],
    userSettings: [],
});

// #endregion Constants

export default class IdentityRecord
    extends Record(defaultValues)
    implements Identity
{
    // -----------------------------------------------------------------------------------------
    // #region Properties
    // -----------------------------------------------------------------------------------------

    // Do NOT set properties on immutable records due to babel and typescript transpilation issue
    // See https://github.com/facebook/create-react-app/issues/6506

    // #endregion Properties

    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: Identity & IdentityNavigationProperties) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.user != null) {
            params.user = RecordUtils.ensureRecord(params.user, UserRecord);
        }

        if (params.userLogin != null) {
            params.userLogin = RecordUtils.ensureRecord(
                params.userLogin,
                UserLoginRecord
            );
        }

        if (CollectionUtils.hasValues(params.userRoles)) {
            params.userRoles = params.userRoles.map((r) =>
                RecordUtils.ensureRecord(r, UserRoleRecord)
            );
        }

        if (CollectionUtils.hasValues(params.userSettings)) {
            params.userSettings = params.userSettings.map((r) =>
                RecordUtils.ensureRecord(r, UserSettingRecord)
            );
        }

        params = attachNavigationProperties(params);

        super(params);
    }

    // #endregion Constructor

    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    /**
     * Finds first uninitialized team where a User is an admin.
     * Uninitialized teams do not have an externalIdentifier.
     */
    public findAdminUserRoleWithUninitializedTeam():
        | UserRoleRecord
        | undefined {
        const uninitializedTeams = this.userRoles.filter(
            (userRole: UserRoleRecord) => {
                return (
                    userRole.userRoleGroup?.group != null &&
                    userRole.userRoleGroup.group.externalIdentifier == null &&
                    userRole.userRoleGroup.isAdmin
                );
            }
        );

        if (uninitializedTeams.length > 0) {
            return uninitializedTeams[0];
        }

        return undefined;
    }

    /**
     * Returns list of unexpired UserRoles from IdentityRecord list of UserRoles.
     *
     * Note: This will only remove expired free trial UserRoles which are not
     * soft-deleted on the back-end when they expire.
     */
    public getActiveUserRoles(): UserRoleRecord[] {
        if (CollectionUtils.isEmpty(this.userRoles)) {
            return [];
        }

        return this.userRoles.filter(
            (userRole: UserRoleRecord) =>
                // If the UserRole has no 'expiresOn', consider it valid. It's probably an admin role.
                StringUtils.isEmpty(userRole.expiresOn) ||
                // Otherwise, ensure the date is sometime in the future
                moment().isBefore(userRole.expiresOn)
        );
    }

    /**
     * Returns a string of the logged in user's current role name for user facing display.
     * For example "Individual", or for a team role "Team: Team name"
     */
    public getCurrentRoleDisplayName(): string {
        if (this.userLogin == null || CollectionUtils.isEmpty(this.userRoles)) {
            return "";
        }

        const currentUserRole = this.userRoles.find(
            (userRole) => userRole.id === this.userLogin?.userRoleId
        );

        if (currentUserRole?.userRoleGroup != null) {
            const teamName =
                currentUserRole?.userRoleGroup.group?.name ??
                "Team name unavailable";
            return "Team: " + teamName;
        }

        return currentUserRole?.role?.name?.replace(" Subscription", "") ?? "";
    }

    /**
     * If current role is a team subscription, return the GroupRecord
     * representing the team.
     */
    public getCurrentTeam(): GroupRecord | undefined {
        const userRoles = this.getActiveUserRoles();
        if (CollectionUtils.isEmpty(userRoles)) {
            return undefined;
        }

        const currentUserRole = this.getCurrentUserRole();
        if (
            currentUserRole == null ||
            (!currentUserRole.role?.is(RoleType.TEAM) &&
                !currentUserRole.role?.is(RoleType.ENTERPRISE))
        ) {
            return undefined;
        }

        return currentUserRole.userRoleGroup?.group;
    }

    /**
     *
     * @returns The current
     */
    public getCurrentTeamId(): number | undefined {
        return this.getCurrentTeam()?.id;
    }

    public isAdminOfCurrentEnterpriseGroup(): boolean {
        const currentUserRole = this.getCurrentUserRole();
        if (currentUserRole == null || currentUserRole.userRoleGroup == null) {
            return false;
        }
        return (
            currentUserRole.isEnterpriseRole() &&
            currentUserRole.userRoleGroup.isAdmin
        );
    }

    /**
     * Returns the user's current UserRole based on the user's UserLogin.UserRoleId.
     * Use this when determining the role the user is currently using.
     */
    public getCurrentUserRole(): UserRoleRecord | undefined {
        return this.userRoles?.find(
            (ur) => ur.id === this.userLogin?.userRoleId
        );
    }

    /**
     * Convenience method for retrieving a specific `UserSettingRecord` by key
     *
     * @param key Strongly typed `UserSettingsKey` to retrieve
     */
    public getUserSetting(
        key: keyof typeof UserSettingsKeys
    ): UserSettingRecord | undefined {
        if (CollectionUtils.isEmpty(this.userSettings)) {
            return undefined;
        }

        return this.userSettings.find((us) => us.key === key);
    }

    /**
     * Convenience method for accessing `UserSettingRecord.getValue<T>()` for a specified key
     *
     * @param key Strongly typed `UserSettingsKey` to retrieve
     */
    public getUserSettingValue<T extends UserSettingTypes>(
        key: keyof typeof UserSettingsKeys
    ): T | undefined {
        return this.getUserSetting(key)?.getValue<T>();
    }

    /**
     * Returns a flat list of RoleRecords the user has (based on list of UserRoleRecords)
     */
    public getRoles(): RoleRecord[] {
        if (CollectionUtils.isEmpty(this.userRoles)) {
            return [];
        }

        return this.userRoles
            .filter((userRole: UserRoleRecord) => userRole.hasRole())
            .map((userRole: UserRoleRecord) => userRole.role!);
    }

    /**
     * Utility method to check if the user has an active subscription based userRole
     */
    public hasActiveSubscription(): boolean {
        if (CollectionUtils.isEmpty(this.userRoles)) {
            return false;
        }

        return this.getActiveUserRoles().some((ur) => ur.isSubscription());
    }

    /**
     * Utility method to check if the user has an active subscription based userRole
     */
    public hasHadFreeTrial(): boolean {
        if (CollectionUtils.isEmpty(this.userRoles)) {
            return false;
        }

        return this.hasRole(RoleType.FREE_TRIAL);
    }

    /**
     * Determines whether or not the user has ANY of the specified role types.
     */
    public hasAnyRole(...types: Array<RoleType>): boolean {
        return this.userRoles.some((userRole: UserRoleRecord) =>
            types.some((type: RoleType) => userRole.isRole(type))
        );
    }

    /**
     * Determines whether or not the user **has** a role of the specified type (based on list of UserRoleRecords)
     */
    public hasRole(type: RoleType): boolean {
        return this.userRoles.some((userRole: UserRoleRecord) =>
            userRole.isRole(type)
        );
    }

    /**
     * Utility function to check system administrator role on the logged in user
     */
    public isAdmin(): boolean {
        return this.isCurrentRole(RoleType.SYS_ADMIN);
    }

    /**
     * Check if user is admin of current team. If not currently using a team profile, returns false.
     */
    public isAdminOfCurrentGroup(): boolean {
        const currentUserRoleGroup = this.getCurrentUserRole()?.userRoleGroup;

        return currentUserRoleGroup?.isAdmin ?? false;
    }

    /**
     * Utility function to check author role on the logged in user
     */
    public isAuthor(): boolean {
        return this.isCurrentRole(RoleType.AUTHOR);
    }

    /**
     * Utility function to check author or publisher role on the logged in user
     */
    public isAuthorOrPublisher(): boolean {
        return this.isAuthor() || this.isPublisher();
    }

    /**
     * Convenience method to check the configuration of the currently logged in user
     */
    public isConfiguredWithActiveSubscription(): boolean {
        if (this.isAdmin()) {
            return true;
        }

        if (!this.isValid() || !this.hasActiveSubscription()) {
            return false;
        }

        // Checking both UserRole and User while converting EulaAccepted to be only on the User record
        return (
            this.user?.externalTopicsUpdatedOn != null &&
            (this.getCurrentUserRole()?.eulaAccepted === true ||
                this.user?.eulaAccepted)
        );
    }

    /**
     * Determines whether or not the user's **current** role is the specified type (based on UserLoginRecord)
     *
     * @param  {string} type
     * @returns boolean
     */
    public isCurrentRole(type: RoleType): boolean {
        if (!this.isValid()) {
            return false;
        }

        return this.userLogin?.isCurrentRole(type) ?? false;
    }

    public hasCurrentRole(...types: RoleType[]) {
        return types.some((x) => this.isCurrentRole(x));
    }

    /**
     * Check to see if the user's current role is an expired free trial subscription.
     */
    public isCurrentRoleExpiredFreeTrial(): boolean {
        const currentUserRole = this.getCurrentUserRole();
        const now = moment();

        if (!this.isCurrentRole(RoleType.FREE_TRIAL)) {
            return false;
        }

        if (
            currentUserRole?.expiresOn == null ||
            now.isBefore(currentUserRole?.expiresOn)
        ) {
            return false;
        }

        return true;
    }

    public isEligibleForTrial(): boolean {
        return !this.hasActiveSubscription() && !this.hasHadFreeTrial();
    }

    /**
     * Utility method to identify if the user is currently logged in as a Group Admin
     * @returns boolean
     */
    public isGroupAdmin(): boolean {
        const currentUserRole = this.getCurrentUserRole();
        return currentUserRole?.userRoleGroup?.isAdmin ?? false;
    }

    /**
     * Utility method to identify if the user is currently logged in as a member of a group
     * @returns boolean
     */
    public isGroupMember(): boolean {
        const currentUserRole = this.getCurrentUserRole();
        return currentUserRole?.userRoleGroup != null;
    }

    /**
     * Utility method to identify if the user is currently logged in as an organization member
     * @returns boolean
     */
    public isOrganizationMember(): boolean {
        return this.isCurrentRole(RoleType.ORGANIZATION_MEMBER);
    }

    /**
     * Utility method to get the current user role organization record
     * @returns boolean
     */
    public getCurrentUserRoleOrganization():
        | UserRoleOrganizationRecord
        | undefined {
        return this.userRoleOrganizations?.find(
            (uro) => uro.userRoleId === this.userLogin?.userRoleId
        );
    }

    public getCurrentUserRoleOrganizationName(): string {
        const currentUserRoleOrganization =
            this.getCurrentUserRoleOrganization();
        return currentUserRoleOrganization?.organization?.name ?? "";
    }

    /**
     * Utility function to check publisher role on the logged in user
     * @returns boolean
     */
    public isPublisher(): boolean {
        return this.isCurrentRole(RoleType.PUBLISHER);
    }

    /**
     * Validates the current userlogin record for integrity
     * @returns boolean
     */
    public isValid(): boolean {
        return (
            this.userLogin != null &&
            this.userLogin.id != null &&
            this.userLogin.id > 0
        );
    }

    /**
     * Utility method to update the identity properties in local storage
     * @returns void
     */
    public refreshLocalStorage(): void {
        LocalStorageUtils.set<IdentityRecord>(LocalStorageKey.Identity, this);
    }

    public updateUserSetting(userSetting: UserSettingRecord): IdentityRecord {
        if (userSetting == null || CollectionUtils.isEmpty(this.userSettings)) {
            return this;
        }

        const { userSettings: existing } = this;
        const updated: Array<UserSettingRecord> = existing.filter(
            (e) => e.key !== userSetting.key
        );

        updated.push(userSetting);

        return this.with({ userSettings: updated });
    }

    /**
     * Utility to return the current user id
     * @returns number
     */
    public userId(): number {
        if (this.user != null && this.user.id != null) {
            return this.user.id;
        }
        // Not authenticated
        return 0;
    }

    /**
     * Returns new object based on `this` and merges the partial values
     * @param  {Partial<Identity>} values
     * @returns Identity
     */
    public with(values: Partial<Identity>): IdentityRecord {
        return new IdentityRecord(
            Object.assign(this.toJS(), values) as IdentityRecord
        );
    }

    /**
     * Adds UserLoginRecord relationship to IdentityRecord, carrying over nested RoleRecord and
     * UserRoleRecord relationship from the current list of UserRoleRecords
     *
     * @param {UserLoginRecord} userLogin
     */
    public withUserLogin(userLogin: UserLoginRecord): IdentityRecord {
        return this.with({
            userLogin: userLogin
                .withRole(this.getRoles())
                .withUserRole(this.userRoles),
        });
    }

    // #endregion Public Methods
}

// -----------------------------------------------------------------------------------------
// #region Private Functions
// -----------------------------------------------------------------------------------------

const attachNavigationProperties = (
    params: Identity & IdentityNavigationProperties
): Identity => {
    if (!hasNavigationProperties(params)) {
        return params;
    }

    if (
        CollectionUtils.hasValues(params.groups) &&
        CollectionUtils.hasValues(params.userRoleGroups)
    ) {
        params.userRoleGroups = params.userRoleGroups.map((userRoleGroup) =>
            userRoleGroup.withGroup(params.groups)
        );

        params.userRoles = params.userRoles.map((userRole) =>
            userRole.withUserRoleGroup(params.userRoleGroups)
        );
    }

    if (CollectionUtils.hasValues(params.roles)) {
        params.userRoles = params.userRoles.map((userRole) =>
            userRole.withRole(params.roles)
        );
    }

    params.userLogin = params.userLogin
        ?.withRole(params.roles)
        .withUserRole(params.userRoles);

    return params;
};

const hasNavigationProperties = (
    navigationProperties: IdentityNavigationProperties
): boolean =>
    CollectionUtils.hasValues(navigationProperties.groups) ||
    CollectionUtils.hasValues(navigationProperties.roles) ||
    CollectionUtils.hasValues(navigationProperties.userRoleGroups) ||
    CollectionUtils.hasValues(navigationProperties.userRoleOrganizations);

// #endregion Private Function
