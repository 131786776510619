import AccessibleList from "molecules/accessible-list/accessible-list";
import CultureResources from "utilities/interfaces/culture-resources";
import LogOutButton from "molecules/log-out-button.tsx/log-out-button";
import ProfileMenuTeamName from "organisms/profile/profile-menu-team-name";
import React from "react";
import useOutsideClick from "utilities/hooks/use-outside-click";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

import { useRef } from "react";
import { useLocalization } from "@rsm-hcd/javascript-react";
import Anchor from "atoms/anchors/anchor";
import { siteMap } from "internal-sitemap";
import useOrganizationSupport from "utilities/hooks/domain/organizations/use-organization-support";

interface OrganizationMemberProfileMenuDetailProps {
    focusFirstItem: boolean;
    setIsOpen: (value: React.SetStateAction<boolean>) => void;
    triggerRef: React.RefObject<HTMLButtonElement>;
    userIcon: React.ReactNode;
}

const BASE_CLASS_NAME = "c-profile-menu";

const OrganizationMemberProfileMenuDetail: React.FunctionComponent<
    OrganizationMemberProfileMenuDetailProps
> = ({ focusFirstItem, setIsOpen, triggerRef, userIcon }) => {
    const profileMenuRef = useRef<HTMLDivElement>(null);
    const { globalState } = useGlobalState();
    const { t } = useLocalization<CultureResources>();

    useOutsideClick(profileMenuRef, () => {
        setIsOpen(false);
    });

    const fullName =
        globalState.currentIdentity?.user?.getFirstAndLastNameOrReturnDefault();

    const { organizationSupport } = useOrganizationSupport({
        organizationId:
            globalState.currentIdentity?.getCurrentUserRoleOrganization()
                ?.organizationId ?? -1,
    });

    return (
        <div className={`${BASE_CLASS_NAME}__menu`}>
            <div className={`${BASE_CLASS_NAME}__menu__user-info`}>
                <div
                    className={`${BASE_CLASS_NAME}__menu__user-info__initials`}>
                    {userIcon}
                </div>
                <div>
                    <div
                        className={`${BASE_CLASS_NAME}__menu__user-info__name`}>
                        {fullName}
                    </div>
                    <ProfileMenuTeamName teamName={organizationSupport?.name} />
                </div>
            </div>
            <div
                ref={profileMenuRef}
                className={`${BASE_CLASS_NAME}__menu__actions`}>
                <AccessibleList
                    focusFirstItem={focusFirstItem}
                    onEsc={() => {
                        triggerRef.current?.focus();
                        setIsOpen(false);
                    }}>
                    <Anchor to={siteMap.support.faq} cssClassName="c-button">
                        {t("support")}
                    </Anchor>
                    <Anchor
                        cssClassName="c-button"
                        to={siteMap.account.organizationMemberInformation}>
                        {t("accountSettings")}
                    </Anchor>
                    <LogOutButton />
                </AccessibleList>
            </div>
        </div>
    );
};

export default OrganizationMemberProfileMenuDetail;
