import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React, { PropsWithChildren } from "react";
import { AuthenticationComponentWrapper } from "utilities/services/auth/authentication-component-wrapper";

export interface AzureAuthenticationProps {
    instance: IPublicClientApplication;
}

export const AzureAuthenticationWrapperComponent: AuthenticationComponentWrapper<
    AzureAuthenticationProps
> = ({
    instance,
    children,
}: PropsWithChildren<AzureAuthenticationProps>): React.ReactElement => {
    return (
        <div>
            <MsalProvider instance={instance}>{children}</MsalProvider>
        </div>
    );
};
