import { createContext, Dispatch, SetStateAction, useContext } from "react";
import BookviewLayoutContextRecord from "./bookview-layout-context-record";

export type BookviewLayoutContextUpdater = Dispatch<
    SetStateAction<BookviewLayoutContextRecord>
>;

const defaultState = new BookviewLayoutContextRecord();
const defaultUpdater = () => {};
const BookviewLayoutContext = createContext<
    [BookviewLayoutContextRecord, BookviewLayoutContextUpdater]
>([defaultState, defaultUpdater]);

export function useBookviewLayoutContext() {
    const [bookviewLayoutContext, setBookviewLayoutContext] = useContext(
        BookviewLayoutContext
    );

    const handleBookmarksReadOnlyDialogOpen = (externalId: string) =>
        setBookviewLayoutContext((context: BookviewLayoutContextRecord) => {
            return context.with({
                bookmarkReadonlyOpenForExternalId: externalId,
                bookmarkSettingsOpenForExternalId: undefined,
            });
        });

    const handleBookmarksReadOnlyDialogClose = () =>
        setBookviewLayoutContext((context: BookviewLayoutContextRecord) => {
            return context.with({
                bookmarkReadonlyOpenForExternalId: undefined,
            });
        });

    const handleBookmarkSettingsClose = () =>
        setBookviewLayoutContext((context: BookviewLayoutContextRecord) => {
            return context.with({
                bookmarkSettingsOpenForExternalId: undefined,
            });
        });

    const handleBookmarkSettingsOpen = (externalId: string) =>
        setBookviewLayoutContext((context: BookviewLayoutContextRecord) => {
            return context.with({
                bookmarkReadonlyOpenForExternalId: undefined,
                bookmarkSettingsOpenForExternalId: externalId,
            });
        });

    const handleToggleContentExpanded = () =>
        setBookviewLayoutContext((context: BookviewLayoutContextRecord) => {
            return context.with({
                bookviewContentExpanded: !context.bookviewContentExpanded,
            });
        });

    return {
        handleBookmarkSettingsClose,
        handleBookmarkSettingsOpen,
        handleBookmarksReadOnlyDialogClose,
        handleBookmarksReadOnlyDialogOpen,
        bookviewLayoutContext,
        setBookviewLayoutContext,
        handleToggleContentExpanded,
    };
}

export default BookviewLayoutContext;
