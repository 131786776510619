export default interface FeatureFlags {
    allowMultiTenantAuth?: boolean;

    allowOrganizationSSO?: boolean;

    /**
     * Disable all calls to Hermes. Use when Hermes is down to give users limited access to LiNK.
     */
    bypassHermes?: boolean;

    /**
     * Flag for toggling I18N features
     */
    i18N?: boolean;

    refreshOnPageChange?: boolean;

    /**
     * Show unhandled XML tags with some default styling in book view as an indication
     * that we need to address this XML tag.
     */
    showUnhandledXml?: boolean;

    /** Show the FAQ panel with demo video links */
    showVideoDemoFaq?: boolean;

    /** Show the custom JWT form on the org member login. */
    showCustomJwtForm?: boolean;

    /**
     * Flag to enable the new ErrorBoundary around BookViewLayout
     */
    useErrorBoundary?: boolean;

    /**
     * Flag to enable viewing legacy pdf publications and other material
     */
    useDocumentViewer?: boolean;

    /**
     * Use HermesAPI for creating/updating/getting teams info
     */
    useHermesApiForTeams?: boolean;

    usePutItForwardForTopics?: boolean;

    /**
     * Flag used for enabling Pendo analytics.
     */
    usePendo?: boolean;

    /**
     * Flag to enable rendering reduction in bookview.
     */
    useReducedRendersInBookview?: boolean;

    /**
     * Flag that enables simple tables(perfect grid 1 cell blocks with 1 set of headers) to look like cards on mobile view
     */
    useSimpleTableCards?: boolean;

    /**
     * Flag that enables using the simple style class for tables
     */
    useSimpleTableStyle?: boolean;

    /**
     * Flag to enable MsalInstance.ssoSilent(). There is sometimes an issue with SSO when going to
     * free access pages. This flag is used to quickly disable ssoSilent().
     */
    useSsoSilent?: boolean;

    /**
     * Validate email addresses.  Currently used for the new user form and user creation.
     */
    validateEmailAddresses?: boolean;

    /**
     * Content width expansion toggles for the bookview
     */
    bookviewContentWidthControl?: boolean;

    // used for tests
    testBooleanFeatureFlag?: boolean;
}

/**
 * Default values for feature flags.
 * These values should match those on the backend.
 * see FeatureFlags.cs#GetDefaults
 */
export const DEFAULT_FEATURE_FLAGS: Required<FeatureFlags> = {
    allowMultiTenantAuth: false,
    allowOrganizationSSO: false,
    bypassHermes: false,
    i18N: false,
    refreshOnPageChange: false,
    showCustomJwtForm: false,
    showUnhandledXml: false,
    showVideoDemoFaq: false,
    useDocumentViewer: false,
    useErrorBoundary: false,
    useHermesApiForTeams: false,
    usePendo: false,
    usePutItForwardForTopics: false,
    useReducedRendersInBookview: false,
    useSimpleTableCards: false,
    useSimpleTableStyle: false,
    useSsoSilent: true,
    validateEmailAddresses: false,
    bookviewContentWidthControl: false,
    // used only for tests and storybook for feature-flag.tsx
    testBooleanFeatureFlag: false,
};
