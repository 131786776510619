import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import PublicationRecord from "models/view-models/publication-record";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import React, { useState } from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import ReadonlyPublicationInfoModal from "../readonly-publication-info-modal";
import PublicationOptionsMenu from "./publication-options-menu";

interface PublicationListItemActionsProps {
    publication: PublicationRecord;
    favorites?: Array<UserPublicationFavoriteRecord>;
    onChangeFavorites: (newValue: Array<UserPublicationFavoriteRecord>) => void;
}

const BASE_CLASS = "c-publication-selection-list-group-item";

const PublicationListItemActions = (props: PublicationListItemActionsProps) => {
    const { publication, favorites = [], onChangeFavorites } = props;

    return (
        <>
            <div className={`${BASE_CLASS}__actions`}>
                <>
                    {publication.getIsLegacy() && <ReadOnlyModalButton />}
                    <PublicationOptionsMenu
                        favorites={favorites}
                        onChangeFavorites={onChangeFavorites}
                        publication={publication}
                    />
                </>
            </div>
        </>
    );
};

const ReadOnlyModalButton = () => {
    const [showReadOnlyModal, setShowReadOnlyModal] = useState(false);

    const { t } = useLocalization();

    const handleReadOnlyClick = () => {
        setShowReadOnlyModal(true);
    };
    const handleReadOnlyModalClose = () => {
        setShowReadOnlyModal(false);
    };

    return (
        <Button
            accessibleText={t("readonlyPublicationAboutTitle")}
            onClick={handleReadOnlyClick}
            cssClassName={"c-action-button"}
            size={ButtonSizes.Small}>
            <Icon
                cssClassName="c-legacy-icon"
                type={Icons.Scroll}
                size={IconSizes.Base}
            />
            <ReadonlyPublicationInfoModal
                isVisible={showReadOnlyModal}
                closeDialog={handleReadOnlyModalClose}
            />
        </Button>
    );
};

export default PublicationListItemActions;
