// -----------------------------------------------------------------------------------------
// #region Utility Functions
// -----------------------------------------------------------------------------------------

/**
 * Return formatted phone number
 * Ex: 234-567-9225
 * @returns Return formatted phone number
 */
const formatPhoneNumber = (value?: string): string => {
    if (value === undefined) {
        return "";
    }

    const cleaned = value.replace(/\D/g, "");

    switch (cleaned.length) {
        case 10:
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

            if (match) {
                return `${match[1]}-${match[2]}-${match[3]}`;
            }

            break;
    }

    return value ?? "";
};

// #endregion Utility Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const PhoneNumberUtils = {
    formatPhoneNumber: formatPhoneNumber,
};

export { PhoneNumberUtils };

// #endregion Exports
