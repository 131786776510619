import IdentityProvider from "models/interfaces/identity-provider";
import { Record } from "immutable";
import { ServerResponseType } from "@azure/msal-browser";

const defaultValues: IdentityProvider = {
    organizationId: 0,
    loginSlug: "",
    clientId: "",
    domain: "",
    policy: "",
    tenant: "",
    overrideAuthority: "",
    overrideTokenProperty: "",
    serverResponseType: ServerResponseType.FRAGMENT,
};

export default class IdentityProviderRecord
    extends Record(defaultValues)
    implements IdentityProvider
{
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: IdentityProvider) {
        if (params == null) params = Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor
}
