import React from "react";
import { useParams } from "react-router-dom";
import useChangeIndicatorDiffPanel from "utilities/atoms/change-indicator-diff-panel/use-change-indicator-diff-panel";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useChapter from "utilities/hooks/domain/publications/chapters/use-chapter";
import useChapterTables from "utilities/hooks/domain/publications/chapters/use-chapter-tables";
import useTias from "utilities/hooks/domain/publications/use-tias";
import useAdminPreview from "utilities/hooks/use-admin-preview";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { useErrorBoundary } from "utilities/hooks/use-error-boundary";
import useErrors from "utilities/hooks/use-errors";
import useLoading from "utilities/hooks/use-loading";
import TablesList from "./tables-list";

interface ChapterTablesContentParams {
    chapterId: string;
    chapterNfpaLabel: string;
}

interface ChapterTablesContentProps {}

const CSS_CLASS_NAME = "c-chapter-tables-content";

const ChapterTablesContent: React.FunctionComponent<
    ChapterTablesContentProps
> = (props: ChapterTablesContentProps) => {
    const { publication } = useCurrentPublication();
    const { chapterId, chapterNfpaLabel } =
        useParams<ChapterTablesContentParams>();
    const { isAdminPreview } = useAdminPreview();
    const { showDiffPanel } = useChangeIndicatorDiffPanel();
    const { isTiaPanelOpen } = useTias();
    const isDesktop = useBreakpoint(Breakpoints.SmallDesktop);
    const {
        errors: chapterErrors,
        loading: chapterLoading,
        loaded: chapterLoaded,
        resultObject: chapter,
    } = useChapter({
        chapterId,
        nfpaLabel: chapterNfpaLabel,
        publicationId: publication?.id,
    });
    const {
        errors: tablesErrors,
        loaded: tablesLoaded,
        loading: tablesLoading,
        resultObject: tables,
    } = useChapterTables(isAdminPreview, chapter.id);
    const errors = useErrors(chapterErrors, tablesErrors);
    const loading = useLoading(chapterLoading, tablesLoading);
    useErrorBoundary(errors);

    const loaded = chapterLoaded && tablesLoaded;
    const title = chapter.getDisplayTitle();
    const showChanges = (showDiffPanel || isTiaPanelOpen) && isDesktop;

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
            }}>
            <div
                className={`${CSS_CLASS_NAME}`}
                style={{
                    width: showChanges ? "50%" : "100%",
                    display: "flex",
                    justifyContent: showChanges ? "end" : "center",
                }}>
                <div
                    className={`${CSS_CLASS_NAME}__container`}
                    style={
                        showChanges
                            ? {
                                  maxWidth: "730px",
                                  width: "100%",
                              }
                            : {}
                    }>
                    <TablesList
                        errors={errors}
                        loaded={loaded}
                        loading={loading}
                        publicationType={publication?.type!}
                        tables={tables}
                        title={title}
                    />
                </div>
            </div>
            {showChanges && (
                <div
                    style={{
                        width: "50%",
                        height: "100%",
                    }}></div>
            )}
        </div>
    );
};

export default ChapterTablesContent;
