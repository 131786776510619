import { atom, useAtom } from "jotai";

const metaNavIsVisible = atom<boolean>(false);
const metaNavIsCollapsed = atom<boolean>(false);

const useMetaNav = () => {
    const [isMetaNavVisible, setIsMetaNavVisible] = useAtom(metaNavIsVisible);
    const [isMetaNavCollapsed, setIsMetaNavCollapsed] =
        useAtom(metaNavIsCollapsed);

    const toggle = () => setIsMetaNavVisible((prev) => !prev);
    const toggleCollapse = () => setIsMetaNavCollapsed((prev) => !prev);

    const closeMetaNav = () => setIsMetaNavVisible(false);

    return {
        isMetaNavVisible,
        toggle,
        closeMetaNav,
        toggleCollapse,
        isMetaNavCollapsed,
    };
};

export default useMetaNav;
