import { BillingCycleOption } from "constants/billing-cycle-options";
import SubscriptionType from "organisms/enums/subscription-type";
import SelectBillingCycleModalV2 from "organisms/subscription-card/select-billing-cycle-modal-v2";
import React from "react";
import EnterpriseSubscriptionCard from "organisms/subscription-cards/enterprise-subscription-card";
import IndividualSubscriptionCard from "organisms/subscription-cards/individual-subscription-card";
import TeamSubscriptionCard from "organisms/subscription-cards/team-subscription-card";

interface SubscriptionCardsProps {
    handleEnterpriseCardSelect: () => void;
    handleIndividualCardSelect: () => void;
    handleTeamCardSelect: () => void;
    resetModal: () => void;
    handleBillingCycleCheck: () => void;
    selectedBillingCycle: BillingCycleOption | undefined;
    selectedSubscriptionType: SubscriptionType | undefined;
    showBillingModal: boolean;
    cssClassName?: string;
    cardCssClassNameModifier?: string;
}

const SubscriptionCards = (props: SubscriptionCardsProps) => {
    const {
        handleEnterpriseCardSelect,
        handleIndividualCardSelect,
        handleTeamCardSelect,
        resetModal,
        handleBillingCycleCheck,
        selectedBillingCycle,
        selectedSubscriptionType,
        showBillingModal,
        cssClassName,
        cardCssClassNameModifier = "",
    } = props;

    return (
        <div className={`${cssClassName}__cards`}>
            <IndividualSubscriptionCard
                cssClassNameModifier={cardCssClassNameModifier}
                onSelect={handleIndividualCardSelect}
            />
            <TeamSubscriptionCard
                cssClassNameModifier={cardCssClassNameModifier}
                onSelect={handleTeamCardSelect}
            />
            <EnterpriseSubscriptionCard
                cssClassNameModifier={cardCssClassNameModifier}
                onSelect={handleEnterpriseCardSelect}
            />

            {showBillingModal && (
                <SelectBillingCycleModalV2
                    closeDialog={resetModal}
                    onCheck={handleBillingCycleCheck}
                    isVisible={showBillingModal}
                    selectedBillingCycle={selectedBillingCycle}
                    selectedSubscriptionType={selectedSubscriptionType}
                />
            )}
        </div>
    );
};

export default SubscriptionCards;
