import Button from "atoms/buttons/button";
import useAzure from "pages/azure-login/use-azure";
import React from "react";
import { t } from "utilities/localization-utils";

interface ForgotPasswordProps {
    cssClassName?: string;
    buttonText?: string;
}

const ForgotPasswordButton = (props: ForgotPasswordProps) => {
    const { cssClassName, buttonText } = props;
    const { forgotPassword } = useAzure();

    return (
        <Button onClick={forgotPassword} cssClassName={cssClassName}>
            {buttonText ? buttonText : t("forgotPassword")}
        </Button>
    );
};

export default ForgotPasswordButton;
