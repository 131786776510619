import OrganizationManifestRecord from "models/view-models/organization-manifest-record";
import ServiceFactory from "../service-factory";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface OrganizationManifestPathParams {
    organizationId: number;
}

// #endregion Interfaces

const resourceEndpoint = "organizations/:organizationId/manifest";
const resourceType = OrganizationManifestRecord;

const OrganizationManifestsService = {
    get: ServiceFactory.get<
        OrganizationManifestRecord,
        OrganizationManifestPathParams
    >(resourceType, resourceEndpoint),
};
export default OrganizationManifestsService;
