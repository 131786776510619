import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { AppNameWithHtml } from "constants/app-name-tm";
import CreateUserDto from "models/interfaces/create-user-dto";
import LogInAnchor from "molecules/log-in-anchor/log-in-anchor";
import NewUserLoginForm, {
    NewUserLoginFormProps,
} from "organisms/userlogins/userlogins-new-form/userlogins-new-form";
import React, { PropsWithChildren, useState } from "react";
import { useLocalization } from "utilities/hooks/use-localization";
import NewUserForm, { NewUserFormProps } from "organisms/users/new-user-form";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface RegisterOrLoginFormProps
    extends Omit<NewUserFormProps, "onSuccess" | "onSubmit">,
        Omit<NewUserLoginFormProps, "onSuccess" | "onSubmit"> {
    newUserLoginFormSubmitButtonText?: string;
    onError?: (message?: string) => void;
    onSubmit?: (() => boolean) | ((user?: CreateUserDto) => boolean);
    onUserLoginFormSuccess?: () => void;
    showRegisterForm: boolean;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-register-or-login-form";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const RegisterOrLoginForm: React.FunctionComponent<
    PropsWithChildren<RegisterOrLoginFormProps>
> = (props: PropsWithChildren<RegisterOrLoginFormProps>) => {
    // -----------------------------------------------------------------------------------------
    // #region Setup
    // -----------------------------------------------------------------------------------------

    const {
        defaultEmail,
        freeTrialAgreementError,
        freeTrialTermsAccepted,
        newUserLoginFormSubmitButtonText,
        newUserLoginFormType,
        onError,
        onFreeTrialTermsAccepted,
        onSubmit,
        onUserLoginFormSuccess,
        selectedSubscriptionType,
        showLogo,
        showRegisterForm: showRegisterFormInitialValue,
        showSignInTitle,
        submitButtonText,
    } = props;

    const { t } = useLocalization();
    const createAccountHeader = t("registerOrLogin-signUpHeader", {
        appNameWithHtml: AppNameWithHtml,
    });

    const registerQuestion = t("registerOrLogin-signUpQuestion");

    // #endregion Setup

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const showRegisterForm = useState<boolean>(showRegisterFormInitialValue);

    // #endregion State
    return (
        <div className={`${BASE_CLASS}`}>
            <Heading priority={HeadingPriority.Two}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: createAccountHeader,
                    }}
                />
            </Heading>
            <div>
                {registerQuestion}
                &nbsp;
                <LogInAnchor />
            </div>
            {props.children}

            {showRegisterForm ? (
                <React.Fragment>
                    <NewUserForm
                        defaultEmail={defaultEmail}
                        freeTrialAgreementError={freeTrialAgreementError}
                        freeTrialTermsAccepted={freeTrialTermsAccepted}
                        onFreeTrialTermsAccepted={onFreeTrialTermsAccepted}
                        selectedSubscriptionType={selectedSubscriptionType}
                    />
                </React.Fragment>
            ) : (
                <NewUserLoginForm
                    defaultEmail={defaultEmail}
                    freeTrialAgreementError={freeTrialAgreementError}
                    freeTrialTermsAccepted={freeTrialTermsAccepted}
                    newUserLoginFormType={newUserLoginFormType}
                    onError={onError}
                    onFreeTrialTermsAccepted={onFreeTrialTermsAccepted}
                    onSubmit={onSubmit}
                    onSuccess={onUserLoginFormSuccess}
                    selectedSubscriptionType={selectedSubscriptionType}
                    showLogo={showLogo}
                    showSignInTitle={showSignInTitle}
                    submitButtonText={
                        newUserLoginFormSubmitButtonText ?? submitButtonText
                    }
                />
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default RegisterOrLoginForm;

// #endregion Exports
