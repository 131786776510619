import { SkipNavLink } from "@reach/skip-nav";
import Anchor from "atoms/anchors/anchor";
import { siteMap } from "internal-sitemap";
import React, { PropsWithChildren } from "react";
import { t } from "utilities/localization-utils";
import logo from "assets/images/nfpa-link-color.png";
import UnauthenticatedFooter from "molecules/footer/unauthenticated-footer";
import LogInAnchor from "molecules/log-in-anchor/log-in-anchor";

interface Props {}

const BASE_CLASS = "c-onboarding-layout-v2";
const BANNER_CLASS = "c-onboarding-layout-v2__banner";
const CONTENT_CLASS = "c-onboarding-layout-v2__content";
const HOME_BUTTON_CLASS = "c-onboarding-layout-v2__banner__home";
const LOGO_CLASS = "c-onboarding-layout-v2__banner__home__logo";

const OnboardingLayoutV2 = (props: PropsWithChildren<Props>) => {
    return (
        <>
            <SkipNavLink>{t("skipToMainContent")}</SkipNavLink>
            <div className={BASE_CLASS}>
                <header className={BANNER_CLASS}>
                    <Anchor
                        to={siteMap.home}
                        ariaLabel={t("home")}
                        cssClassName={HOME_BUTTON_CLASS}>
                        <img
                            className={LOGO_CLASS}
                            src={logo}
                            alt={t("home")}
                        />
                    </Anchor>
                    <div className={`${BASE_CLASS}__headerRightSide`}>
                        <LogInAnchor cssClassName={"c-button -medium"} />
                    </div>
                </header>
                <main className={CONTENT_CLASS}>{props.children}</main>
                <UnauthenticatedFooter />
            </div>
        </>
    );
};

export default OnboardingLayoutV2;
