import Button from "atoms/buttons/button";
import React, { forwardRef, useCallback, useEffect } from "react";
import useEmulationMode from "utilities/contexts/emulation/use-emulation-mode";
import useToastError from "utilities/hooks/use-toast-error";
import { AuthenticationLifeCycleStatus } from "utilities/enumerations/authorization";
import { t } from "utilities/localization-utils";
import { useAuthentication } from "utilities/contexts/authentication/authentication-provider";
import { useGlobalState } from "utilities/contexts/use-global-state-context";

interface LogOutButtonProps {
    disabled?: boolean;
    cssClassName?: string;
}

const LogOutButton = forwardRef((props: LogOutButtonProps, _ref) => {
    const { disabled = false, cssClassName = "c-button" } = props;
    const { logout, errorMessage, authenticationLifeCycleStatus } =
        useAuthentication();
    const toastError = useToastError(errorMessage ?? "failed to logout");
    const { isInEmulationMode } = useEmulationMode();
    const { globalState } = useGlobalState();

    const handleSignOutOfAzure = useCallback(() => {
        if (globalState.currentIdentity?.isOrganizationMember()) {
            logout(
                globalState.currentIdentity?.getCurrentUserRoleOrganization()
                    ?.organization?.loginSlug
            );
        } else {
            logout();
        }
    }, [globalState.currentIdentity, logout]);

    useEffect(() => {
        if (
            authenticationLifeCycleStatus ===
            AuthenticationLifeCycleStatus.FailedToLogout
        ) {
            toastError();
        }
    }, [authenticationLifeCycleStatus, toastError]);

    return isInEmulationMode ? (
        <></>
    ) : (
        <Button
            onClick={handleSignOutOfAzure}
            cssClassName={cssClassName}
            disabled={disabled}>
            {t("logOut")}
        </Button>
    );
});

export default LogOutButton;
