import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import OrganizationSupportRecord from "models/view-models/organization-support-record";
import CopyTextButton from "molecules/buttons/copy-text-button";
import { ModalCloseButtonStyle } from "molecules/constants/modal-close-button-style";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import React from "react";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useBreakpoint from "utilities/hooks/use-breakpoint";
import { t } from "utilities/localization-utils";
import { PhoneNumberUtils } from "utilities/phone-number-utils";
import StringUtils from "utilities/string-utils";

const BASE_CSS_CLASS = "c-organization-support-modal";
interface OrganizationSupportModalProps {
    showModal: boolean;
    onHideModal: () => void;
    organization: OrganizationSupportRecord;
}

const OrganizationSupportModal = ({
    showModal,
    onHideModal,
    organization,
}: OrganizationSupportModalProps) => {
    const isDesktop = useBreakpoint(Breakpoints.SmallTablet);

    return (
        <Modal
            cssClassName={`${BASE_CSS_CLASS}__modal`}
            closeDialog={onHideModal}
            isVisible={showModal}
            label={t("accountSupport")}
            closeButtonStyle={ModalCloseButtonStyle.InsideDialog}
            type={isDesktop ? ModalTypes.Base : ModalTypes.Bottom}>
            <header className={`${BASE_CSS_CLASS}__header`}>
                <Heading
                    cssClassName={`${BASE_CSS_CLASS}__heading`}
                    priority={HeadingPriority.Two}>
                    {t("accountSupport")}
                </Heading>
                <Button
                    accessibleText={t("closeItem", { item: t("dialog") })}
                    cssClassName={`${BASE_CSS_CLASS}__closeButton`}
                    onClick={onHideModal}
                    size={ButtonSizes.Small}
                    style={ButtonStyles.None}>
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </header>
            <section className={`${BASE_CSS_CLASS}__content`}>
                <dl>
                    <dt className={`${BASE_CSS_CLASS}__itemLabel`}>
                        {t("organization")}
                    </dt>
                    <dd className={`${BASE_CSS_CLASS}__item`}>
                        {organization.name}
                    </dd>
                    <dt className={`${BASE_CSS_CLASS}__itemLabel`}>
                        {t("contactPerson")}
                    </dt>
                    {StringUtils.hasValue(organization.contactFullname) && (
                        <dd className={`${BASE_CSS_CLASS}__item`}>
                            <span className={`${BASE_CSS_CLASS}__entry`}>
                                {organization.contactFullname}
                            </span>
                            {StringUtils.hasValue(
                                organization.emailAddress
                            ) && (
                                <CopyTextButton
                                    textCssClassName={`${BASE_CSS_CLASS}__entryText`}
                                    iconCssClassName={`${BASE_CSS_CLASS}__entryIcon`}
                                    cssClassName={`${BASE_CSS_CLASS}__entry`}
                                    textToCopy={organization.emailAddress}
                                />
                            )}
                            {StringUtils.hasValue(organization.phoneNumber) && (
                                <CopyTextButton
                                    textCssClassName={`${BASE_CSS_CLASS}__entryText`}
                                    iconCssClassName={`${BASE_CSS_CLASS}__entryIcon`}
                                    cssClassName={`${BASE_CSS_CLASS}__entry`}
                                    textToCopy={PhoneNumberUtils.formatPhoneNumber(
                                        organization.phoneNumber
                                    )}
                                />
                            )}
                        </dd>
                    )}
                    {StringUtils.hasValue(organization.website) && (
                        <div className={`${BASE_CSS_CLASS}__websiteWrapper`}>
                            <Anchor
                                external
                                target={AnchorTargetTypes.Blank}
                                cssClassName="c-button -secondary"
                                to={`${organization.website}`}>
                                {t("organizationSupportWebsite", {
                                    organizationName: organization.name,
                                })}
                            </Anchor>
                            <Paragraph size={ParagraphSizes.Small}>
                                {t("orVisit")}{" "}
                                <Anchor
                                    external
                                    cssClassName={`${BASE_CSS_CLASS}__anchor`}
                                    target={AnchorTargetTypes.Blank}
                                    to={`${organization.website}`}>
                                    {organization.website}
                                </Anchor>
                            </Paragraph>
                        </div>
                    )}
                </dl>
            </section>
        </Modal>
    );
};

export default OrganizationSupportModal;
