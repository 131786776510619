import Button from "atoms/buttons/button";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import { AppNameWithHtml } from "constants/app-name-tm";
import { BillingCycleOption } from "constants/billing-cycle-options";
import { EcommercePins } from "constants/ecommerce-pins";
import { History } from "history";
import { siteMap } from "internal-sitemap";
import { SubscriptionTeamSize } from "models/enumerations/subscription-team-size";
import { MetaTag, MetaTagTypes } from "models/interfaces/header-data";
import SignupContextRecord from "models/view-models/signup-context-record";
import FreeTrialTermsBox from "molecules/free-trial-terms-box/free-trial-terms-box";
import FrozenSubscriptionsAlert from "molecules/frozen-subscriptions-alert/frozen-subscriptions-alert";
import SubscriptionType from "organisms/enums/subscription-type";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import EcommerceRedirectionModal from "organisms/subscription-card/ecommerce-redirection-modal";
import SelectedSubscriptionCard from "organisms/subscription-card/selected-subscription-card";
import RegisterOrLoginForm from "organisms/users/register-or-login-form";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSignupContext } from "utilities/contexts/signup/use-signup-context";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import useStartFreeTrial from "utilities/hooks/domain/users/use-start-free-trial";
import useQueryString from "utilities/hooks/routing/use-query-string";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";
import { useEcommercePrices } from "utilities/hooks/use-ecommerce-prices";
import { useLocalization } from "utilities/hooks/use-localization";
import useMarketingQueryParams from "utilities/hooks/use-marketing-query-params";
import { RouteUtils } from "utilities/route-utils";
import { useUrlCallback } from "utilities/routing/url-callback/use-url-callback";
import { ScrollUtils } from "utilities/scroll-utils";
import StringUtils from "utilities/string-utils";
import SubscriptionTypeUtils from "utilities/subscription-type-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface RegisterAccountPageProps {}

interface RegisterAccountPageQueryParams {
    sku?: string;
    icid?: string;
    freeTrialAgreementAccepted?: boolean;
}

interface BillingCycleAndSubscription {
    billingCycle?: BillingCycleOption;
    teamSize?: SubscriptionTeamSize;
    type?: SubscriptionType;
}

interface RegisterAccountPageParams {
    subscriptionType: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-register-account-page";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const RegisterAccountPage: React.FC<RegisterAccountPageProps> = () => {
    // -----------------------------------------------------------------------------------------
    // #region Hooks
    // -----------------------------------------------------------------------------------------

    const { t } = useLocalization();
    const history = useHistory();
    const { subscriptionType } = useParams<RegisterAccountPageParams>();
    const { queryParams } = useQueryString<RegisterAccountPageQueryParams>();
    const { icid, sku, freeTrialAgreementAccepted } = queryParams;
    const { globalState } = useGlobalState();
    const { fromPin } = useEcommercePrices();
    const { setCallbackUrlParam } = useUrlCallback();

    const { startFreeTrialForSignedInUser, setCallbackLink } =
        useStartFreeTrial();

    const { setMarketingQueryParams } = useMarketingQueryParams();
    setMarketingQueryParams();

    const { subscriptionFreezeActive } = useConfigurableAlertMessages();

    //#endregion Hooks

    // -----------------------------------------------------------------------------------------
    // #region Setup
    // -----------------------------------------------------------------------------------------

    const metaTags: Array<MetaTag> = useMemo(
        () => [
            {
                name: MetaTagTypes.Title,
                content: t("registerAccountPageMetaTagTitle"),
            },
            {
                name: MetaTagTypes.Description,
                content: t("registerAccountPageMetaTagDescription"),
            },
        ],
        [t]
    );

    const title = useMemo(() => t("registerAccountPageTitle"), [t]);

    useHeaderData({ title, metaTags });

    const initialBillingState = () => {
        const subscriptionTypeFromRoute =
            SubscriptionTypeUtils.fromRouteParam(subscriptionType);

        const subscriptionTypeFromPin = SubscriptionTypeUtils.fromPin(sku);

        // if we have no subscription type from either param, redirect to plans page
        if (
            subscriptionTypeFromRoute == null &&
            subscriptionTypeFromPin == null &&
            !freeTrialAgreementAccepted
        ) {
            history.push(siteMap.signup.plans);
        }

        let mappedBillingCycle = fromPin(sku);

        const { teamSize, type } = subscriptionTypeFromPin ?? {};

        // if the subscription type from the route param doesn't match
        // the one from the PIN query param, ignore the billing cycle from the PIN
        if (
            subscriptionTypeFromRoute != null &&
            subscriptionTypeFromRoute !== type
        ) {
            mappedBillingCycle = undefined;
        }
        return {
            billingCycle: mappedBillingCycle,
            teamSize: teamSize,
            type: subscriptionTypeFromRoute ?? type,
        };
    };

    // this means the user is returning from Azure after accepting the terms and creating an account
    const shouldAutoStartFreeTrial =
        freeTrialAgreementAccepted && globalState.isAuthenticated();

    // #endregion Setup

    // -----------------------------------------------------------------------------------------
    // #region Context
    // -----------------------------------------------------------------------------------------

    const { signupContext, setSignupContext } = useSignupContext();

    // #endregion Context

    // -----------------------------------------------------------------------------------------
    // #region State
    // -----------------------------------------------------------------------------------------

    const [redirecting, setRedirecting] = useState<boolean>(false);
    const [isRedirectingViaSignIn, setIsRedirectingViaSignIn] = useState(false);
    const isRedirectingViaRegistration = useState(false);
    const [billingCycleErrored, setBillingCycleErrored] =
        useState<boolean>(false);
    const [teamSizeErrored, setTeamSizeErrored] = useState<boolean>(false);
    const [freeTrialAgreementError, setFreeTrialAgreementError] = useState("");
    const [freeTrialTermsAccepted, setFreeTrialTermsAccepted] = useState(false);

    const [billingCycleAndSubscription, setBillingCycleAndSubscription] =
        useState<BillingCycleAndSubscription>(initialBillingState);

    // #endregion State

    // -----------------------------------------------------------------------------------------
    // #region Transforms and Computations
    // -----------------------------------------------------------------------------------------

    const {
        billingCycle: selectedBillingCycle,
        teamSize: selectedSubscriptionTeamSize,
        type: selectedSubscriptionType,
    } = billingCycleAndSubscription;

    const { currentIdentity } = globalState;
    const isSignedIn = currentIdentity?.userLogin != null;
    const freeTrialSelected =
        selectedSubscriptionType === SubscriptionType.FreeTrial;

    const headingText = freeTrialSelected
        ? t("registerHeading-freeTrial", { appNameWithHtml: AppNameWithHtml })
        : t("registerHeading", { appNameWithHtml: AppNameWithHtml });

    const newUserLoginFormSubmitButtonText = freeTrialSelected
        ? t("freeTrialNewUserLoginFormSubmitButtonText")
        : t("newUserLoginFormSubmitButtonText");

    const submitButtonText = freeTrialSelected
        ? t("freeTrialBillingCycle-goToNfpaToPurchase")
        : t("billingCycle-goToNfpaToPurchase");

    // #endregion Transforms and Computations

    const handleSubmit = () => {
        if (freeTrialSelected && !validateAcceptedFreeTrialAgreement()) {
            return false;
        }

        if (freeTrialSelected) {
            // the user must sign in or create a new account, if they are not signed in then the
            // free trial will be created after creating the new user record
            if (isSignedIn) startFreeTrialForSignedInUser();
            return true;
        }

        if (
            selectedSubscriptionType === SubscriptionType.Team &&
            selectedSubscriptionTeamSize == null
        ) {
            setTeamSizeErrored(true);
            return false;
        }

        if (selectedBillingCycle == null) {
            setBillingCycleErrored(true);
            return false;
        }

        if (billingCycleErrored) {
            setBillingCycleErrored(false);
        }

        if (teamSizeErrored) {
            setTeamSizeErrored(false);
        }

        if (isSignedIn) {
            setRedirecting(true);
        }

        return true;
    };

    const validateAcceptedFreeTrialAgreement = () => {
        let freeTrialAgreementError = "";

        if (freeTrialSelected && !freeTrialTermsAccepted) {
            freeTrialAgreementError = t(
                "freeTrialTermsAndConditions-mustAgreeToContinue"
            );
        }

        setFreeTrialAgreementError(freeTrialAgreementError);

        return StringUtils.isEmpty(freeTrialAgreementError);
    };

    const handleFreeTrialTermsAccepted = (accepted: boolean) => {
        setFreeTrialTermsAccepted(accepted);
        if (accepted) {
            setFreeTrialAgreementError("");
            setCallbackUrlParam(setCallbackLink());
        }
    };

    const currentCulture = globalState.getPreferredOrCurrentCulture();
    useEffect(
        function handleRouteChanges() {
            replaceRouteFromOptions(
                history,
                selectedBillingCycle,
                selectedSubscriptionType,
                currentCulture,
                icid,
                undefined,
                freeTrialAgreementAccepted
            );
        },
        [
            history,
            currentCulture,
            selectedBillingCycle,
            selectedSubscriptionType,
            icid,
            freeTrialAgreementAccepted,
        ]
    );

    useEffect(function scrollToTop() {
        // Mobile browsers sometimes were starting at the bottom of the page and not resetting scroll
        // on page change.
        ScrollUtils.scrollToTop();
    }, []);

    useEffect(() => {
        setCallbackUrlParam(window.location.pathname);
    }, [setCallbackUrlParam]);

    useEffect(
        function resetPasswordField() {
            // When coming back from another page, reset the password fields
            if (!signupContext.shouldResetPasswordField) {
                return;
            }

            setSignupContext((prevSignupContext: SignupContextRecord) =>
                prevSignupContext.with({
                    newUser: prevSignupContext.newUser.with({
                        password: undefined,
                        confirmPassword: undefined,
                    }),
                    shouldResetPasswordField: false,
                })
            );
        },
        [signupContext, setSignupContext]
    );

    useEffect(
        function handleRedirects() {
            const redirectToDashboard =
                freeTrialSelected &&
                currentIdentity != null &&
                !currentIdentity?.isEligibleForTrial();

            if (!redirectToDashboard) {
                return;
            }

            history.push(siteMap.dashboards.user);
        },
        [currentIdentity, history, freeTrialSelected]
    );

    useEffect(() => {
        if (shouldAutoStartFreeTrial) {
            startFreeTrialForSignedInUser();
        }
    }, [shouldAutoStartFreeTrial, startFreeTrialForSignedInUser]);

    const handleBillingCycleCheck = (option: BillingCycleOption) => {
        setBillingCycleErrored(false);
        setBillingCycleAndSubscription((prev) => ({
            ...prev,
            billingCycle: option,
            type: selectedSubscriptionType,
        }));
    };

    const handleUserLoginFormSuccess = () => {
        setRedirecting(true);
        setIsRedirectingViaSignIn(true);
    };

    const getAutoRenewPinFromTeamSize = (teamSize: SubscriptionTeamSize) => {
        return teamSize === SubscriptionTeamSize.UpToFive
            ? EcommercePins.TeamFiveAnnualAutoRenew
            : EcommercePins.TeamTenAnnualAutoRenew;
    };

    const getAnnualPinFromTeamSize = (teamSize: SubscriptionTeamSize) => {
        return teamSize === SubscriptionTeamSize.UpToFive
            ? EcommercePins.TeamFiveAnnual
            : EcommercePins.TeamTenAnnual;
    };

    const handleChangeTeamSize = (teamSize: SubscriptionTeamSize) => {
        setTeamSizeErrored(false);

        // Using the previous billing cycle if avail, find new one based on updated team size
        const isAnnual = [
            EcommercePins.TeamFiveAnnual,
            EcommercePins.TeamTenAnnual,
        ].includes(selectedBillingCycle?.pin ?? "");

        let pin = getAutoRenewPinFromTeamSize(teamSize);
        if (isAnnual) {
            pin = getAnnualPinFromTeamSize(teamSize);
        }

        setBillingCycleAndSubscription((prev) => {
            return {
                ...prev,
                billingCycle: fromPin(pin),
                teamSize: teamSize,
            };
        });
    };

    const classNames = [BASE_CLASS];
    const showRegisterOrLoginForm =
        !isSignedIn ||
        (isSignedIn && isRedirectingViaSignIn) ||
        (isSignedIn && isRedirectingViaRegistration);

    if (
        isSignedIn &&
        !isRedirectingViaSignIn &&
        !isRedirectingViaRegistration
    ) {
        classNames.push("-signed-in");
    }

    if (shouldAutoStartFreeTrial)
        return <FullScreenTransition transitionText={""} />;

    return (
        <>
            {subscriptionFreezeActive ? (
                <FrozenSubscriptionsAlert />
            ) : (
                <div className={classNames.join(" ")}>
                    <div className={`${BASE_CLASS}__header`}>
                        <Heading
                            cssClassName={`${BASE_CLASS}__header__title`}
                            priority={HeadingPriority.One}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: headingText,
                                }}
                            />
                        </Heading>
                    </div>
                    <div className={`${BASE_CLASS}__content`}>
                        {redirecting && (
                            <EcommerceRedirectionModal
                                pin={selectedBillingCycle!.pin}
                            />
                        )}
                        <SelectedSubscriptionCard
                            erroredBillingCycle={billingCycleErrored}
                            erroredTeamSize={teamSizeErrored}
                            onCheckBillingCycle={handleBillingCycleCheck}
                            onChangeTeamSize={handleChangeTeamSize}
                            selectedBillingCycle={selectedBillingCycle}
                            selectedSubscriptionType={selectedSubscriptionType}
                            selectedTeamSize={selectedSubscriptionTeamSize}
                        />
                        {showRegisterOrLoginForm && (
                            <div>
                                <RegisterOrLoginForm
                                    freeTrialTermsAccepted={
                                        freeTrialTermsAccepted
                                    }
                                    freeTrialAgreementError={
                                        freeTrialAgreementError
                                    }
                                    isSigningIn={isRedirectingViaSignIn}
                                    newUserLoginFormSubmitButtonText={
                                        newUserLoginFormSubmitButtonText
                                    }
                                    onFreeTrialTermsAccepted={
                                        handleFreeTrialTermsAccepted
                                    }
                                    onUserLoginFormSuccess={
                                        handleUserLoginFormSuccess
                                    }
                                    onSubmit={handleSubmit}
                                    selectedSubscriptionType={
                                        selectedSubscriptionType
                                    }
                                    showLogo={false}
                                    showRegisterForm={!isRedirectingViaSignIn}
                                    showSignInTitle={false}
                                    submitButtonText={submitButtonText}
                                />
                            </div>
                        )}
                        {isSignedIn && (
                            <React.Fragment>
                                {freeTrialSelected && (
                                    <FreeTrialTermsBox
                                        accepted={freeTrialTermsAccepted}
                                        error={freeTrialAgreementError}
                                        onAcceptChange={
                                            handleFreeTrialTermsAccepted
                                        }
                                    />
                                )}
                                {!isRedirectingViaSignIn &&
                                    !isRedirectingViaRegistration && (
                                        <Button
                                            cssClassName={`${BASE_CLASS}__content__form__submit`}
                                            dataTestId="signed-in-submit-button"
                                            onClick={handleSubmit}>
                                            {submitButtonText}
                                        </Button>
                                    )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

/**
 * Replaces the current route with the given plan & sku. Assumes that proper validation has been
 * performed beforehand (such as checking to see if billing cycle/subscription match)
 * @param history
 * @param billingCycle
 * @param subscriptionType
 */
const replaceRouteFromOptions = (
    history: History,
    billingCycle?: BillingCycleOption,
    subscriptionType?: SubscriptionType,
    cultureCode?: string,
    icid?: string,
    sku?: string,
    freeTrialAgreementAccepted?: boolean
) => {
    // If no subscription type, bail
    if (subscriptionType == null) {
        return;
    }

    // Creates baseline route
    let redirectRoute: string = RouteUtils.getRegistrationPageRoute(
        subscriptionType,
        billingCycle?.pin
    );

    const routeAsArray = redirectRoute.split("?");

    redirectRoute = routeAsArray[0];

    // Will hold search params
    let queryParams = new URLSearchParams(routeAsArray[1] ?? "");

    // Translate to culture-coded URL, if possible
    if (cultureCode != null) {
        redirectRoute =
            RouteUtils.getCultureRedirectPath(redirectRoute, cultureCode) ??
            redirectRoute;
    }

    // Add search params, if available
    if (icid != null) {
        queryParams.append("icid", icid);
    }

    if (freeTrialAgreementAccepted) {
        queryParams.append(
            "freeTrialAgreementAccepted",
            freeTrialAgreementAccepted.toString()
        );
    }
    redirectRoute = redirectRoute + "?" + queryParams.toString();
    // Replace route in history
    history.replace(redirectRoute);
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default RegisterAccountPage;

// #endregion Exports
