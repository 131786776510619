import React, { HTMLAttributes, PropsWithChildren } from "react";
import { createPortal } from "react-dom";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface RootPortalProps extends HTMLAttributes<HTMLDivElement> {}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const rootLoader = (() => {
    let loaded = false;
    let rootElement: HTMLElement | null = null;

    const load = () => {
        loaded = true;
        rootElement = document.getElementById("root");

        return rootElement;
    };

    return {
        get() {
            if (loaded) {
                return rootElement;
            }

            return load();
        },
    };
})();

/**
 * Utility component to portal children to the root div.
 * @param props any HTML div attributes, and children
 */
const RootPortal: React.FC<PropsWithChildren<RootPortalProps>> = (
    props: PropsWithChildren<RootPortalProps>
) => {
    const root = rootLoader.get();
    if (!root) {
        return <div {...props}>{props.children}</div>;
    }

    return createPortal(<div {...props}>{props.children}</div>, root);
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default RootPortal;

// #endregion Exports
