import Button from "atoms/buttons/button";
import React, { useEffect } from "react";
import useEmulationMode from "utilities/contexts/emulation/use-emulation-mode";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { useEmulationAuthenticationStrategy } from "utilities/services/auth/emulation-authentication/use-emulation-authentication-strategy.hook";

interface EmulateOrganizationMemberButtonProps {
    guestId: number | undefined;
    isAdmin: boolean | undefined;
    organizationName: string;
    userRoleOrganizationId: number | undefined;
}

const BASE_CLASS = "c-support-user-detail-page__user-details__emulate";

const EmulateOrganizationMemberButton: React.FC<
    EmulateOrganizationMemberButtonProps
> = (props: EmulateOrganizationMemberButtonProps) => {
    const { guestId, isAdmin, organizationName, userRoleOrganizationId } =
        props;
    const { createOrgMemberToken, isInEmulationMode } = useEmulationMode();
    const { setEmulationUserID, setEmulateOrganizationMemberId } =
        useEmulationAuthenticationStrategy();

    useEffect(() => {
        setEmulationUserID(guestId);
        setEmulateOrganizationMemberId(userRoleOrganizationId);
    }, [
        guestId,
        setEmulateOrganizationMemberId,
        setEmulationUserID,
        userRoleOrganizationId,
    ]);

    const onClick = () => {
        createOrgMemberToken(guestId, userRoleOrganizationId);
    };

    const buttonText = isAdmin
        ? `Emulation not available for ${organizationName} Account`
        : `Emulate ${organizationName} Account`;

    return (
        <div className={BASE_CLASS}>
            <Button
                disabled={isInEmulationMode || isAdmin}
                size={ButtonSizes.Small}
                style={ButtonStyles.Primary}
                onClick={onClick}>
                {buttonText}
            </Button>
        </div>
    );
};

export default EmulateOrganizationMemberButton;
