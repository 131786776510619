import Button from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import ChangeIndicatorIcon from "atoms/icons/change-indicator-icon";
import Icon from "atoms/icons/icon";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { List } from "immutable";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import SectionRecord from "models/view-models/section-record";
import UserBookmarkRecord from "models/view-models/user-bookmark-record";
import MenuButton from "molecules/menu-button/menu-button";
import EnhancedContentModal from "organisms/enhanced-content/enhanced-content-modal";
import AddEditChangeSummariesModal from "organisms/panels/change-indicator-diff/change-summaries/add-edit-change-summaries-modal";
import BookmarkSettings from "organisms/section-detail/bookmark-settings";
import BookmarkSlider from "organisms/section-detail/bookmark-slider";
import ShareModal from "organisms/section-detail/share-modal";
import React, { useState } from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useReferencePanelContext } from "utilities/contexts/reference-panel/use-reference-panel-context";
import { useBookviewLayoutContext } from "utilities/contexts/ui-state-context/use-bookview-layout-context";
import useCurrentPublication from "utilities/contexts/use-current-publication";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import { UserBookmarkColors } from "utilities/enumerations/user-bookmark-colors";
import UserBookmarkType from "utilities/enumerations/user-bookmark-type";
import useEnhancedContent from "utilities/hooks/domain/enhanced-content/use-enhanced-content";
import useBookmarkActions from "utilities/hooks/domain/user-bookmarks/use-bookmark-actions";
import useBookmarks from "utilities/hooks/domain/user-bookmarks/use-bookmarks";
import useRenderBookmarks from "utilities/hooks/domain/user-bookmarks/use-render-bookmarks";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import EnhancedContentService from "utilities/services/sections/enhanced-content/enhanced-content-service";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-section-detail-actions";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface SectionDetailMenuProps {
    resources?: List<EnhancedContentResourceRecord>;
    section: SectionRecord;
    showEnhancedContentModal: boolean;
    onEditEnhancedContentClick: () => void;
    onEditEnhancedContentClose: () => void;
    handleDiffPanelOpen: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SectionDetailActions: React.FunctionComponent<SectionDetailMenuProps> =
    React.memo((props: SectionDetailMenuProps) => {
        // -----------------------------------------------------------------------------------------
        // #region Props
        // -----------------------------------------------------------------------------------------

        const {
            onEditEnhancedContentClick,
            onEditEnhancedContentClose,
            resources,
            section,
            showEnhancedContentModal,
            handleDiffPanelOpen,
        } = props;

        // #endregion Props

        // -----------------------------------------------------------------------------------------
        // #region State
        // -----------------------------------------------------------------------------------------

        const [currentBookmark, setCurrentBookmark] =
            useState<UserBookmarkRecord>(new UserBookmarkRecord());
        const { globalState } = useGlobalState();
        const { t } = useLocalization();

        const {
            handleBookmarksReadOnlyDialogClose,
            handleBookmarkSettingsClose,
            handleBookmarksReadOnlyDialogOpen,
            bookviewLayoutContext,
        } = useBookviewLayoutContext();

        const { publication } = useCurrentPublication();

        const {
            isOpen: showShareModal,
            handleClose: handleShareModalClose,
            handleOpen: handleShareModalOpen,
        } = useModalActions();
        const {
            isOpen: isChangeSummaryModalOpen,
            handleClose: onAddChangeSummaryClose,
            handleOpen: onAddChangeSummaryClick,
        } = useModalActions();

        const currentIdentity = globalState.currentIdentity;

        const userId = currentIdentity?.userId() ?? 0;

        const groupId =
            globalState.currentIdentity?.getCurrentTeam()?.id ?? undefined;

        const createdOnPublicationId = publication?.id;

        const isAdminOrPublisher =
            currentIdentity?.isAdmin() ||
            currentIdentity?.isAuthorOrPublisher();

        const { enhancedContentForCurrentCaapsItem } = useEnhancedContent({
            externalId: section.externalId,
        });

        const { bookmarks } = useBookmarks();
        const sectionBookmarks = bookmarks
            .filter((b) => b.externalId === section.externalId)
            .sort(getCurrentUserBookmarkComparator(userId));

        const { handleAddBookmark, handleEditBookmark, handleViewBookmarks } =
            useBookmarkActions({
                userBookmarkType: UserBookmarkType.Section,
                createdOnPublicationId: createdOnPublicationId,
                color: UserBookmarkColors.Red,
                externalId: section.externalId,
                userId: userId,
                groupId: groupId,
                setCurrentBookmark: setCurrentBookmark,
                bookmarks: sectionBookmarks,
            });

        const { renderPrimaryBookmark, renderSecondaryBookmark } =
            useRenderBookmarks({ baseClass: BASE_CLASS_NAME });

        const bookmarksDistinctByColor = CollectionUtils.distinctBy(
            sectionBookmarks,
            (b: UserBookmarkRecord) => b.color
        );

        const bookmarkCount = CollectionUtils.length(sectionBookmarks);
        const addBookmarkButtonText = t("addItem", { item: t("bookmark") });

        const bookmarkButtonAccessibleText = t("itemCount", {
            count: bookmarkCount,
            item: t("bookmark", { count: bookmarkCount }),
        });

        const enhancedContentActionLabel =
            enhancedContentForCurrentCaapsItem?.isPersisted()
                ? t("editItem", { item: t("enhancedContent") })
                : t("addItem", { item: t("enhancedContent") });
        const addChangeSummaryLabel = t("add-change-summary");

        const viewPriorVersionButtonText = t("viewChanges");

        // #endregion State

        // -----------------------------------------------------------------------------------------
        // #region Service Hooks
        // -----------------------------------------------------------------------------------------

        const { create } = EnhancedContentService.useCreate();
        const { update } = EnhancedContentService.useUpdate();

        // #endregion Service Hooks

        // -----------------------------------------------------------------------------------------
        // #region Handlers
        // -----------------------------------------------------------------------------------------

        const handleCreate = (enhancedContent: EnhancedContentRecord) =>
            create(enhancedContent);
        const handleUpdate = (enhancedContent: EnhancedContentRecord) =>
            update(enhancedContent, { id: enhancedContent.id! });

        const { handleAdd, referenceForSectionExists } =
            useReferencePanelContext();
        const handleAddReferenceClick = (
            event: React.MouseEvent<HTMLElement>
        ) => {
            handleAdd?.(section?.id);
        };

        // #endregion Handlers

        const referencePanelButtonText = referenceForSectionExists(section?.id)
            ? t("reference-panel-open-in")
            : t("reference-panel-add-to");
        const openForSectionId =
            bookviewLayoutContext.bookmarkSettingsOpenForExternalId;
        const readonlyOpenForSectionId =
            bookviewLayoutContext.bookmarkReadonlyOpenForExternalId;
        const renderReadonlySlider =
            readonlyOpenForSectionId != null &&
            section.isPersisted() &&
            readonlyOpenForSectionId === section.externalId;
        const renderSettings =
            openForSectionId != null &&
            section.isPersisted() &&
            openForSectionId === section.externalId;
        const showViewPriorVersionsButton =
            section.doesTitleOrBodyHaveChanges();
        const showChangeSummariesModal =
            isChangeSummaryModalOpen &&
            publication?.code != null &&
            publication?.edition != null;
        const changeNotation =
            section.changes === XmlChangeNotationConstants.NEW_MATERIAL
                ? XmlChangeNotationConstants.NEW_MATERIAL
                : XmlChangeNotationConstants.HAS_CHANGES;

        return (
            <div className={BASE_CLASS_NAME}>
                <div className={`${BASE_CLASS_NAME}__notes`}>
                    {CollectionUtils.hasValues(sectionBookmarks) && (
                        <Button
                            cssClassName={`${bookmarkCount > 1 && "multi"}`}
                            accessibleText={bookmarkButtonAccessibleText}
                            dataTestId="section-bookmark"
                            style={ButtonStyles.TertiaryAlt}
                            onClick={() =>
                                handleBookmarksReadOnlyDialogOpen(
                                    section.externalId!
                                )
                            }>
                            {renderPrimaryBookmark(
                                bookmarksDistinctByColor[0],
                                bookmarkCount
                            )}
                            {CollectionUtils.length(bookmarksDistinctByColor) >
                                1 &&
                                renderSecondaryBookmark(
                                    bookmarksDistinctByColor.slice(1)
                                )}
                        </Button>
                    )}
                </div>
                <MenuButton
                    buttonAccessibleText={t("actionsForItem", {
                        item: t("section"),
                    })}
                    dataTestId={"section-detail-actions-menu-button"}
                    icon={Icons.MoreHorizontal}
                    iconSize={IconSizes.Base}
                    key={"menu-button"}
                    modalClassName={`${BASE_CLASS_NAME}__modal`}
                    triggerButtonStyle={ButtonStyles.Tertiary}
                    offlineAlertOptions={{
                        enabled: true,
                        tooltipDescription: t("offline-fallback-description"),
                        tooltipHeader: t("section-detail-menu-feature-name"),
                    }}>
                    <Button
                        accessibleText={t("reference-panel-add-to")}
                        dataTestId={"reference-panel-add-to"}
                        onClick={handleAddReferenceClick}>
                        <Icon type={Icons.ReferencePanel}></Icon>
                        {referencePanelButtonText}
                    </Button>
                    <Button dataTestId={"bookmark"} onClick={handleAddBookmark}>
                        <Icon type={Icons.Bookmark} />

                        {addBookmarkButtonText}
                    </Button>
                    {bookmarkCount > 0 && (
                        <Button onClick={handleViewBookmarks}>
                            <Icon type={Icons.Eye} />
                            {t("view-bookmarks")}
                        </Button>
                    )}
                    <Button dataTestId={"share"} onClick={handleShareModalOpen}>
                        <Icon type={Icons.Share} />
                        {t("share")}
                    </Button>
                    {showViewPriorVersionsButton && (
                        <Button
                            accessibleText={t("viewChanges")}
                            ariaControls={"diff-panel"}
                            onClick={handleDiffPanelOpen}>
                            <ChangeIndicatorIcon
                                changes={changeNotation}
                                cssClassName=""
                            />
                            {viewPriorVersionButtonText}
                        </Button>
                    )}
                    {isAdminOrPublisher && (
                        <Button
                            dataTestId={"enhancedContent"}
                            onClick={onEditEnhancedContentClick}>
                            {enhancedContentActionLabel}
                        </Button>
                    )}
                    {showViewPriorVersionsButton && isAdminOrPublisher && (
                        <Button onClick={onAddChangeSummaryClick}>
                            {addChangeSummaryLabel}
                        </Button>
                    )}
                </MenuButton>
                {renderReadonlySlider && (
                    <BookmarkSlider
                        bookmarks={sectionBookmarks}
                        onClose={handleBookmarksReadOnlyDialogClose}
                        onDeleted={handleBookmarksReadOnlyDialogClose}
                        onEdit={handleEditBookmark}
                        section={section}
                        title={section.getFullyQualifiedDisplayTitle()}
                        handleAddBookmark={handleAddBookmark}
                    />
                )}
                {renderSettings && (
                    <BookmarkSettings
                        onCancel={handleBookmarkSettingsClose}
                        onDelete={handleBookmarkSettingsClose}
                        bookmark={currentBookmark}
                        externalId={section.externalId}
                        section={section}
                        title={section.getFullyQualifiedDisplayTitle()}
                        userBookmarkType={UserBookmarkType.Section}
                    />
                )}
                {showShareModal && (
                    <ShareModal
                        onClose={handleShareModalClose}
                        section={section}
                    />
                )}
                {showEnhancedContentModal && (
                    <EnhancedContentModal
                        enhancedContent={
                            enhancedContentForCurrentCaapsItem ?? undefined
                        }
                        externalId={section.externalId}
                        files={resources?.map((r) => r.fileDraft!).toList()}
                        onClose={onEditEnhancedContentClose}
                        onCreate={handleCreate}
                        onEditClick={onEditEnhancedContentClick}
                        onUpdate={handleUpdate}
                        resources={resources}
                        title={section.getFullyQualifiedDisplayTitle()}
                    />
                )}
                {showChangeSummariesModal && (
                    <AddEditChangeSummariesModal
                        externalId={section.externalId}
                        title={section.getFullyQualifiedDisplayTitle()}
                        code={publication.code!}
                        edition={publication.edition!}
                        publicationComponentType={
                            PublicationComponentType.Section
                        }
                        onClose={onAddChangeSummaryClose}
                    />
                )}
            </div>
        );
    });

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

// Force the current user's bookmark to the beginning of the list.
const getCurrentUserBookmarkComparator = (currentUserId: number) => {
    return (a: UserBookmarkRecord, b: UserBookmarkRecord) => {
        if (a.userId === currentUserId) {
            return -1;
        }

        if (b.userId === currentUserId) {
            return 1;
        }

        return 0;
    };
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default SectionDetailActions;

// #endregion Export
