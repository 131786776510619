import OrganizationManifestRecord from "./organization-manifest-record";
import UserRoleOrganization from "models/interfaces/user-role-organization";
import UserRoleRecord from "models/view-models/user-role-record";
import { Record } from "immutable";
import { RecordUtils } from "@rsm-hcd/javascript-core";

const defaultValues: UserRoleOrganization =
    RecordUtils.auditableDefaultValuesFactory<UserRoleOrganization>({
        organization: undefined,
        organizationId: 0,
        organizationMemberId: "",
        userRole: undefined,
        userRoleId: 0,
    });

export default class UserRoleOrganizationRecord
    extends Record(defaultValues)
    implements UserRoleOrganization
{
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: UserRoleOrganization) {
        if (params == null) {
            params = Object.assign({}, defaultValues);
        }

        if (params.organization != null) {
            params.organization = RecordUtils.ensureRecord(
                params.organization,
                OrganizationManifestRecord
            );
        }

        if (params.userRole != null) {
            params.userRole = RecordUtils.ensureRecord(
                params.userRole,
                UserRoleRecord
            );
        }

        super(params);
    }

    // #endregion Constructor

    // -------------------------------------------------------------------------------------------------
    // #region Public Methods
    // -------------------------------------------------------------------------------------------------

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<UserRoleOrganization>} values
     */
    public with(
        values: Partial<UserRoleOrganization>
    ): UserRoleOrganizationRecord {
        return new UserRoleOrganizationRecord(
            Object.assign(this.toJS(), values)
        );
    }

    // #endregion Public Methods
}
