import AuthService from "utilities/services/auth/auth-service";
import EmulationTokenRecord from "models/view-models/emulation-token-record";
import UserLoginRecord from "models/view-models/user-login-record";
import useCurrentIdentity from "utilities/hooks/use-current-identity";
import useLocalStorage from "utilities/hooks/use-local-storage";
import useRefreshIdentity from "utilities/hooks/use-refresh-identity";
import { AuthenticationStrategyType } from "utilities/enumerations/authorization";
import { CookieUtils } from "utilities/cookie-utils";
import { EmulationModeContext } from "utilities/contexts/emulation/emulation-mode-context-provider";
import { RouteUtils } from "utilities/route-utils";
import { ToastManager } from "utilities/toast/toast-manager";
import { siteMap } from "internal-sitemap";
import { useAuthentication } from "utilities/contexts/authentication/authentication-provider";
import { useAuthenticationFactory } from "utilities/contexts/authentication/use-authentication.factory";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";

const UNABLE_TO_CREATE_TOKEN = "Unable to create emulation token.";
const ACCESS_TOKEN = "AccessToken";

export default function useEmulationMode() {
    const {
        setLoadingEmulationSession,
        setEndingEmulationSession,
        token,
        setToken,
        userEmail,
    } = useContext(EmulationModeContext);
    const { authenticationFactory } = useAuthenticationFactory();
    const { login, endEmulationSession } = useAuthentication();
    const { build } = useCurrentIdentity();
    const { setLoading } = useRefreshIdentity();
    const history = useHistory();
    const timeoutId = useRef<number | null>(null);
    const { getValue } = useLocalStorage<EmulationTokenRecord | null>(
        "token",
        null
    );

    const deleteToken = useCallback(
        async (guestId: number | undefined) => {
            setLoading(true);
            setEndingEmulationSession(true);

            await endEmulationSession();

            const userLogin = await AuthService.create();
            const userLoginRecord = new UserLoginRecord(userLogin.resultObject);
            await build(userLoginRecord);

            setEndingEmulationSession(false);

            history.push(
                RouteUtils.getUrl(siteMap.admin.support.userDetail, {
                    id: guestId,
                })
            );

            if (timeoutId.current != null) {
                clearTimeout(timeoutId.current);
            }
        },
        [build, history, endEmulationSession, setEndingEmulationSession]
    );

    const countDownToExpiration = (expiresOn: string | undefined) => {
        if (expiresOn !== undefined) {
            const expiresOnTime = Date.parse(expiresOn);
            const countdownMilliseconds = expiresOnTime - Date.now();
            return countdownMilliseconds;
        }
    };

    useEffect(() => {
        if (token != null) {
            timeoutId.current = setTimeout(async () => {
                await deleteToken(token?.guestId);
            }, countDownToExpiration(token?.expiresOn));
        }
        return () => {
            if (timeoutId.current != null) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [deleteToken, token]);

    const createToken = async (guestId: number | undefined) => {
        if (guestId == null) {
            return ToastManager.error(UNABLE_TO_CREATE_TOKEN);
        }

        const accessToken = CookieUtils.get(ACCESS_TOKEN);

        if (!accessToken) {
            const authStrategyResult = await authenticationFactory(
                AuthenticationStrategyType.AzureB2C
            );

            await authStrategyResult.authStrategy.verifyLogin();
        }
        setLoadingEmulationSession(true);

        await login(AuthenticationStrategyType.Emulation);

        history.push(RouteUtils.getUrl(siteMap.dashboards.user));
        setLoadingEmulationSession(false);
    };

    const createOrgMemberToken = async (
        guestId: number | undefined,
        userRoleOrganizationId: number | undefined
    ) => {
        if (guestId == null || userRoleOrganizationId == null) {
            return ToastManager.error(UNABLE_TO_CREATE_TOKEN);
        }
        setLoadingEmulationSession(true);

        await login(AuthenticationStrategyType.Emulation);

        history.push(RouteUtils.getUrl(siteMap.dashboards.user));
        setLoadingEmulationSession(false);
    };

    const isInEmulationMode = useMemo(() => {
        return getValue() != null;
    }, [getValue]);

    return {
        isInEmulationMode,
        token,
        setToken,
        userEmail,
        createToken,
        createOrgMemberToken,
        login: login,
        deleteToken,
    };
}
