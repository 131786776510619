import AuthService from "../auth-service";
import EmulationService from "../../support/emulation-service";
import EmulationTokenRecord from "models/view-models/emulation-token-record";
import UserLogin from "models/interfaces/user-login";
import UserLoginRecord from "models/view-models/user-login-record";
import {
    AuthenticationStrategy,
    LoginResponse,
} from "utilities/interfaces/authentication-strategy";
import { ToastManager } from "utilities/toast/toast-manager";

export class EmulationAuthenticationStrategy implements AuthenticationStrategy {
    private static readonly UNABLE_TO_CREATE_TOKEN =
        "Unable to create emulation token.";
    private static readonly UNABLE_TO_VERIFY_AUTH =
        "Unable to verify authentication.";

    constructor(
        private emulatedUserId: number,
        private userRoleOrganizationId: number,
        private setToken: (value: EmulationTokenRecord | null) => void,
        private getToken: () => EmulationTokenRecord | null,
        private userLogin: UserLoginRecord | null
    ) {}
    public async login(): Promise<LoginResponse> {
        try {
            if (this.emulatedUserId === 0) {
                return {
                    shouldVerifyLogin: false,
                };
            }
            const request = await EmulationService.create(
                new EmulationTokenRecord({
                    guestId: this.emulatedUserId,
                    userRoleOrganizationId: this.userRoleOrganizationId,
                })
            );
            const newToken = request.resultObject;

            const success = request.status === 200;
            if (
                success &&
                newToken != null &&
                newToken.id != null &&
                newToken.expiresOn != null &&
                newToken.guestId != null
            ) {
                this.setToken(newToken);
                return {
                    shouldVerifyLogin: true,
                };
            } else {
                ToastManager.error(
                    EmulationAuthenticationStrategy.UNABLE_TO_CREATE_TOKEN
                );
            }
        } catch (error) {
            ToastManager.error(
                EmulationAuthenticationStrategy.UNABLE_TO_CREATE_TOKEN
            );
        }
        return {
            shouldVerifyLogin: false,
        };
    }

    public async verifyLogin(): Promise<UserLoginRecord> {
        try {
            const userLoginResult = await this.auth();

            if (userLoginResult === null) {
                this.logout();
                return new UserLoginRecord();
            }
            return new UserLoginRecord(userLoginResult);
        } catch (error) {
            ToastManager.error(
                EmulationAuthenticationStrategy.UNABLE_TO_VERIFY_AUTH
            );
        }
        return new UserLoginRecord();
    }

    public async logout(): Promise<void> {
        try {
            const token = this.getToken();
            if (token?.id !== undefined) {
                const emulationTokenDeleted = await EmulationService.delete(
                    token?.id
                );

                if (emulationTokenDeleted) {
                    this.setToken(null);
                }
            }
        } catch (error) {
            ToastManager.error(
                "There was an error deleting emulation session token"
            );
        }
    }

    public async forgotPassword(isComingFromLogin: boolean): Promise<void> {
        throw new Error("Method not implemented.");
    }

    public async signUp(): Promise<void> {
        throw new Error("Method not implemented.");
    }

    private async auth(): Promise<UserLogin | null> {
        const response = await AuthService.create();
        if (!response.resultObject) {
            throw new Error("Unable to get User Login");
        }

        return response.resultObject;
    }
}
