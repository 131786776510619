import React, { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import TableOfContents from "organisms/table-of-contents/table-of-contents";
import { siteMap } from "internal-sitemap";
import Paragraph from "atoms/typography/paragraph";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import RemoteImage from "atoms/images/remote-image";
import { LoaderStyles } from "atoms/constants/loader-styles";
import AnchorWithIcon from "atoms/anchors/anchor-with-icon";
import { t } from "utilities/localization-utils";
import { Icons } from "atoms/constants/icons";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { RouteUtils } from "utilities/route-utils";
import usePublication from "utilities/hooks/domain/publications/public/use-publication";
import CallToAction, {
    CallToActionType,
} from "molecules/call-to-action/call-to-action";
import { NestedPublicationParams } from "interfaces/routing/nested-publication-params";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import StructuredData from "molecules/structured-data/structured-data";
import usePublicationStructuredData from "utilities/hooks/structured-data/use-publication-structured-data";
import useBookviewTableOfContents from "utilities/hooks/domain/publications/use-bookview-table-of-contents";
import TableOfContentsRecord from "models/view-models/table-of-contents/table-of-contents-record";
import PlansAnchor from "molecules/plans-anchor/plans-anchor";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface PublicationPreviewPageProps {}

interface RouteParams
    extends Pick<NestedPublicationParams, "code" | "edition"> {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASSNAME = "c-publication-preview-page";

// #endregion Constants

const PublicationPreviewPage: React.FunctionComponent<
    PublicationPreviewPageProps
> = (props: PublicationPreviewPageProps) => {
    const { globalState } = useGlobalState();
    const { currentIdentity } = globalState;
    const { code, edition } = useParams<RouteParams>();
    const { loaded, resultObject: publication } = usePublication({
        code,
        edition,
    });

    const { getTableOfContents, setTableOfContents } =
        useBookviewTableOfContents(true);

    useEffect(() => {
        getTableOfContents(publication.id);

        return () => setTableOfContents(new TableOfContentsRecord());
    }, [getTableOfContents, publication.id, setTableOfContents]);

    const publicationStructuredData = usePublicationStructuredData(publication);

    if (loaded && publication.id == null) {
        return <Redirect to={siteMap.publications.public.all} />;
    }

    if (currentIdentity?.isConfiguredWithActiveSubscription()) {
        const permaLink = RouteUtils.getUrl(
            siteMap.publications.permalinks.publication,
            {
                code,
                edition,
            }
        );
        return <Redirect to={permaLink} />;
    }

    return (
        <React.Fragment>
            <StructuredData jsonLd={publicationStructuredData} />
            <div className={BASE_CLASSNAME}>
                <div className={`${BASE_CLASSNAME}__header`}>
                    <div className={`${BASE_CLASSNAME}__header-content`}>
                        <div className={`${BASE_CLASSNAME}__title-card`}>
                            <AnchorWithIcon
                                accessibleText={t("backToLocation", {
                                    location: t("publication_plural"),
                                })}
                                icon={Icons.ChevronLeft}
                                size={ButtonSizes.Small}
                                style={ButtonStyles.TertiaryAlt}
                                to={RouteUtils.getUrl(
                                    siteMap.publications.public.all
                                )}>
                                {t("backToLocation", {
                                    location: t("publication_plural"),
                                })}
                            </AnchorWithIcon>

                            <div
                                className={`${BASE_CLASSNAME}__identification`}>
                                {publication.coverImageFileId != null && (
                                    <div className={`${BASE_CLASSNAME}__cover`}>
                                        <RemoteImage
                                            file={publication.coverImageFile}
                                            loaderType={
                                                LoaderStyles.LinkGlyphGray
                                            }
                                        />
                                    </div>
                                )}

                                {loaded && (
                                    <div
                                        className={`${BASE_CLASSNAME}__metadata`}>
                                        <Paragraph
                                            cssClassName={`${BASE_CLASSNAME}__edition`}
                                            size={ParagraphSizes.Large}>
                                            {publication.edition}
                                        </Paragraph>
                                        <Heading
                                            cssClassName={`${BASE_CLASSNAME}__code`}
                                            priority={HeadingPriority.Three}>
                                            {`NFPA ${publication.code}`}
                                        </Heading>
                                        <Heading
                                            cssClassName={`${BASE_CLASSNAME}__title`}
                                            priority={HeadingPriority.Five}>
                                            {publication.title}
                                        </Heading>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className={`${BASE_CLASSNAME}__call-to-action`}>
                            <Heading
                                priority={HeadingPriority.Two}
                                cssClassName={`${BASE_CLASSNAME}__call-to-action__heading`}>
                                {t("publicationPreview-previewMode")}
                            </Heading>
                            <Paragraph
                                cssClassName={`${BASE_CLASSNAME}__call-to-action__message`}>
                                {t("publicationPreview-callToAction-public")}
                            </Paragraph>
                            <PlansAnchor cssClassName="c-button">
                                {t("featuredPublications-c2a-buttonText")}
                            </PlansAnchor>
                        </div>
                    </div>
                </div>
                <div className={`${BASE_CLASSNAME}__content`}>
                    {loaded && (
                        <TableOfContents
                            disableExpandOrCollapseAll={true}
                            isPublic={true}
                            publication={publication}
                        />
                    )}
                    <CallToAction
                        message={t("featuredPublications-c2a-text")}
                        title={t("featuredPublications-c2a-buttonText")}
                        type={CallToActionType.Anchor}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default PublicationPreviewPage;

// #endregion Exports
