import OrganizationSupportRecord from "models/view-models/organization-support-record";
import ServiceFactory from "../service-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OrganizationSupportPathParams {
    organizationId: number;
}

// #endregion Interfaces

const resourceEndpoint = "organizations/:organizationId/support";

const resourceType = OrganizationSupportRecord;

const OrganizationSupportsService = {
    get: ServiceFactory.get<
        OrganizationSupportRecord,
        OrganizationSupportPathParams
    >(resourceType, resourceEndpoint),
};

export default OrganizationSupportsService;
