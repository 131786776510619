import EmulationTokenRecord from "models/view-models/emulation-token-record";
import React from "react";
import useLocalStorage from "utilities/hooks/use-local-storage";
import { AuthenticationStrategyFactoryHookResult } from "utilities/services/auth/authentication-strategy-factory-hook-result";
import { AuthenticationStrategyResult } from "utilities/services/auth/authentication-strategy-result";
import { EmulationAuthenticationStrategy } from "./emulation-authentication-strategy";
import { PropsWithChildren, useCallback } from "react";
import { atom, useAtom } from "jotai";

interface EmulationAuthentication {
    emulatedUserId: number;
    userRoleOrganizationId: number;
}

const defaultEmulationAuthentication: EmulationAuthentication = {
    emulatedUserId: 0,
    userRoleOrganizationId: 0,
};

const emulationAuthenticationAtom = atom<EmulationAuthentication>(
    defaultEmulationAuthentication
);

interface EmulationAuthenticationStrategyHookResult
    extends AuthenticationStrategyFactoryHookResult {
    setEmulationUserID: (emulationUserId: number | undefined) => void;
    setEmulateOrganizationMemberId: (
        userRoleOrganizationId: number | undefined
    ) => void;
}

export function useEmulationAuthenticationStrategy(): EmulationAuthenticationStrategyHookResult {
    const [emulationAuthentication, setEmulationAuthentication] = useAtom(
        emulationAuthenticationAtom
    );
    const { setValue, getValue } = useLocalStorage<EmulationTokenRecord | null>(
        "token",
        null
    );

    const authStrategyFactory =
        async (): Promise<AuthenticationStrategyResult> => {
            const emulationAuthenticationStrategy =
                new EmulationAuthenticationStrategy(
                    emulationAuthentication.emulatedUserId,
                    emulationAuthentication.userRoleOrganizationId,
                    setValue,
                    getValue,
                    null
                );

            const authenticationStrategyResult: AuthenticationStrategyResult = {
                authStrategy: emulationAuthenticationStrategy,
                getAuthWrapperComponent: getWrapperComponent,
            };

            return Promise.resolve(authenticationStrategyResult);
        };

    const create = useCallback(authStrategyFactory, [
        emulationAuthentication.emulatedUserId,
        emulationAuthentication.userRoleOrganizationId,
        setValue,
        getValue,
    ]);

    const getWrapperComponent = () => {
        const EmulationWrapperComponent: React.FC<PropsWithChildren> = ({
            children,
        }) => {
            return <div>{children}</div>;
        };

        return EmulationWrapperComponent;
    };

    const setEmulationUserID = useCallback(
        (emulationUserId: number | undefined) => {
            if (emulationUserId === undefined) {
                return;
            }
            setEmulationAuthentication((previous) => {
                return {
                    ...previous,
                    emulatedUserId: emulationUserId,
                };
            });
        },
        [setEmulationAuthentication]
    );

    const setEmulateOrganizationMemberId = useCallback(
        (userRoleOrganizationId: number | undefined) => {
            if (userRoleOrganizationId === undefined) {
                return;
            }
            setEmulationAuthentication((previous) => {
                return {
                    ...previous,
                    userRoleOrganizationId: userRoleOrganizationId,
                };
            });
        },
        [setEmulationAuthentication]
    );

    return { create, setEmulationUserID, setEmulateOrganizationMemberId };
}
