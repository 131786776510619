import Anchor from "atoms/anchors/anchor";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { AppNameTm } from "constants/app-name-tm";
import SubscriptionType from "organisms/enums/subscription-type";
import SubscriptionCards from "organisms/subscription-cards/subscription-cards";
import React from "react";
import useSubscriptionCards from "utilities/hooks/domain/subscriptions/use-subscription-cards";
import { t } from "utilities/localization-utils";
import LandingPageSectionWrapper, {
    LandingPageSectionIds,
} from "./landing-page-section-wrapper";
import { RouteUtils } from "utilities/route-utils";

interface LandingPagePlansSectionProps {}

const BASE_CSS_CLASSNAME = "c-landing-page-plans";

const LandingPagePlansSection = (props: LandingPagePlansSectionProps) => {
    const {
        handleBillingCycleCheck,
        handleEnterpriseCardSelect,
        handleIndividualCardSelect,
        handleTeamCardSelect,
        resetModal,
        showBillingModal,
        selectedBillingCycle,
        selectedSubscriptionType,
    } = useSubscriptionCards();

    return (
        <LandingPageSectionWrapper id={LandingPageSectionIds.Plans}>
            <div className={`${BASE_CSS_CLASSNAME}__header`}>
                <Heading
                    cssClassName={`${BASE_CSS_CLASSNAME}__title`}
                    priority={HeadingPriority.Two}>
                    {t("selectAPlanToGetStarted")}
                </Heading>
                <Paragraph cssClassName={`${BASE_CSS_CLASSNAME}__intro`}>
                    {t("selectAPlanShortDescription")}
                </Paragraph>
            </div>
            <SubscriptionCards
                cssClassName={BASE_CSS_CLASSNAME}
                cardCssClassNameModifier="-landingPageSectionCard"
                handleEnterpriseCardSelect={handleEnterpriseCardSelect}
                handleIndividualCardSelect={handleIndividualCardSelect}
                handleTeamCardSelect={handleTeamCardSelect}
                resetModal={resetModal}
                handleBillingCycleCheck={handleBillingCycleCheck}
                selectedBillingCycle={selectedBillingCycle}
                selectedSubscriptionType={selectedSubscriptionType}
                showBillingModal={showBillingModal}
            />
            <div className={`${BASE_CSS_CLASSNAME}__footer`}>
                <Paragraph size={ParagraphSizes.Large}>
                    {t("plansPage-interestedInTryingLink", {
                        appName: AppNameTm,
                    })}
                    &nbsp;
                    <Anchor
                        cssClassName={`${BASE_CSS_CLASSNAME}__free-trial-link`}
                        to={RouteUtils.getRegistrationPageRoute(
                            SubscriptionType.FreeTrial
                        )}>
                        {t("plansPage-freeTrial-callToAction", {
                            appName: AppNameTm,
                        })}
                    </Anchor>
                </Paragraph>
            </div>
        </LandingPageSectionWrapper>
    );
};

export default LandingPagePlansSection;
