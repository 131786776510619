import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { Icons } from "atoms/constants/icons";
import Checkbox from "atoms/forms/checkbox";
import AlertLevels from "constants/alert-levels";
import { atom, useAtom } from "jotai";
import GlobalStateRecord from "models/view-models/global-state-record";
import UserSettingRecord from "models/view-models/user-setting-record";
import AlertBanner from "molecules/alerts/alert-banner";
import MenuButton from "molecules/menu-button/menu-button";
import React, { useEffect, useRef, useState } from "react";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useUserSettings from "utilities/hooks/domain/user-settings/use-user-settings";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface BookviewUserPreferencesProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-bookview-user-preferences";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------
export const isOpenAtom = atom(false);

const BookViewUserPreferences: React.FC<BookviewUserPreferencesProps> = (
    props: BookviewUserPreferencesProps
) => {
    const { globalState, setGlobalState } = useGlobalState();
    const { t } = useLocalization();
    const { createOrUpdate: createOrUpdateUserSettings } = useUserSettings();

    const [loading, setLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useAtom(isOpenAtom);

    const showDeletedTiasSetting =
        globalState.getUserSetting("TiasDeletedShow");
    const showDeletedTiasEnabled =
        showDeletedTiasSetting?.getValue<boolean>() ?? false;

    const expandEnhancedContentSetting = globalState.getUserSetting(
        "EnhancedContentExpand"
    );

    const expandEnhancedContentEnabled =
        expandEnhancedContentSetting?.getValue<boolean>() ?? false;

    const noPreferencesFound =
        expandEnhancedContentSetting == null && showDeletedTiasSetting == null;

    const showEnhancedContentSetting = globalState.getUserSetting(
        "EnhancedContentShow"
    );
    const showEnhancedContentEnabled =
        showEnhancedContentSetting?.getValue<boolean>() ?? false;

    const currentUserId = globalState.currentIdentity?.userId();

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    const renderExpandSetting = showEnhancedContentEnabled && !isMobile;

    const handleSettingToggle = async (
        value: boolean,
        setting?: UserSettingRecord
    ) => {
        if (currentUserId == null || loading || value == null) {
            return;
        }

        setLoading(true);

        const dto = setting?.with({
            value: `${value}`,
        });

        const result = await createOrUpdateUserSettings(dto, currentUserId);

        if (result == null) {
            setLoading(false);
            return;
        }

        setGlobalState((globalState: GlobalStateRecord) =>
            globalState.updateUserSetting(result)
        );
        setLoading(false);
    };

    const handleExpandSettingToggle = (value: boolean) =>
        handleSettingToggle(value, expandEnhancedContentSetting);

    const handleShowSettingToggle = (value: boolean) =>
        handleSettingToggle(value, showEnhancedContentSetting);

    const handleShowDeletedTiasToggle = (value: boolean) =>
        handleSettingToggle(value, showDeletedTiasSetting);

    const className = `${BASE_CLASS_NAME}${
        !showEnhancedContentEnabled ? " -ec-hidden" : ""
    }`;

    const title = t("usersettings-viewingPreferences");

    const ref = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (ref.current && isOpen) ref.current.focus();
    }, [isOpen]);

    return (
        <div className={BASE_CLASS_NAME}>
            <MenuButton
                cssClassName={!showEnhancedContentEnabled ? "-ec-hidden" : ""}
                buttonAccessibleText={title}
                externalIsOpen={isOpen}
                externalSetIsOpen={setIsOpen}
                icon={Icons.Preferences}
                modalClassName={className}
                offlineAlertOptions={{
                    enabled: true,
                    tooltipDescription: t("offline-fallback-description"),
                    tooltipHeader: t("usersettings-viewingPreferences"),
                }}
                title={title}
                triggerButtonStyle={ButtonStyles.TertiaryAlt}
                triggerButtonSize={ButtonSizes.XSmall}>
                {noPreferencesFound && t("usersettings-noPreferencesFound")}

                {expandEnhancedContentSetting != null && (
                    <React.Fragment>
                        <Checkbox
                            checked={showEnhancedContentEnabled}
                            disabled={loading}
                            label={t("usersettings-showEnhancedContent")}
                            onChange={handleShowSettingToggle}
                            ref={ref}
                        />

                        {renderExpandSetting && (
                            <div
                                className={`${BASE_CLASS_NAME}__expand-option`}>
                                <Checkbox
                                    checked={expandEnhancedContentEnabled}
                                    disabled={
                                        !showEnhancedContentEnabled || loading
                                    }
                                    label={t("usersettings-expandByDefault")}
                                    onChange={handleExpandSettingToggle}
                                />
                            </div>
                        )}
                    </React.Fragment>
                )}

                {!showEnhancedContentEnabled && (
                    <AlertBanner alertLevel={AlertLevels.Warning}>
                        {t("usersettings-enhancedContentHidden")}
                    </AlertBanner>
                )}

                {showDeletedTiasSetting != null && (
                    <Checkbox
                        checked={showDeletedTiasEnabled}
                        disabled={loading}
                        label={t("usersettings-showDeletedTIAs")}
                        onChange={handleShowDeletedTiasToggle}
                    />
                )}

                {!showDeletedTiasEnabled && (
                    <AlertBanner alertLevel={AlertLevels.Warning}>
                        {t("usersettings-deleteTiasHidden")}
                    </AlertBanner>
                )}
            </MenuButton>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default BookViewUserPreferences;

// #endregion Exports
