import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import ChangeIndicatorIcon from "atoms/icons/change-indicator-icon";
import TiaRecord from "models/view-models/tia-record";
import TiaIndicatorButton from "organisms/tia-indicators/tia-indicator-button";
import React from "react";
import { t } from "utilities/localization-utils";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";

interface IndicatorIconsProps {
    tias: TiaRecord[];
    changes: string | undefined;
    handleDiffPanelOpen: () => void;
    handleTiaClick: (tiaId: string) => void;
    cssClassName?: string;
    notClickable?: boolean;
}

const IndicatorIcons: React.FunctionComponent<IndicatorIconsProps> = (
    props: IndicatorIconsProps
) => {
    const {
        tias,
        changes,
        handleDiffPanelOpen,
        handleTiaClick,
        notClickable = false,
    } = props;

    const showTias = tias.length > 0;

    return showTias ? (
        <TiaIndicatorButton tias={tias} handleTiaClick={handleTiaClick} />
    ) : (
        <ChangeIndicatorButton
            accessibleText={t("viewChanges")}
            changes={changes}
            handleClick={handleDiffPanelOpen}
            notClickable={notClickable}
        />
    );
};

export default IndicatorIcons;

interface ChangeIndicatorButtonProps {
    accessibleText: string;
    changes?: string;
    handleClick: () => void;
    notClickable?: boolean;
}
const ChangeIndicatorButton = ({
    accessibleText,
    changes,
    handleClick,
    notClickable,
}: ChangeIndicatorButtonProps) => {
    if (changes === XmlChangeNotationConstants.NO_CHANGES || changes == null)
        return null;
    return (
        <div className="c-tia-indicator">
            <Button
                disabled={notClickable}
                accessibleText={accessibleText}
                cssClassName="c-tia-indicator__button"
                style={ButtonStyles.None}
                type={ButtonTypes.Button}
                onClick={handleClick}>
                <ChangeIndicatorIcon changes={changes} />
            </Button>
        </div>
    );
};
