import AuthService from "utilities/services/auth/auth-service";
import B2C_CONFIG from "pages/azure-login/b2c-config";
import UserLoginRecord from "models/view-models/user-login-record";
import {
    AuthenticationStrategy,
    LoginResponse,
} from "utilities/interfaces/authentication-strategy";
import { AzureB2CCustomState } from "utilities/services/auth/azure-location-state";
import { AzureTokenInformation } from "utilities/services/auth/azure-strategies/azure-token-information";
import { Configuration, IPublicClientApplication } from "@azure/msal-browser";
import { createAzureAccessToken } from "utilities/services/auth/azure-strategies/create-azure-access-token";
import { getAzureAccessToken } from "utilities/services/auth/azure-strategies/get-azure-access-token";
import {
    removeAccessTokenFromCookie,
    saveAccessTokenInCookie,
    getAccessTokenFromCookie,
} from "utilities/services/auth/azure-access-token";
import { siteMap } from "internal-sitemap";

export class AzureAuthenticationStrategy implements AuthenticationStrategy {
    constructor(
        private isAuthenticatedUser: boolean,
        private useSsoSilent: boolean,
        private currentCulture: string,
        private callbackUrl: string,
        private instance: IPublicClientApplication,
        private config: Configuration,
        private userLogin: UserLoginRecord | undefined
    ) {}

    public async verifyLogin(): Promise<UserLoginRecord> {
        if (this.isAuthenticatedUser && this.userLogin) {
            const accessToken = getAccessTokenFromCookie();
            if (!accessToken) {
                await this.trySilentTokenAcquisition();
            }
            return this.userLogin;
        }
        // console.log("***Starting Azure SSO");

        try {
            const redirectResponse =
                await this.instance.handleRedirectPromise(); //ALREADY HAS THE TOKEN
            if (redirectResponse != null) {
                // console.log("***Completed handleRedirectPromise");
                saveAccessTokenInCookie(
                    redirectResponse.accessToken,
                    redirectResponse.expiresOn ?? new Date()
                );
            } else {
                await this.trySilentTokenAcquisition();
            }

            const response = await this.loginUserToLinkApi();
            if (!response.resultObject) {
                throw new Error("unable to get User Login");
            }
            return response.resultObject;
        } catch (error) {
            // console.log("***Completed Azure SSO with error", error);
            throw new Error("unable to get User Login");
        }
    }

    public async trySilentTokenAcquisition(): Promise<void> {
        try {
            const azureTokenInformation =
                await this.getOrCreateAzureAccessToken(this.instance);

            // console.log("***Completed SSO Silent");
            saveAccessTokenInCookie(
                azureTokenInformation.accessToken,
                azureTokenInformation.expiresOn
            );
        } catch {
            // console.log("Error in saving access token.");
        }
    }

    public async login(): Promise<LoginResponse> {
        // console.log("login() of azure-authentication-strategy.ts");

        const msalInstanceConfig = this.instance.getConfiguration();
        if (msalInstanceConfig.auth.clientId === B2C_CONFIG.CLIENT_ID) {
            //You are connected to the correct this.instance, go ahead and log in.
            await this.instance.clearCache();
            this.instance.loginRedirect({
                redirectStartPage: this.callbackUrl ?? siteMap.dashboards.user,
                scopes: [msalInstanceConfig.auth.clientId],
                extraQueryParameters: {
                    ui_locales: this.currentCulture,
                },
            });
        }
        return { shouldVerifyLogin: false };
    }

    public async forgotPassword(isComingFromLogin: boolean): Promise<void> {
        const msalInstanceConfig = this.instance.getConfiguration();
        this.instance.loginRedirect({
            scopes: [msalInstanceConfig.auth.clientId],
            redirectStartPage: isComingFromLogin
                ? siteMap.dashboards.user
                : window.location.pathname + window.location.search,
            authority: `https://${B2C_CONFIG.DOMAIN}/${B2C_CONFIG.TENANT}.onmicrosoft.com/${B2C_CONFIG.FORGOT_PASSWORD_POLICY}`,
            extraQueryParameters: { ui_locales: this.currentCulture },
        });
    }

    public async signUp(redirectStartPage: string): Promise<void> {
        const msalInstanceConfig = this.instance.getConfiguration();
        if (msalInstanceConfig.auth.clientId === B2C_CONFIG.CLIENT_ID) {
            //You are connected to the correct this.instance, go ahead and log in.
            await this.instance.clearCache();
            this.instance.loginRedirect({
                scopes: [B2C_CONFIG.CLIENT_ID],
                redirectStartPage: redirectStartPage,
                extraQueryParameters: {
                    option: "signUp",
                    ui_locales: this.currentCulture,
                },
            });
        }
    }

    public async logout() {
        removeAccessTokenFromCookie();
        // console.log(
        //     "IN LOGOUT:  Removed access token from cookie.  Redirecting to logout."
        // );
        await this.instance.clearCache();
        await this.instance.logoutRedirect({
            postLogoutRedirectUri: this.callbackUrl ?? siteMap.home,
        });
    }

    private getCustomStateAsJson() {
        const customState: AzureB2CCustomState = {
            startPage: this.callbackUrl,
        };
        return JSON.stringify(customState);
    }

    private getOrCreateAzureAccessToken = async (
        instance: IPublicClientApplication
    ): Promise<AzureTokenInformation> => {
        const accounts = instance.getAllAccounts();
        // Not logged in yet
        if (accounts.length === 0 && this.useSsoSilent) {
            return await createAzureAccessToken(
                instance,
                this.config.auth.clientId
            );
        }
        // Already logged
        else {
            // console.log("***Getting Azure Access Token");
            // console.log("***Instance:", instance);
            // console.log("***Accounts:", accounts);
            // console.log("***Client ID:", this.config.auth.clientId);
            return await getAzureAccessToken(
                instance,
                accounts,
                this.config.auth.clientId
            );
        }
    };

    private async loginUserToLinkApi() {
        return await AuthService.create();
    }
}
