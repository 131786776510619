import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import UserRoleRecord from "models/view-models/user-role-record";
import { ModalTransitions } from "molecules/constants/modal-transitions";
import { ModalTypes } from "molecules/constants/modal-types";
import Modal from "molecules/modals/modal";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import OfflineAlertModal from "organisms/modals/offline-alert-modal/offline-alert-modal";
import React, { useState } from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import RoleType from "utilities/enumerations/role-type";
import { useLocalization } from "utilities/hooks/use-localization";
import useRefreshIdentity from "utilities/hooks/use-refresh-identity";
import { t } from "utilities/localization-utils";
import UserConfigurationService from "utilities/services/user-configurations/user-configuration-service";
import UserLoginService from "utilities/services/user-logins/user-login-service";
import { ToastManager } from "utilities/toast/toast-manager";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SwitchProfileModalProps {
    closeDialog: () => void;
    isVisible: boolean;
    onSwitchProfile: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_BASE_CLASS_NAME = "c-switch-profile-modal";
const SWITCH_PROFILE_SUCCESSFUL = "You have successfully switched profiles.";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SwitchProfileModal: React.FunctionComponent<SwitchProfileModalProps> = (
    props
) => {
    const {
        closeDialog,
        isVisible,
        onSwitchProfile: onSwitchProfileComplete,
    } = props;

    const { isOnline } = useNetworkInformation();
    const [switchingProfile, setSwitchingProfile] = useState<boolean>(false);
    const { globalState } = useGlobalState();
    const { t } = useLocalization();

    const { update: updateUserLoginApi } = UserLoginService.useUpdate();
    const { get: getUserConfigurationApi } = UserConfigurationService.useGet();

    const menuLabel = t("switchProfile");
    const switchingMessage = t("switchProfile-loadingMessage");
    const header = t("switchProfile-header");

    const { refresh: refreshIdentity } = useRefreshIdentity();

    const handleClick = async (userRoleId?: number, roleId?: number) => {
        if (userRoleId == null || roleId == null) {
            return;
        }

        try {
            const dto = globalState.currentIdentity?.userLogin?.with({
                roleId,
                userRoleId,
            });
            setSwitchingProfile(true);
            const userLoginResponse = await updateUserLoginApi(dto!);
            if (userLoginResponse.result?.hasErrors()) {
                setSwitchingProfile(false);
                displaySwitchError();
                return;
            }

            await getUserConfigurationApi({});

            await refreshIdentity(true);

            setTimeout(() => {
                closeDialog();
                onSwitchProfileComplete();
                ToastManager.success(SWITCH_PROFILE_SUCCESSFUL);
            }, 1500);
        } catch {
            setSwitchingProfile(false);
            displaySwitchError();
        }
    };

    const isMobile = window.innerWidth < Breakpoints.Phone;
    const transitionEffect = isMobile
        ? ModalTransitions.SlideUp
        : ModalTransitions.Fade;

    if (switchingProfile) {
        return <FullScreenTransition transitionText={switchingMessage} />;
    }

    if (!isOnline) {
        return (
            <OfflineAlertModal isOpen={isVisible} handleClose={closeDialog} />
        );
    }

    return (
        <Modal
            closeDialog={closeDialog}
            cssClassName={CSS_BASE_CLASS_NAME}
            isVisible={isVisible}
            label={menuLabel}
            transition={transitionEffect}
            type={ModalTypes.Base}>
            <div className={`${CSS_BASE_CLASS_NAME}__header`}>
                <h2>{header}</h2>
                <Button
                    style={ButtonStyles.Tertiary}
                    size={ButtonSizes.Medium}
                    onClick={closeDialog}
                    cssClassName={"-modal-close -icon"}
                    accessibleText="Close Dialog">
                    <Icon type={Icons.Close} size={IconSizes.Large} />
                </Button>
            </div>
            <div className={`${CSS_BASE_CLASS_NAME}__content`}>
                {globalState.currentIdentity
                    ?.getActiveUserRoles()
                    .map((ur: UserRoleRecord) => {
                        const selected =
                            ur.id ===
                            globalState.currentIdentity?.userLogin?.userRoleId;
                        return (
                            <button
                                className={`${CSS_BASE_CLASS_NAME}__card ${
                                    selected ? " -selected" : ""
                                }`}
                                disabled={selected}
                                key={ur.id}
                                onClick={() => handleClick(ur.id, ur.roleId)}>
                                <div
                                    className={`${CSS_BASE_CLASS_NAME}__card__header`}>
                                    <span>
                                        <Icon
                                            type={getIcon(ur.role?.roleType)}
                                            size={IconSizes.Large}
                                        />
                                        {ur.role?.getSubscriptionLabel()}
                                    </span>
                                    {!isMobile && getStatus(selected)}
                                </div>
                                {(ur.role?.is(RoleType.TEAM) ||
                                    ur.role?.is(RoleType.ENTERPRISE)) && (
                                    <div
                                        className={`${CSS_BASE_CLASS_NAME}__card__team-info`}>
                                        <span>
                                            {ur.userRoleGroup?.group?.name}
                                        </span>
                                    </div>
                                )}
                                {isMobile && getStatus(selected)}
                            </button>
                        );
                    })}
            </div>
        </Modal>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Helper Methods
// -----------------------------------------------------------------------------------------

const displaySwitchError = (): void => {
    ToastManager.error(
        "There was an issue switching your profile, refresh the page and try again."
    );
};

const getIcon = (roleType?: RoleType): Icons => {
    switch (roleType) {
        case RoleType.ENTERPRISE:
            return Icons.Enterprise;
        case RoleType.TEAM:
            return Icons.Team;
        default:
            return Icons.Individual;
    }
};

const getStatus = (selected: boolean): JSX.Element => {
    return (
        <React.Fragment>
            {selected && (
                <div className={`${CSS_BASE_CLASS_NAME}__card__status`}>
                    {t("selected")}
                </div>
            )}
        </React.Fragment>
    );
};

// #endregion Helper Methods

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default SwitchProfileModal;

// #endregion Export
