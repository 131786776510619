import { HeadingPriority } from "atoms/constants/heading-priority";
import Heading from "atoms/typography/heading";
import Paragraph from "atoms/typography/paragraph";
import { AppNameTm, AppNameWithHtml } from "constants/app-name-tm";
import UserConfigurationErrorKeys from "constants/user-configuration-error-keys";
import { siteMap } from "internal-sitemap";
import { MetaTag, MetaTagTypes } from "models/interfaces/header-data";
import GlobalStateRecord from "models/view-models/global-state-record";
import PlansAnchor from "molecules/plans-anchor/plans-anchor";
import SubscriptionFeatureList from "organisms/subscription-card/subscription-feature-list";
import NewUserLoginForm from "organisms/userlogins/userlogins-new-form/userlogins-new-form";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect } from "react-router-dom";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { SubscriptionFeaturesType } from "utilities/hooks/domain/subscriptions/use-subscription-features";
import { useLocalization } from "utilities/hooks/use-localization";
import CultureResources from "utilities/interfaces/culture-resources";
import { RouteUtils } from "utilities/route-utils";
import UserLoginService from "utilities/services/user-logins/user-login-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { Translator } from "utilities/types/translator-type";
import { useUrlCallback } from "utilities/routing/url-callback/use-url-callback";

// -----------------------------------------------------------------------------------------
// #region Interface
// -----------------------------------------------------------------------------------------

interface NewUserLoginPageProps {}

// #endregion Interface

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const translateMetaTags = (t: Translator<CultureResources>): Array<MetaTag> => [
    {
        name: MetaTagTypes.Title,
        content: `${AppNameTm}: ${t("signInOrCreateAccount")}`,
    },
    {
        name: MetaTagTypes.Description,
        content: t("newUserLoginPage-meta-description", { appName: AppNameTm }),
    },
];
const translatePageTitle = (t: Translator<CultureResources>) =>
    `${AppNameTm} ${t("signIn")}`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const NewUserLoginPage: React.FC<NewUserLoginPageProps> = () => {
    const { t } = useLocalization();
    const headerData = useMemo(
        () => ({
            title: translatePageTitle(t),
            metaTags: translateMetaTags(t),
        }),
        [t]
    );
    useHeaderData(headerData);

    const { callbackUrl } = useUrlCallback();

    const { globalState, setGlobalState } = useGlobalState();
    const [loading, setLoading] = useState<boolean>(false);
    const { unauthorizedResult } = globalState;
    const { delete: deleteUserLoginApi } = UserLoginService.useDelete();

    const hasUserConfigurationErrors =
        unauthorizedResult != null &&
        unauthorizedResult.hasErrorFor(
            UserConfigurationErrorKeys.ERROR_SELECT_ANOTHER_ROLE
        );
    const shouldRedirect =
        !hasUserConfigurationErrors && globalState.isAuthenticated();

    useEffect(() => {
        if (!hasUserConfigurationErrors || loading) {
            return;
        }

        // Let the user know why they are being logged out and steps to continue.
        ToastManager.error(t("newUserLoginPage-errors-selectAnotherRole"));

        const deleteUserLogin = async (userLoginId?: number) => {
            if (userLoginId == null) {
                return;
            }

            setLoading(true);

            try {
                await deleteUserLoginApi(userLoginId);
                setGlobalState((globalState: GlobalStateRecord) =>
                    globalState.clearUnauthorizedResult().setUnauthenticated()
                );
            } catch (error) {
                ToastManager.error(t("newUserLoginPage-errors-loggingOut"));
            }

            setLoading(false);
        };

        deleteUserLogin(globalState?.currentIdentity?.userLogin?.id);
    }, [
        deleteUserLoginApi,
        globalState,
        hasUserConfigurationErrors,
        loading,
        setGlobalState,
        t,
    ]);

    // Will need to be here still in case this route is bookmarked by users
    // But should also prevent users from getting here in the first place if the Azure B2C flag is on.
    if (!loading) {
        return <Redirect to={siteMap.home} />;
    }

    // If already logged in, redirect to dashboard or callback url.
    if (shouldRedirect) {
        return (
            <Redirect
                to={RouteUtils.getUrl(callbackUrl ?? siteMap.dashboards.user)}
            />
        );
    }

    return (
        <>
            <div className="c-login-layout__panel -left">
                <div className="c-login-layout__panel__form">
                    <NewUserLoginForm displayLoginToasts={true} />
                </div>
            </div>
            <div className="c-login-layout__panel -right">
                <Heading priority={HeadingPriority.Two}>
                    {t("createAnAccount").toUpperCase()}
                </Heading>
                <Paragraph>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t("newUserLoginPage-callToAction", {
                                appName: AppNameWithHtml,
                            }),
                        }}
                    />
                </Paragraph>
                <div className="c-login-layout__panel__features">
                    <SubscriptionFeatureList
                        type={SubscriptionFeaturesType.Individual}
                    />
                    <PlansAnchor cssClassName="c-button -secondary" />
                </div>
            </div>
        </>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default NewUserLoginPage;

// #endregion Export
