import IdentityProviderRecord from "models/view-models/identity-provider-record";
import ServiceFactory from "../service-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface IdentityProviderBySlugPathParams {
    identityProviderSlug: string;
}

export interface IdentityProviderPathParams {
    loginSlug: string;
}

// #endregion Interfaces
const resourceEndpoint = "identityproviders/:loginSlug";

const resourceType = IdentityProviderRecord;

const IdentityProvidersService = {
    get: ServiceFactory.get<IdentityProviderRecord, IdentityProviderPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

export default IdentityProvidersService;
