export enum AuthenticationStrategyType {
    AzureB2C = "AzureB2CStaging",
    MultiTenantAuth = "MultiTenantAuth",
    Emulation = "Emulation",
}

export enum AuthenticationLifeCycleStatus {
    Uninitialized = "Uninitialized",
    LoggedOut = "LoggedOut",
    Loading = "loading",
    LoggedIn = "LoggedIn",
    FailedToLogin = "FailedToLogin",
    FailedToVerifyLogin = "FailedToVerifyLogin",
    FailedToLogout = "FailedToLogout",
    FailedToEndEmulation = "FailedToEndEmulation",
}
