import { HeadingPriority } from "atoms/constants/heading-priority";
import SelectSizes from "atoms/constants/select-sizes";
import { SelectOption } from "atoms/forms/select";
import Heading from "atoms/typography/heading";
import _ from "lodash";
import TopicRecord from "models/view-models/topic-record";
import NoResultsBanner from "molecules/banners/no-results-banner";
import CallToAction, {
    CallToActionType,
} from "molecules/call-to-action/call-to-action";
import { SearchFormSizes } from "molecules/enums/search-form-sizes";
import { SearchFormStyles } from "molecules/enums/search-form-style";
import SearchForm from "molecules/forms/search-form";
import MultiCheckboxSelect from "molecules/multi-checkbox-select/multi-checkbox-select";
import PageNavigationMenu from "molecules/page-navigation/page-navigation-menu";
import FilterButton from "organisms/filter-button/filter-button";
import PublicationSelectionList from "organisms/modals/publication-selection-modal/publication-selection-list";
import TopicsFilterModal from "organisms/topics-filter-modal/topics-filter-modal";
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHeaderData } from "utilities/contexts/use-header-data-context";
import { AriaRole } from "utilities/enumerations/aria-role";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import RoleType from "utilities/enumerations/role-type";
import useFiles from "utilities/hooks/domain/files/use-files";
import usePreviewPublications from "utilities/hooks/domain/publications/public/use-preview-publications";
import useTopics from "utilities/hooks/domain/topics/public/use-topics";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import usePagedResults from "utilities/hooks/use-paged-results";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-all-publications-page";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AllPublicationsPage: React.FC = () => {
    const { t } = useLocalization();
    const { globalState } = useGlobalState();
    const { currentIdentity } = globalState;

    const headingText = `${t("available")} ${t("publication_plural")}`;

    useHeaderData({ title: headingText });
    const {
        currentPage,
        numberOfPages,
        onPageLast,
        onPageNext,
        onPageSizeChange,
        onSelectPage,
        resetPagination,
        setRowCount,
        skip,
        take,
    } = usePagedResults();

    const [searchText, setSearchText] = useState("");
    const [submittedSearchText, setSubmittedSearchText] = useState("");
    const [selectedTopicIds, setSelectedTopicIds] = useState<number[]>([]);

    const {
        filtering: isFiltering,
        searching: isSearching,
        resultObject: publications,
        loading: publicationsLoading,
        rowCount: publicationsRowCount,
    } = usePreviewPublications({
        searchText: submittedSearchText,
        skip,
        take,
        topicIds: selectedTopicIds,
    });

    const { resultObject: topics } = useTopics();
    const topicSelectOptions = useMemo(
        () => getFilteredAndSortedTopicSelectOptions(topics, selectedTopicIds),
        [topics, selectedTopicIds]
    );

    const isMobile = useBreakpoint(
        Breakpoints.Phone,
        BreakpointComparer.MaxWidth
    );

    useEffect(() => {
        resetPagination();
    }, [resetPagination, submittedSearchText]);

    useEffect(() => {
        setRowCount(publicationsRowCount ?? 0);
    }, [setRowCount, publicationsRowCount]);

    const fileIds =
        CollectionUtils.uniqueValuesByProperty(
            publications,
            "coverImageFileId"
        ) ?? [];
    const { resultObject: files } = useFiles({ ids: fileIds });

    const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.currentTarget.value);
    };

    const handleSearchClear = () => {
        setSubmittedSearchText("");
        setSearchText("");
        resetPagination();
    };

    const {
        isOpen: showTopicsFilterModal,
        handleOpen: handleTopicsModalOpen,
        handleClose: handleTopicsModalClose,
    } = useModalActions();

    const handleSelectedTopics = useCallback(
        (newValues: SelectOption<TopicRecord, number>[]) => {
            setSelectedTopicIds(newValues.map((nv) => nv?.data?.id ?? 0));
        },
        []
    );

    const handleResetFilters = () => {
        setSelectedTopicIds([]);
    };

    const handleModalFiltersConfirmed = (topicIds: Array<number>) => {
        setSelectedTopicIds(topicIds);
    };

    const contentClassName = `${BASE_CLASS_NAME}__content`;
    const renderCta =
        currentIdentity == null ||
        !currentIdentity.hasActiveSubscription() ||
        currentIdentity.isCurrentRole(RoleType.FREE_TRIAL);

    const accessibleResultCount = `${t("publication_plural")} (${
        publicationsRowCount ?? 0
    })`;

    const hasNoSearchResults =
        publicationsRowCount === 0 &&
        (StringUtils.hasValue(submittedSearchText) ||
            selectedTopicIds.length > 0);

    return (
        <div className={BASE_CLASS_NAME}>
            <div className={`${BASE_CLASS_NAME}__header`}>
                <Heading priority={HeadingPriority.Two}>{headingText}</Heading>
            </div>
            <div className={`${contentClassName}-wrapper`}>
                <div className={`${contentClassName}`}>
                    {renderCta && (
                        <CallToAction
                            message={t("featuredPublications-c2a-text")}
                            title={t("featuredPublications-c2a-buttonText")}
                            type={CallToActionType.Anchor}
                        />
                    )}

                    <div className={`${contentClassName}__controls`}>
                        <SearchForm
                            onClear={handleSearchClear}
                            onSearchClick={(e) =>
                                setSubmittedSearchText(searchText)
                            }
                            onSearchTextChange={handleSearchTextChange}
                            placeholder={t(
                                "publicationSelectionModal-searchPlaceholder"
                            )}
                            searchText={searchText}
                            size={isMobile ? SearchFormSizes.Small : undefined}
                            style={SearchFormStyles.Tertiary}
                        />

                        {!isMobile ? (
                            <MultiCheckboxSelect
                                cssClassName={`${contentClassName}__controls__topics-filter`}
                                filterType="topic"
                                onChange={handleSelectedTopics}
                                options={topicSelectOptions}
                                size={SelectSizes.Base}
                                values={selectedTopicIds}
                            />
                        ) : (
                            <Fragment>
                                <FilterButton
                                    count={selectedTopicIds.length}
                                    onClick={handleTopicsModalOpen}>
                                    Filter
                                </FilterButton>

                                <TopicsFilterModal
                                    closeDialog={handleTopicsModalClose}
                                    isVisible={showTopicsFilterModal}
                                    onConfirm={handleModalFiltersConfirmed}
                                    onReset={handleResetFilters}
                                    options={topicSelectOptions}
                                    selectedTopicIds={selectedTopicIds}
                                />
                            </Fragment>
                        )}
                    </div>
                    <div
                        className={`${contentClassName}__publications`}
                        tabIndex={0}>
                        <div>
                            <div
                                aria-live={
                                    publicationsLoading ||
                                    StringUtils.isEmpty(submittedSearchText)
                                        ? "off"
                                        : "polite"
                                }
                                className="sr-only"
                                role={AriaRole.Status}>
                                <span>{accessibleResultCount}</span>
                            </div>
                            <PublicationSelectionList
                                coverImageFiles={files}
                                displayMenuActions={false}
                                favorites={[]}
                                filtering={isFiltering}
                                onChangeFavorites={() => null}
                                publications={publications}
                                rowCount={publicationsRowCount}
                                searching={isSearching}
                                searchText={submittedSearchText}
                            />
                            {!publicationsLoading && hasNoSearchResults && (
                                <NoResultsBanner
                                    subtitle={t(
                                        "noSearchResultsBanner-subTitle"
                                    )}
                                    title={t("noSearchResultsBanner-title")}
                                />
                            )}
                        </div>
                        {StringUtils.hasValue(submittedSearchText) &&
                            !isSearching && (
                                <PageNavigationMenu
                                    currentPage={currentPage}
                                    numberOfPages={numberOfPages}
                                    onNavigateBack={onPageLast}
                                    onNavigateForward={onPageNext}
                                    onPageSizeChange={onPageSizeChange}
                                    onSelectPage={onSelectPage}
                                    pageSize={take}
                                    resultTotal={publicationsRowCount ?? 0}
                                />
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const getFilteredAndSortedTopicSelectOptions = (
    topics: Array<TopicRecord>,
    selectedTopicIds: Array<number>
): Array<SelectOption<TopicRecord, number>> =>
    _.chain(topics)
        .orderBy([orderBySelectedTopic(selectedTopicIds), "name"])
        .map(topicRecordToSelectOption)
        .value();

const orderBySelectedTopic =
    (selectedTopicIds: Array<number>) => (topic: TopicRecord) => {
        const isSelected =
            selectedTopicIds.findIndex(
                (selectedTopicId) => topic.id === selectedTopicId
            ) >= 0;

        return isSelected ? 0 : 1;
    };

const topicRecordToSelectOption = (
    topic: TopicRecord
): SelectOption<TopicRecord, number> => ({
    data: topic,
    label: topic.name,
    value: topic.id ?? 0,
});

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AllPublicationsPage;

// #endregion Exports
