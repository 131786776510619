import AccountDisplayInformation from "./account-information/account-display-information";
import AccountLanguagePreferences from "./account-information/account-language-preferences";
import Button from "atoms/buttons/button";
import Heading from "atoms/typography/heading";
import OrganizationErrorKey from "constants/organization-error-keys";
import OrganizationSupportModal from "organisms/organization-login/organization-support-modal";
import Paragraph from "atoms/typography/paragraph";
import React, { useEffect, useState } from "react";
import StringUtils from "utilities/string-utils";
import useAccountInformationTab from "pages/account/tabs/tab-panels/use-account-information-tab";
import useOrganizationSupport from "utilities/hooks/domain/organizations/use-organization-support";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface AccountOrganizationMemberInformationTabPanelProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-account-dashboard__information";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const AccountOrganizationMemberInformationTabPanel: React.FC<
    AccountOrganizationMemberInformationTabPanelProps
> = () => {
    const [showOrgSupportModal, setShowOrgSupportModal] = useState(false);
    const {
        email,
        emailLabel,
        emailNotAvailable,
        fullName,
        nameLabel,
        nameNotAvailable,
        organizationId,
    } = useAccountInformationTab();

    const { organizationSupport, error } = useOrganizationSupport({
        organizationId: organizationId,
    });

    useEffect(() => {
        if (error === OrganizationErrorKey.NO_ORGANIZATION_FOUND_ERROR) {
            ToastManager.error(t("error-loading-organization"));
        }
    }, [error]);

    return (
        <div className={BASE_CLASS}>
            <div className={`${BASE_CLASS}__personal`}>
                <Heading priority={HeadingPriority.Five}>
                    {t("yourProfile")}
                </Heading>
                <AccountDisplayInformation
                    heading={t("organization")}
                    information={
                        organizationSupport?.name ??
                        t("organizationNameUnavailable")
                    }
                />
                <AccountDisplayInformation
                    heading={nameLabel}
                    information={
                        StringUtils.isEmpty(fullName)
                            ? nameNotAvailable
                            : fullName!
                    }
                />
                <AccountDisplayInformation
                    heading={emailLabel}
                    information={
                        StringUtils.isEmpty(email) ? emailNotAvailable : email!
                    }
                />
                {organizationSupport && (
                    <div className={`${BASE_CLASS}__orgSupport`}>
                        <Paragraph
                            cssClassName={`${BASE_CLASS}__orgSupportHelpText`}>
                            {t("orgAccountSettingsSupportText")}
                        </Paragraph>
                        <Button
                            style={ButtonStyles.Secondary}
                            onClick={() => setShowOrgSupportModal(true)}>
                            {t("contactMyOrganization")}
                        </Button>
                        <OrganizationSupportModal
                            showModal={showOrgSupportModal}
                            onHideModal={() => setShowOrgSupportModal(false)}
                            organization={organizationSupport}
                        />
                    </div>
                )}
            </div>
            <AccountLanguagePreferences />
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default AccountOrganizationMemberInformationTabPanel;

// #endregion Exports
