import {
    Configuration,
    IPublicClientApplication,
    PublicClientApplication,
} from "@azure/msal-browser";

export class MsalFactory {
    private instance: IPublicClientApplication | null = null;

    public async getOrCreateMsalInstance(
        config: Configuration
    ): Promise<IPublicClientApplication> {
        if (this.instance) {
            return this.instance;
        }
        this.instance = new PublicClientApplication(config);
        await this.instance.initialize();

        return this.instance;
    }
}
