import { t } from "utilities/localization-utils";
import { SearchModalFiltersContainerProps } from "./search-modal-filters-container";
import CultureResources from "utilities/interfaces/culture-resources";
import SelectOptionUtils from "utilities/select-option-utils";
import {
    EnhancedContentEntityTypes,
    PublicationEntityTypeConstants,
} from "constants/publication-entity-type-constants";
import { SelectOption } from "atoms/forms/select";
import SearchModalFilterSection from "./search-modal-filter-section";
import CheckboxList from "molecules/checkbox-list/checkbox-list";
import React, { useMemo } from "react";
import { SearchModalResultType } from "../utils/search-modal-reducer";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";

interface SearchModalEnhancedContentFilterProps
    extends Pick<
        SearchModalFiltersContainerProps,
        | "enhancedContentTypes"
        | "onEnhancedContentTypesUpdated"
        | "searchResultType"
    > {}

const SearchModalEnhancedContentFilter = (
    props: SearchModalEnhancedContentFilterProps
) => {
    const {
        enhancedContentTypes = [],
        onEnhancedContentTypesUpdated,
        searchResultType,
    } = props;

    const { isOnline } = useNetworkInformation();

    const entityTypeOptions = SelectOptionUtils.map(
        EnhancedContentEntityTypes,
        entityTypeToSelectOption(isOnline)
    );

    const handleEntityTypesUpdated = (
        selectedOptions: Array<SelectOption<string, string>>
    ) => {
        const entityTypes = SelectOptionUtils.getValues(selectedOptions);
        onEnhancedContentTypesUpdated(entityTypes);
    };

    const contentText = t("enhancedContent");

    const isVisible = useMemo(
        () => searchResultType === SearchModalResultType.Codes,
        [searchResultType]
    );

    return (
        <SearchModalFilterSection
            isVisible={isVisible}
            panelText={contentText}
            panelTriggerAriaText={contentText}>
            <CheckboxList<string, string>
                disabled={!isOnline}
                onChange={handleEntityTypesUpdated}
                options={entityTypeOptions}
                values={!isOnline ? [] : enhancedContentTypes}
            />
        </SearchModalFilterSection>
    );
};

export default SearchModalEnhancedContentFilter;

const entityTypeToSelectOption =
    (isOnline: boolean) => (entityType: string) => {
        let label = t(
            `${entityType.toLowerCase()}_plural` as keyof CultureResources
        );

        if (!isOnline) label += ` (${t("unavailableOffline")})`;

        if (entityType === PublicationEntityTypeConstants.EnhancedContent) {
            label = t(`include${entityType}` as keyof CultureResources);
        }

        return {
            value: entityType,
            label,
        };
    };
