import OrganizationManifest from "models/interfaces/organization-manifest";
import { Record } from "immutable";

const defaultValues: OrganizationManifest = {
    name: "",
    loginSlug: "",
};

export default class OrganizationManifestRecord
    extends Record(defaultValues)
    implements OrganizationManifest
{
    // -----------------------------------------------------------------------------------------
    // #region Constructor
    // -----------------------------------------------------------------------------------------

    constructor(params?: OrganizationManifest) {
        if (params == null) params = Object.assign({}, defaultValues);

        super(params);
    }

    // #endregion Constructor
}
