import { RouteUtils } from "utilities/route-utils";
import { CollectionUtils } from "utilities/collection-utils";
import Anchor from "atoms/anchors/anchor";
import Button from "atoms/buttons/button";
import FileRecord from "models/view-models/file-record";
import PublicationRecord from "models/view-models/publication-record";
import FileUploadField from "molecules/form-fields/file-upload-field";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import FileUploadDestination from "utilities/enumerations/file-upload-destination";
import MimeTypes from "utilities/enumerations/mime-type";
import useFile from "utilities/hooks/domain/files/use-file";
import usePageErrors from "utilities/hooks/use-page-errors";
import AdminPublicationService from "utilities/services/admin/publications/admin-publication-service";
import AdminLegacyPublicationService from "utilities/services/admin/publications/admin-legacy-publication-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { useAdminEditorPageContext } from "utilities/contexts/admin/use-admin-editor-page-context";
import AdminEditorPageContextRecord from "models/view-models/situational-navigation/admin-editor-page-context-record";
import PdfPublicationImportForm, {
    PdfPublication,
} from "./pdf-publication-import-form";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const CSS_CLASS_NAME = "c-publication-create";

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublicationCreate: React.FC = () => {
    const history = useHistory();
    const { setContext } = useAdminEditorPageContext();
    const [publication, setPublication] = useState<PublicationRecord>(
        new PublicationRecord()
    );

    const [pdfPublication, setPdfPublication] = useState<PdfPublication>({
        title: "",
        code: "",
        edition: "",
        usePdfBookmarkTitles: false,
    });
    const [isLegacyPublication, setIsLegacyPublication] =
        useState<boolean>(false);

    const { file: contentSourceFile, loadingFile } = useFile(
        publication.contentSourceFileId
    );
    const { pageErrors, resetPageErrors, handlePageLoadError } =
        usePageErrors();

    const { create: createPublicationApi } =
        AdminPublicationService.useCreate();

    const { create: createLegacyPublicationApi } =
        AdminLegacyPublicationService.useCreate();

    const isFileLegacyPublication = (file?: FileRecord) => {
        if (file?.relativeProviderPath.toUpperCase().endsWith("PDF")) {
            return true;
        }
        return false;
    };

    const getFileUploadComponent = (): JSX.Element => {
        return (
            <FileUploadField
                accept={MimeTypes.Publication}
                confirmRemove={true}
                disabled={false}
                fileUploadDestination={FileUploadDestination.Publication}
                helpText={"Must be a .pdf or .zip"}
                label="Add Publication Data"
                onFileChanged={handlePublicationFileChange}
                uploadPathPrefix={"publications"}
                value={contentSourceFile}
            />
        );
    };

    const handlePublicationFileChange = (file?: FileRecord) => {
        setIsLegacyPublication(isFileLegacyPublication(file));

        setPublication(
            publication.with({
                code: file?.suggestedCode,
                edition: file?.suggestedEdition,
                locale: file?.suggestedLocale,
                contentSourceFileId: file?.id,
                contentSourceFile: file,
            })
        );

        setPdfPublication({
            ...pdfPublication,
            code: file?.suggestedCode ?? "",
            contentSourceFileId: file?.id,
            edition: file?.suggestedEdition ?? "",
        });
    };

    const handlePublicationCreate = async () => {
        if (publication.contentSourceFileId == null) {
            return;
        }

        try {
            resetPageErrors();
            let response: any;

            if (isLegacyPublication) {
                const newPublication =
                    PublicationRecord.fromPdfPublication(pdfPublication);
                response = await createLegacyPublicationApi(newPublication);
            } else {
                response = await createPublicationApi(publication);
            }
            if (response.result?.hasErrors() || response.resultObject == null) {
                ToastManager.error("Error creating publication");
                return;
            } else {
                if (isLegacyPublication) {
                    ToastManager.success(
                        "Processing legacy publication import"
                    );
                }
            }

            // Refresh list through context
            setContext((prevState: AdminEditorPageContextRecord) =>
                prevState.with({ shouldFetchNavItems: true })
            );

            history.push(
                RouteUtils.getUrl(siteMap.admin.publications.edit, {
                    id: response.resultObject.id,
                })
            );
        } catch (response) {
            handlePageLoadError(response);
        }
    };

    useEffect(() => {
        if (CollectionUtils.isEmpty(pageErrors)) {
            return;
        }
        ToastManager.error(pageErrors);
    }, [pageErrors]);

    useEffect(() => {
        setContext((prevState: AdminEditorPageContextRecord) =>
            prevState.with({ publicationBatch: [] })
        );
    }, [setContext]);

    const hasEmptyCode =
        pdfPublication.code == null || pdfPublication.code === "";
    const hasEmptyEdition =
        pdfPublication.edition == null || pdfPublication.edition === "";
    const hasEmptyTitle =
        pdfPublication.title == null || pdfPublication.title === "";

    const isDisabledForPDF =
        publication.contentSourceFileId == null ||
        hasEmptyCode ||
        hasEmptyEdition ||
        hasEmptyTitle;
    const isDisabledForXML =
        publication.contentSourceFileId == null && !isLegacyPublication;

    const isDisabled =
        (isDisabledForPDF && isLegacyPublication) ||
        (isDisabledForXML && !isLegacyPublication);

    return (
        <div className={CSS_CLASS_NAME}>
            <div className={`${CSS_CLASS_NAME}__form`}>
                {!loadingFile && getFileUploadComponent()}
                {isLegacyPublication && (
                    <PdfPublicationImportForm
                        publication={pdfPublication}
                        setPublication={setPdfPublication}
                    />
                )}
            </div>
            <div className={`${CSS_CLASS_NAME}__footer`}>
                <Anchor
                    cssClassName="c-button -secondary"
                    to={siteMap.admin.publications.dashboard}>
                    Cancel
                </Anchor>
                <Button disabled={isDisabled} onClick={handlePublicationCreate}>
                    Import Publication
                </Button>
            </div>
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default PublicationCreate;

// #endregion Exports
