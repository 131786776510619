import { atom, useAtom } from "jotai";
import IdentityProviderRecord from "models/view-models/identity-provider-record";
import { useEffect, useState } from "react";
import LocalStorageKey from "utilities/enumerations/local-storage-keys";
import { LocalStorageUtils } from "utilities/local-storage-utils";
import IdentityProvidersService from "utilities/services/identity-providers/identity-providers-service";

const CurrentIdentityProviderAtom = atom<IdentityProviderRecord | undefined>(
    undefined
);

export function useCurrentIdentityProvider() {
    const [isLoadingIdentityProvider, setIsLoadingIdentityProvider] =
        useState(false);
    const [loginSlug, setLoginSlug] = useState<string>();
    const [currentIdentityProvider, setCurrentIdentityProvider] = useAtom(
        CurrentIdentityProviderAtom
    );

    const { get: getIdentityProvider } = IdentityProvidersService;

    const setIdentityProviderFromLocalStorage = () => {
        //grab from localstorage and rehydrate:
        const storedIdentityProviderRecord =
            LocalStorageUtils.get<IdentityProviderRecord>(
                LocalStorageKey.IdentityProvider
            );
        if (
            storedIdentityProviderRecord?.clientId !==
            currentIdentityProvider?.clientId
        ) {
            setCurrentIdentityProvider(storedIdentityProviderRecord);
        }
    };

    useEffect(() => {
        setIsLoadingIdentityProvider(true);
        async function fetchIdentityProvider() {
            if (!loginSlug) return;
            const identityProviderResult = await getIdentityProvider({
                loginSlug: loginSlug,
            });
            const identityProvider = identityProviderResult.resultObject;
            setCurrentIdentityProvider(identityProvider);

            LocalStorageUtils.set<IdentityProviderRecord>(
                LocalStorageKey.IdentityProvider,
                identityProvider!
            );
        }

        //look up the identity provider by organizationId
        if (currentIdentityProvider?.loginSlug !== loginSlug) {
            fetchIdentityProvider();
        }
        setIsLoadingIdentityProvider(false);
    }, [
        getIdentityProvider,
        loginSlug,
        setCurrentIdentityProvider,
        setIsLoadingIdentityProvider,
        currentIdentityProvider?.loginSlug,
    ]);

    return {
        setLoginSlug,
        currentIdentityProvider,
        setCurrentIdentityProvider,
        setIdentityProviderFromLocalStorage,
        isLoadingIdentityProvider,
    };
}
