import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import React from "react";
import { AuthenticationLifeCycleStatus } from "utilities/enumerations/authorization";
import { Redirect } from "react-router";
import { siteMap } from "internal-sitemap";
import { useAuthentication } from "utilities/contexts/authentication/authentication-provider";
import MaintenancePage from "pages/errors/maintenance";

const AzureLoginPage = () => {
    const { authenticationLifeCycleStatus } = useAuthentication();

    if (
        authenticationLifeCycleStatus === AuthenticationLifeCycleStatus.Loading
    ) {
        return <FullScreenTransition transitionText={""} />;
    }

    if (
        authenticationLifeCycleStatus ===
            AuthenticationLifeCycleStatus.FailedToLogin ||
        authenticationLifeCycleStatus ===
            AuthenticationLifeCycleStatus.FailedToVerifyLogin
    ) {
        return <Redirect to={siteMap.home} />;
    }

    return (
        <>
            <MaintenancePage />
        </>
    );
};

export default AzureLoginPage;
