import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Icon from "atoms/icons/icon";
import Paragraph from "atoms/typography/paragraph";
import VideoModal from "organisms/modals/video-modal/video-modal";
import React from "react";
import useModalActions from "utilities/hooks/use-modal-actions";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface VideoCardProps {
    videoDescription?: string;
    videoId?: string;
    videoThumbnailUrl?: string;
    videoTitle?: string;
    videoUrl?: string;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-video-card";
const BUTTON_CLASS_NAME = `${BASE_CLASS_NAME}__button`;

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const VideoCard: React.FC<VideoCardProps> = (
    props: React.PropsWithChildren<VideoCardProps>
) => {
    const {
        children,
        videoId,
        videoThumbnailUrl,
        videoDescription,
        videoTitle,
    } = props;
    const { isOpen, handleClose, handleOpen } = useModalActions();

    if (StringUtils.isEmpty(videoId)) {
        return null;
    }

    return (
        <div className={BASE_CLASS_NAME}>
            <button className={BUTTON_CLASS_NAME} onClick={handleOpen}>
                <div
                    className={`${BUTTON_CLASS_NAME}__thumbnail`}
                    style={{
                        backgroundImage: `url(${videoThumbnailUrl})`,
                    }}></div>
                <div className={`${BUTTON_CLASS_NAME}__footer`}>
                    <div className={`${BUTTON_CLASS_NAME}__icon`}>
                        <Icon size={IconSizes.Large} type={Icons.MediaPlay} />
                    </div>
                    <div className={`${BUTTON_CLASS_NAME}__footer__content`}>
                        <Paragraph>{children}</Paragraph>
                        {
                            // if
                            StringUtils.hasValue(videoDescription) && (
                                <div
                                    className={`${BUTTON_CLASS_NAME}__footer__label`}>
                                    <Paragraph size={ParagraphSizes.XSmall}>
                                        {StringUtils.truncateRight(
                                            videoDescription ?? "",
                                            100
                                        )}
                                    </Paragraph>
                                </div>
                            )
                        }
                    </div>
                </div>
            </button>
            <VideoModal
                autoplay={true}
                handleClose={handleClose}
                isOpen={isOpen}
                title={videoTitle ?? ""}
                videoId={videoId!}
            />
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default VideoCard;

// #endregion Exports
