import { BillingCycleOption } from "constants/billing-cycle-options";
import SignupContextRecord from "models/view-models/signup-context-record";
import SubscriptionType from "organisms/enums/subscription-type";
import { useState } from "react";
import { useSignupContext } from "utilities/contexts/signup/use-signup-context";
import useNfpaUrlPaths from "utilities/hooks/use-nfpa-url-paths";

export default function useSubscriptionCards() {
    const [showBillingModal, setShowBillingModal] = useState<boolean>(false);

    const [selectedSubscriptionType, setSelectedSubscriptionType] =
        useState<SubscriptionType>();
    const [selectedBillingCycle, setSelectedBillingCycle] =
        useState<BillingCycleOption>();
    const { requestQuote } = useNfpaUrlPaths();
    const { setSignupContext } = useSignupContext();

    const handleEnterpriseCardSelect = () => {
        window.open(requestQuote);
    };
    const handleIndividualCardSelect = () =>
        handleSelectClick(SubscriptionType.Individual);

    const handleSelectClick = (type: SubscriptionType) => {
        setShowBillingModal(true);
        setSelectedSubscriptionType(type);
        setSignupContext((signupContext: SignupContextRecord) =>
            signupContext.with({
                shouldResetPasswordField: true,
            })
        );
    };

    const handleTeamCardSelect = () => handleSelectClick(SubscriptionType.Team);

    const handleBillingCycleCheck = (option?: BillingCycleOption): void =>
        setSelectedBillingCycle(option);

    const resetModal = () => {
        setShowBillingModal(false);
        setSelectedSubscriptionType(undefined);
    };

    return {
        handleBillingCycleCheck,
        handleEnterpriseCardSelect,
        handleIndividualCardSelect,
        handleSelectClick,
        handleTeamCardSelect,
        resetModal,
        showBillingModal,
        selectedBillingCycle,
        selectedSubscriptionType,
    };
}
