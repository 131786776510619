import Button, { ButtonTypes } from "atoms/buttons/button";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import React from "react";
import CultureResources from "utilities/interfaces/culture-resources";
import { t } from "utilities/localization-utils";
import StringUtils from "utilities/string-utils";
import { ToastManager } from "utilities/toast/toast-manager";
const BASE_CSS_CLASS = "c-copy-text-button";
interface CopyTextButtonProps {
    textToCopy: string;
    successMessage?: keyof CultureResources;
    cssClassName?: string;
    textCssClassName?: string;
    iconCssClassName?: string;
}
const CopyTextButton = ({
    textToCopy,
    successMessage = "textCopied",
    cssClassName = "",
    textCssClassName = "",
    iconCssClassName = "",
}: CopyTextButtonProps) => {
    const handleClick = () => {
        navigator.clipboard.writeText(textToCopy);
        ToastManager.success(t(successMessage));
    };
    return StringUtils.hasValue(textToCopy) ? (
        <Button
            cssClassName={`${BASE_CSS_CLASS}__button ${cssClassName}`}
            style={ButtonStyles.None}
            type={ButtonTypes.Button}
            onClick={handleClick}>
            <span className={textCssClassName}>{textToCopy} </span>
            <Icon
                size={IconSizes.Base}
                type={Icons.Copy}
                cssClassName={`${BASE_CSS_CLASS}__icon ${iconCssClassName}`}
            />
        </Button>
    ) : (
        "--"
    );
};

export default CopyTextButton;
