import * as React from "react";
import CreateAccountAnchor from "molecules/create-account-anchor/create-account-anchor";
import FreeTrialTermsBox from "molecules/free-trial-terms-box/free-trial-terms-box";
import StringUtils from "utilities/string-utils";
import SubscriptionType from "organisms/enums/subscription-type";
import useStartFreeTrial from "utilities/hooks/domain/users/use-start-free-trial";
import { useEffect, useState } from "react";
import { useSignupContext } from "utilities/contexts/signup/use-signup-context";

export interface NewUserFormProps {
    /**
     * If present the email address will be prefilled and disabled
     */
    defaultEmail?: string;

    /**
     * A value is required if showing the free trial terms box.
     * Indicates user agreement to the free trial agreement.
     */
    freeTrialTermsAccepted?: boolean;

    /**
     * If the free trial agreement is showing, add the error message to it.
     */
    freeTrialAgreementError?: string;

    /**
     * Called when the accepted state of free trial terms changes.
     * @param accepted
     */
    onFreeTrialTermsAccepted?: (accepted: boolean) => void;

    /**
     * Called after successfully creating and logging in the user.
     */
    selectedSubscriptionType?: SubscriptionType;
}

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS = "c-new-user-form";

// #endregion Constants

const NewUserForm: React.FunctionComponent<NewUserFormProps> = (
    props: NewUserFormProps
) => {
    const { selectedSubscriptionType } = props;
    const { signupContext, setSignupContext } = useSignupContext();
    const { setCallbackLink } = useStartFreeTrial();

    // State
    const [disabled, setDisabled] = useState<boolean>(true);
    const isFreeTrial = selectedSubscriptionType === SubscriptionType.FreeTrial;

    useEffect(() => {
        if (
            StringUtils.hasValue(props.defaultEmail) &&
            StringUtils.isEmpty(signupContext.newUser.email)
        ) {
            setSignupContext(
                signupContext.with({
                    newUser: signupContext.newUser?.with({
                        email: props.defaultEmail,
                    }),
                })
            );
        }
    }, [props.defaultEmail, signupContext, setSignupContext]);

    const handleFreeTrialTermsAccepted = (accepted: boolean) => {
        setDisabled(!disabled);
        props.onFreeTrialTermsAccepted?.(accepted);
    };

    return (
        <div className={BASE_CLASS}>
            {isFreeTrial && (
                <FreeTrialTermsBox
                    accepted={props.freeTrialTermsAccepted ?? false}
                    error={props.freeTrialAgreementError}
                    onAcceptChange={handleFreeTrialTermsAccepted}
                />
            )}
            <CreateAccountAnchor
                redirectStartPage={setCallbackLink()}
                disabled={disabled}
                cssClassName="c-button"
            />
        </div>
    );
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default NewUserForm;

// #endregion Exports
