import {
    Configuration,
    InteractionStatus,
    IPublicClientApplication,
} from "@azure/msal-browser";
import {
    AuthenticationStrategy,
    LoginResponse,
} from "utilities/interfaces/authentication-strategy";
import { siteMap } from "internal-sitemap";
import AuthService from "../../auth-service";
import { AzureTokenInformation } from "utilities/services/auth/azure-strategies/azure-token-information";
import { createAzureAccessToken } from "utilities/services/auth/azure-strategies/create-azure-access-token";
import { getAzureAccessToken } from "utilities/services/auth/azure-strategies/get-azure-access-token";
import {
    getOrganizationAccessTokenFromCookie,
    removeOrganizationAccessTokenFromCookie,
    saveOrganizationAccessTokenInCookie,
} from "utilities/services/auth/azure-strategies/multi-tenant-authentication/organization-access-token";
import { RouteUtils } from "utilities/route-utils";
import UserLoginRecord from "models/view-models/user-login-record";

export class MultiTenantAuthenticationStrategy
    implements AuthenticationStrategy
{
    constructor(
        private isAuthenticatedUser: boolean,
        private inProgress: InteractionStatus,
        private useSsoSilent: boolean,
        private currentCulture: string,
        private callbackUrl: string,
        private instance: IPublicClientApplication,
        private config: Configuration,
        private tokenProperty: string | undefined,
        private organizationId: number,
        private userLogin: UserLoginRecord | undefined
    ) {}

    public async trySilentTokenAcquisition(): Promise<void> {
        try {
            const azureTokenInformation =
                await this.getOrCreateAzureAccessToken(this.instance);

            // console.log("***Completed SSO Silent");
            saveOrganizationAccessTokenInCookie(
                azureTokenInformation.accessToken,
                azureTokenInformation.expiresOn
            );
        } catch {
            // console.log("Unable to obtain access token.");
        }
    }

    public async verifyLogin(): Promise<UserLoginRecord> {
        if (this.isAuthenticatedUser && this.userLogin) {
            //This is short-circuiting and not allowing regeneration of the token:
            const accessToken = getOrganizationAccessTokenFromCookie();
            if (!accessToken) {
                await this.trySilentTokenAcquisition();
            }
            return this.userLogin;
        }

        // console.log("***Starting Multi-Tenant SSO");
        try {
            const responseRedirect =
                await this.instance.handleRedirectPromise(); //ALREADY HAS THE TOKEN
            if (responseRedirect != null) {
                // console.log("***Completed SSO handleRedirectPromise");

                const jwtToken =
                    this.tokenProperty?.toUpperCase() === "IDTOKEN"
                        ? responseRedirect.idToken
                        : responseRedirect.accessToken;

                saveOrganizationAccessTokenInCookie(
                    jwtToken,
                    responseRedirect.expiresOn ?? new Date()
                );
            } else {
                this.trySilentTokenAcquisition();
            }

            const response = await this.loginUserToLinkApi();
            if (!response.resultObject) {
                throw new Error("unable to get User Login");
            }

            return response.resultObject;
        } catch (error) {
            // console.log(
            //     "***Completed Multi-Tenant SSO Silent with error",
            //     error
            // );
            throw new Error("Multi-Tenant SSO failed with error.");
        }
    }

    public async login(): Promise<LoginResponse> {
        const msalInstanceConfig = this.instance.getConfiguration();
        if (msalInstanceConfig.auth.clientId === this.config.auth.clientId) {
            //You are connected to the correct this.instance, go ahead and log in.
            await this.instance.clearCache();
            this.instance.loginRedirect({
                scopes: [msalInstanceConfig.auth.clientId],
                redirectStartPage: this.callbackUrl ?? siteMap.dashboards.user,
                extraQueryParameters: {
                    ui_locales: this.currentCulture,
                    organizationId: this.organizationId?.toString(),
                },
            });
        }
        return Promise.resolve({ shouldVerifyLogin: false });
    }

    public async forgotPassword(isComingFromLogin: boolean): Promise<void> {
        throw new Error("Method not implemented.");
    }

    public async signUp(): Promise<void> {
        throw new Error("Method not implemented.");
    }

    public async logout(loginSlug?: string) {
        removeOrganizationAccessTokenFromCookie();
        await this.instance.clearCache();
        await this.instance.logoutRedirect({
            postLogoutRedirectUri: RouteUtils.replacePathParams(
                siteMap.userlogins.organization,
                { loginSlug: loginSlug }
            ),
        });
    }

    private async getOrCreateAzureAccessToken(
        instance: IPublicClientApplication
    ): Promise<AzureTokenInformation> {
        const accounts = instance.getAllAccounts().filter((account) => {
            return this.config.auth.authority?.includes(account.environment);
        });

        // Not logged in yet
        if (accounts.length === 0 && this.useSsoSilent) {
            return await createAzureAccessToken(
                instance,
                this.config.auth.clientId,
                this.tokenProperty === "idToken" ? "IDTOKEN" : "ACCESSTOKEN"
            );
        }
        // Already logged in
        else {
            return await getAzureAccessToken(
                instance,
                accounts,
                this.config.auth.clientId,
                this.tokenProperty === "idToken" ? "IDTOKEN" : "ACCESSTOKEN"
            );
        }
    }

    private async loginUserToLinkApi() {
        return await AuthService.create();
    }
}
