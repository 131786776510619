import AlertBanner from "molecules/alerts/alert-banner";
import AlertLevels from "constants/alert-levels";
import Button from "atoms/buttons/button";
import FullScreenTransition from "organisms/full-screen-transition/full-screen-transition";
import Heading from "atoms/typography/heading";
import OnboardingLayout from "templates/onboarding-layout";
import OrganizationSupportModal from "organisms/organization-login/organization-support-modal";
import Paragraph from "atoms/typography/paragraph";
import React, { useCallback, useEffect, useState } from "react";
import Trans from "atoms/i18n/trans";
import useFeatureFlags from "utilities/hooks/use-feature-flags";
import useLoading from "utilities/hooks/use-loading";
import useOrganizationManifest from "utilities/hooks/domain/organizations/use-organization-manifest";
import useOrganizationSupport from "utilities/hooks/domain/organizations/use-organization-support";
import {
    AuthenticationLifeCycleStatus,
    AuthenticationStrategyType,
} from "utilities/enumerations/authorization";
import { ButtonStyles } from "atoms/constants/button-styles";
import { HeadingPriority } from "atoms/constants/heading-priority";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { siteMap } from "internal-sitemap";
import { t } from "utilities/localization-utils";
import { useAuthentication } from "utilities/contexts/authentication/authentication-provider";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useCurrentIdentityProvider } from "utilities/atoms/current-identity-provider-atom";
import { ToastManager } from "utilities/toast/toast-manager";

interface OrganizationLoginPageParams {
    loginSlug: string;
}

export const ORGANIZATION_BASE_CLASS = "c-organization-login-page";

const OrganizationLoginPage = () => {
    const history = useHistory();
    const { allowOrganizationSSO } = useFeatureFlags();
    const { loginSlug } = useParams<OrganizationLoginPageParams>();
    const [showSupportModal, setShowSupportModal] = useState(false);

    const {
        setLoginSlug,
        currentIdentityProvider,
        setCurrentIdentityProvider,
        setIdentityProviderFromLocalStorage,
        isLoadingIdentityProvider,
    } = useCurrentIdentityProvider();

    const [showLoginButton, setShowLoginButton] = useState(false);

    const { inProgress } = useMsal();

    const { globalState } = useGlobalState();

    const [organizationNotFound, setOrganizationNotFound] = useState(false);

    const handleError = useCallback(() => {
        setOrganizationNotFound(true);
    }, []);

    const { isLoading: isLoadingOrganizationManifest, organizationManifest } =
        useOrganizationManifest({
            organizationId: currentIdentityProvider?.organizationId ?? -1,
            onError: handleError,
        });

    const { isLoading: isLoadingOrganizationSupport, organizationSupport } =
        useOrganizationSupport({
            organizationId: currentIdentityProvider?.organizationId ?? -1,
        });

    const loading = useLoading(
        isLoadingIdentityProvider,
        isLoadingOrganizationManifest,
        isLoadingOrganizationSupport
    );

    const { login, authenticationLifeCycleStatus } = useAuthentication();

    const handleLogin = async () => {
        login(AuthenticationStrategyType.MultiTenantAuth);
    };

    const handleSupportClick = () => {
        setShowSupportModal(true);
    };

    useEffect(() => {
        //DO NOT ALLOW THE ORGANIZATION ID TO CHANGE
        //WHEN THE USER IS ALREADY LOGGED IN
        if (globalState.isAuthenticated()) {
            if (
                loginSlug.toUpperCase() !==
                currentIdentityProvider?.loginSlug.toUpperCase()
            ) {
                ToastManager.error(
                    "You must logout before you can change your organization."
                );
            }
            return;
        }
        if (loginSlug == null) {
            return;
        }
        setLoginSlug(loginSlug);
    }, [loginSlug, currentIdentityProvider, globalState, setLoginSlug]);

    useEffect(() => {
        if (globalState.isAuthenticated()) {
            setShowLoginButton(false);
            return;
        }
        setIdentityProviderFromLocalStorage();
        setShowLoginButton(true);
    }, [
        currentIdentityProvider,
        globalState,
        setCurrentIdentityProvider,
        setIdentityProviderFromLocalStorage,
    ]);

    if (loading) {
        return <FullScreenTransition transitionText="" />;
    } else if (organizationNotFound || !allowOrganizationSSO) {
        return <Redirect to={siteMap.errors.notFound} />;
    }

    if (
        authenticationLifeCycleStatus ===
            AuthenticationLifeCycleStatus.LoggedIn &&
        globalState.isAuthenticated()
    ) {
        return <Redirect to={siteMap.dashboards.user} />;
    }

    const redirectToDashboard = () => {
        history.push(siteMap.dashboards.user);
    };

    return (
        <OnboardingLayout showActions={false}>
            {organizationManifest &&
                organizationSupport &&
                currentIdentityProvider && (
                    <section className={`${ORGANIZATION_BASE_CLASS}`}>
                        <Heading
                            priority={HeadingPriority.One}
                            cssClassName={`${ORGANIZATION_BASE_CLASS}__heading`}>
                            {t("orgLoginHeading")}
                        </Heading>
                        <Paragraph
                            cssClassName={`${ORGANIZATION_BASE_CLASS}__subheading`}>
                            {t("orgLoginSubheading")}
                        </Paragraph>
                        <Paragraph
                            cssClassName={`${ORGANIZATION_BASE_CLASS}__helpText`}>
                            <Trans i18nKey="orgLoginHelpText">
                                For issues logging in, please{" "}
                                <Button
                                    style={ButtonStyles.AnchorBlue}
                                    cssClassName={`${ORGANIZATION_BASE_CLASS}__supportLink`}
                                    onClick={handleSupportClick}>
                                    contact your organization for help
                                </Button>
                                .
                            </Trans>
                        </Paragraph>
                        {authenticationLifeCycleStatus ===
                            AuthenticationLifeCycleStatus.FailedToLogin && (
                            <div
                                className={`${ORGANIZATION_BASE_CLASS}__errorBanner`}>
                                <AlertBanner alertLevel={AlertLevels.Error}>
                                    {t(
                                        "weHaveEncounteredAnIssuePleaseTryAgainOrContactYourOrganizationForAssistance"
                                    )}
                                </AlertBanner>
                            </div>
                        )}
                        <div
                            className={`${ORGANIZATION_BASE_CLASS}__organizationBlock`}>
                            <span
                                className={`${ORGANIZATION_BASE_CLASS}__organizationLabel`}>
                                {t("organization")}:
                            </span>
                            <span
                                className={`${ORGANIZATION_BASE_CLASS}__organizationName`}>
                                {organizationManifest.name}
                            </span>
                        </div>
                        <Button
                            disabled={inProgress !== InteractionStatus.None}
                            cssClassName={`${ORGANIZATION_BASE_CLASS}__loginButton`}
                            onClick={() =>
                                showLoginButton
                                    ? handleLogin()
                                    : redirectToDashboard()
                            }>
                            {showLoginButton
                                ? t("logInToLink")
                                : t("backToDashboard")}
                        </Button>
                        <OrganizationSupportModal
                            showModal={showSupportModal}
                            organization={organizationSupport!}
                            onHideModal={() => {
                                setShowSupportModal(false);
                            }}
                        />
                    </section>
                )}
        </OnboardingLayout>
    );
};

export default OrganizationLoginPage;
