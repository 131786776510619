import ServiceHookFactory from "utilities/services/service-hook-factory";
import UserRoleOrganizationRecord from "models/view-models/user-role-organization-record";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface ListUserRoleOrganizationQueryParams {
    userRoleId: number;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Constants
// -------------------------------------------------------------------------------------------------

const baseEndpoint = "userroleorganizations";
const resourceType = UserRoleOrganizationRecord;

// #endregion Constants

// -------------------------------------------------------------------------------------------------
// #region Service
// -------------------------------------------------------------------------------------------------

const UserRoleOrganizationService = {
    useList: ServiceHookFactory.useList<
        UserRoleOrganizationRecord,
        ListUserRoleOrganizationQueryParams
    >(resourceType, baseEndpoint),
};

// #endregion Service

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default UserRoleOrganizationService;

// #endregion Exports
