/**
 * String constants mapping to values stored in browser local storage
 */
enum LocalStorageKey {
    Identity = "Identity",
    SystemSettings = "SystemSettings",
    NetworkState = "NetworkState",
    RefreshToken = "RefreshToken",
    ExternalTopics = "ExternalTopics",
    AuthStrategyType = "AuthStrategyType",
    IdentityProvider = "IdentityProvider",
}

export default LocalStorageKey;
