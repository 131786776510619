import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { AzureTokenInformation } from "utilities/services/auth/azure-strategies/azure-token-information";

export async function getAzureAccessToken(
    instance: IPublicClientApplication,
    accounts: AccountInfo[],
    clientId: string,
    propertyPath: string = "ACCESSTOKEN"
): Promise<AzureTokenInformation> {
    const result = await instance.acquireTokenSilent({
        scopes: [clientId], // TODO: do we need to provide scope for this?
        account: accounts[0], // TODO: do we need to provide account for this?
    });
    return {
        accessToken:
            propertyPath === "ACCESSTOKEN"
                ? result.accessToken
                : result.idToken,
        expiresOn: result.expiresOn ?? undefined,
    };
}
