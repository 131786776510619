import React from "react";
import SupportFaqPanel from "pages/support/support-faq-panel";
import { useLocalization } from "utilities/hooks/use-localization";
import Paragraph from "atoms/typography/paragraph";
import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import Anchor from "atoms/anchors/anchor";
import Trans from "atoms/i18n/trans";
import { AppNameTm } from "constants/app-name-tm";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

export interface OfflineFaqPanelsProps {}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ANCHOR_CLASS_NAME = "-anchor";
const ANSWER_CLASS_NAME = "-answer";
const U_LIST_CLASS_NAME = "-u-list";

const OfflineFaqPanels: React.FunctionComponent<OfflineFaqPanelsProps> = (
    props: OfflineFaqPanelsProps
) => {
    const { t } = useLocalization();
    const { globalState } = useGlobalState();
    const systemSettings = globalState.getSystemSettings();

    return (
        <React.Fragment>
            <SupportFaqPanel
                buttonText={t("support-offline-use", { appName: AppNameTm })}>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    While connected to the internet, you can store frequently
                    used publications, bookmarks, notes and enhanced content to
                    your device for offline use with supported browsers (see
                    browser requirements below for more details).
                </Paragraph>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    Any content you store for offline access will be available
                    when using the same account, device, browser or app. Logging
                    in with a different account, device or browser will require
                    you to re-download the content to that device.
                </Paragraph>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    To access your offline content, you need to have an active
                    session running. We recommend logging into NFPA LiNK® prior
                    to going offline to confirm the content you need is stored
                    on that device and to ensure you have an active session
                    running.
                </Paragraph>
            </SupportFaqPanel>
            <SupportFaqPanel buttonText={t("support-howDoIAccessOffline")}>
                <Trans i18nKey="support-howDoIAccessOffline_answer">
                    <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                        Your offline content can be accessed through the device,
                        browser, or app it was stored to. Once losing
                        connectivity, go to{" "}
                        <Anchor
                            cssClassName={ANCHOR_CLASS_NAME}
                            external={true}
                            target={AnchorTargetTypes.Blank}
                            to={systemSettings.nfpaBaseUrl!}>
                            link.nfpa.org
                        </Anchor>{" "}
                        or the installed application and your offline
                        publications will be shown on your main dashboard. Any
                        bookmarks, notes and enhanced content you have saved for
                        offline use will be shown inline when viewing those
                        publications. You can also search these publications
                        while in offline mode.
                    </Paragraph>
                    <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                        Here are a few optional steps that offer streamlined
                        access and a more optimal experience:
                        <ul className={U_LIST_CLASS_NAME}>
                            <li>
                                When using a computer, you have the option to
                                install the NFPA LiNK® web application. You can
                                do this by clicking the install icon in the far
                                right side of your browser bar.
                            </li>
                            <li>
                                On mobile devices, you can add NFPA LiNK® to
                                your home screen by selecting the ‘Add to Home
                                Screen’ option. This will add an application
                                icon for NFPA LiNK® to your phone to help
                                streamline access.
                            </li>
                        </ul>
                    </Paragraph>
                </Trans>
            </SupportFaqPanel>
            <SupportFaqPanel buttonText={t("support-newBookmarksWhileOnline")}>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    {t("support-newBookmarksWhileOnline_answer")}
                </Paragraph>
            </SupportFaqPanel>
            <SupportFaqPanel buttonText={t("support-howMuchContent")}>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    {t("support-howMuchContent_answer")}
                </Paragraph>
            </SupportFaqPanel>
            <SupportFaqPanel buttonText={t("support-howDoIDeleteContent")}>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    {t("support-howDoIDeleteContent_answer")}
                </Paragraph>
            </SupportFaqPanel>
            <SupportFaqPanel buttonText={t("support-whatHappensClearCookies")}>
                <Trans i18nKey="support-whatHappensClearCookies_answer">
                    <ul className={U_LIST_CLASS_NAME}>
                        <li>
                            On a computer, clearing browser cache should not
                            delete any of the content you have stored for
                            offline use on that device, browser, or installed
                            app.
                        </li>
                        <li>
                            On a mobile device, clearing browser cache will
                            delete all content you have stored for offline use
                            on that device, browser, or home screen app.
                        </li>
                    </ul>
                </Trans>
            </SupportFaqPanel>
            <SupportFaqPanel buttonText={t("support-concernedWithSecurity")}>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    {t("support-concernedWithSecurity_answer")}
                </Paragraph>
            </SupportFaqPanel>
            <SupportFaqPanel buttonText={t("support-foundInAppStore")}>
                <Paragraph cssClassName={ANSWER_CLASS_NAME}>
                    {t("support-foundInAppStore_answer")}
                </Paragraph>
            </SupportFaqPanel>
            <SupportFaqPanel buttonText={t("support-offlineLimitations")}>
                <Trans i18nKey="support-offlineLimitations_answer">
                    <ul className={U_LIST_CLASS_NAME}>
                        <li>
                            Users must be logged into NFPA LiNK® to access
                            their offline content. We recommend logging in prior
                            to going offline to confirm the content you need is
                            stored on that device/browser or app, and to ensure
                            you have an active session running.
                        </li>
                        <li>
                            After 20 days without a connectivity, NFPA LiNK®
                            will display a warning that your session will expire
                            in 1 day.
                        </li>
                        <li>
                            After 21 days without a connectivity, NFPA LiNK®
                            will end your offline session and prompt you to
                            re-connect to validate that an active subscription
                            still exists.
                        </li>
                        <li>
                            Offline access is compatible with the following
                            browsers: Chrome v45+, Safari v11.1+ / iOS 11.3+,
                            Edge v17+, Firefox v44+.
                        </li>
                    </ul>
                </Trans>
            </SupportFaqPanel>
        </React.Fragment>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default OfflineFaqPanels;

// #endregion Export
