import AlertLevels from "constants/alert-levels";
import AlertNotice from "molecules/banners/alert-notice";
import LocalizationUtils from "utilities/localization-utils";
import React, { useCallback, useMemo } from "react";
import { AuthenticationStrategyType } from "utilities/enumerations/authorization";
import { siteMap } from "internal-sitemap";
import { useAuthentication } from "utilities/contexts/authentication/authentication-provider";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import { useHistory } from "react-router-dom";
import { useLocalization } from "utilities/hooks/use-localization";

const FrozenSubscriptionsAlert: React.FC<any> = (props: any) => {
    const history = useHistory();
    const { isAuthenticated, globalState } = useGlobalState();
    const { login } = useAuthentication();
    const { t } = useLocalization();

    const currentCultureCode =
        LocalizationUtils.currentCultureCode() ?? "en-US";

    const subscriptionsOfflineConfigurableAlert =
        globalState.systemSettings?.configurableAlertMessages
            ?.subscriptionsOffline;

    const alertMessageContents =
        currentCultureCode === "en-US"
            ? subscriptionsOfflineConfigurableAlert?.messageContents.enUs
            : subscriptionsOfflineConfigurableAlert?.messageContents.esEs;

    const buttonText = useMemo(() => {
        return isAuthenticated ? t("backToDashboard") : t("logIn");
    }, [t, isAuthenticated]);

    const onClick = useCallback(() => {
        isAuthenticated
            ? history.push(siteMap.dashboards.user)
            : login(AuthenticationStrategyType.AzureB2C);
    }, [history, isAuthenticated, login]);

    return (
        <div className="c-frozen-subscriptions-page">
            <AlertNotice
                buttonText={buttonText}
                description={alertMessageContents?.message}
                level={AlertLevels.Warning}
                onClick={onClick}
                title={alertMessageContents?.title}
            />
        </div>
    );
};

export default FrozenSubscriptionsAlert;
