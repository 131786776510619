import OrganizationErrorKey from "constants/organization-error-keys";
import OrganizationManifestRecord from "models/view-models/organization-manifest-record";
import OrganizationManifestsService from "utilities/services/organizations/organization-manifests-service";
import { ToastManager } from "utilities/toast/toast-manager";
import { t } from "utilities/localization-utils";
import { useCallback, useEffect, useState } from "react";

const defaultErrorHandler = (errorMessage: string) =>
    ToastManager.error(errorMessage);

export interface UseOrganizationManifestOptions {
    organizationId: number;
    onError?: () => void;
}

export default function useOrganizationManifest(
    options: UseOrganizationManifestOptions
) {
    const { organizationId, onError } = options;
    const errorMessage = t("error-loading-organization");

    const [isLoading, setIsLoading] = useState(true);
    const [organizationManifest, setOrganizationManifest] = useState<
        OrganizationManifestRecord | undefined
    >();

    // Handlers
    const handleError = useCallback(() => {
        if (onError != null) {
            onError();
            return;
        }

        defaultErrorHandler(errorMessage);
    }, [errorMessage, onError]);

    const get = useCallback(async () => {
        try {
            if (organizationId <= 0) {
                return;
            }

            setIsLoading(true);

            const result = await OrganizationManifestsService.get({
                organizationId,
            });

            if (result.resultObject === undefined) {
                throw new Error(
                    OrganizationErrorKey.NO_ORGANIZATION_FOUND_ERROR
                );
            }

            setOrganizationManifest(result.resultObject);
        } catch (error) {
            handleError();
        } finally {
            setIsLoading(false);
        }
    }, [handleError, organizationId]);

    useEffect(() => {
        get();
    }, [get]);

    return {
        get,
        isLoading,
        organizationManifest,
    };
}
