import Anchor from "atoms/anchors/anchor";
import { siteMap } from "internal-sitemap";
import Tooltip from "molecules/tooltips/tooltip";
import { LandingPageSectionIds } from "organisms/landing-page-sections/landing-page-section-wrapper";
import React, { PropsWithChildren } from "react";
import { t } from "utilities/localization-utils";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import useConfigurableAlertMessages from "utilities/hooks/use-configurable-alert-messages";
import { AnchorTargetTypes } from "@rsm-hcd/javascript-core";

interface PlansAnchorProps {
    cssClassName?: string;
    onClick?: () => void;
    target?: AnchorTargetTypes;
}

const PlansAnchor = (props: PropsWithChildren<PlansAnchorProps>) => {
    const {
        children = t("signUp"),
        cssClassName = "c-button -anchor",
        onClick,
        target,
    } = props;

    const { globalState } = useGlobalState();
    const isLoggedIn = globalState.isAuthenticated();
    const { subscriptionFreezeActive, alertMessageContents } =
        useConfigurableAlertMessages();

    // User Not Authenticated && Use Azure flag TRUE = TRUE - not logged in, azure flag on - lead to anchor
    // User Not Authenticated && Use Azure flag FALSE = FALSE - anchor doesn't exist when azure false
    // User Authenticated && Use Azure flag TRUE = FALSE -  authenticated user never sees landing page
    // User Authenticated && Use Azure flag FALSE = FALSE - ditto ^
    const usePlansAnchor = !isLoggedIn;

    const to = usePlansAnchor
        ? `${siteMap.home}#${LandingPageSectionIds.Plans}`
        : siteMap.signup.plans;

    return (
        <Tooltip
            cssClassName="with-outline-border"
            content={alertMessageContents?.tooltip}
            disabled={!subscriptionFreezeActive}>
            <Anchor
                disabled={subscriptionFreezeActive}
                cssClassName={cssClassName}
                onClick={onClick}
                target={target}
                to={to}>
                {children}
            </Anchor>
        </Tooltip>
    );
};

export default PlansAnchor;
