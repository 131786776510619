import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import WorkGroupCollection from "models/interfaces/work-groups/work-group-collection";
import WorkGroupRecord from "models/view-models/work-group/work-group-record";
import MenuButton from "molecules/menu-button/menu-button";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import { TooltipPlacement } from "molecules/tooltips/tooltip";
import DataTable from "organisms/data-tables/data-table";
import React, { useState } from "react";
import useWorkGroupDeleteService from "utilities/hooks/domain/work-groups/use-work-group-delete-service";
import useWorkGroupUpdateService from "utilities/hooks/domain/work-groups/use-work-group-update-service";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import WorkGroupModal from "./work-group-modal";

interface WorkGroupManageTableProps {
    workGroups: WorkGroupRecord[];
}

const WorkGroupManageTable: React.FC<WorkGroupManageTableProps> = (props) => {
    const { workGroups } = props;
    const workGroupDeleteService = useWorkGroupDeleteService();

    const { loading, updateWorkGroup } = useWorkGroupUpdateService();

    const { handleClose, handleOpen, isOpen } = useModalActions();

    const [deleteWorkGroupId, setDeleteWorkGroupId] = useState<null | number>(
        null
    );

    const [existingWorkGroup, setExistingWorkGroup] =
        useState<WorkGroupRecord>();

    const handleManageWorkGroupClick = (workGroup: WorkGroupRecord) => {
        // TODO: more expressive name
        handleOpen();
        setExistingWorkGroup(updateWorkGroupWithSortedMembers(workGroup));
    };

    const showModal = existingWorkGroup != null;

    const { t } = useLocalization();

    const manageWorkGroup = t("workGroup-manage-group-members");
    const deleteWorkGroupMessage = t("workgroup-delete-message");
    const confirmButtonText = t("yesAction", {
        action: t("delete"),
    });
    const cancelButtonText = t("workgroup-delete-cancel");
    const deleteConfirmationLabel = t("workgroup-delete-confirmation-label");
    const handleDeleteConfirmed = () => {
        if (deleteWorkGroupId == null) {
            return;
        }
        workGroupDeleteService.deleteWorkGroup(deleteWorkGroupId);
        setDeleteWorkGroupId(null);
    };

    const handleDeleteConfirmation = (id: number) => {
        setDeleteWorkGroupId(id);
    };

    const BASE_CLASS = "c-account-dashboard__workgroups";
    const BASE_CLASS_TABLE_CONTAINER = `${BASE_CLASS}__table-container`;
    const BASE_CLASS_TABLE = `${BASE_CLASS_TABLE_CONTAINER}__table`;
    return (
        <>
            <div className={BASE_CLASS_TABLE_CONTAINER}>
                <DataTable cssClassName={BASE_CLASS_TABLE}>
                    <thead className={`${BASE_CLASS_TABLE}__header`}>
                        <tr>
                            <th className={`${BASE_CLASS_TABLE}__header__name`}>
                                {workGroups.length} {t("workgroups")}
                            </th>
                            <th
                                className={`${BASE_CLASS_TABLE}__header__members`}>
                                {t("workgroup-members")}
                            </th>
                            <th
                                className={`${BASE_CLASS_TABLE}__header__collections`}>
                                {t("shared-collections")}
                            </th>
                            <th
                                className={`${BASE_CLASS_TABLE}__header__actions`}>
                                &nbsp;
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {workGroups.map((workGroup: WorkGroupRecord) => (
                            <tr key={workGroup.id}>
                                <td>{workGroup.title}</td>
                                <td className="c-cell-alt">
                                    {workGroup.workGroupMembers?.length}{" "}
                                    {t("workgroup-members")}
                                </td>
                                <td>
                                    {workGroup.workGroupCollections?.map(
                                        (collection: WorkGroupCollection) => (
                                            <div
                                                key={collection.collectionId}
                                                className={`${BASE_CLASS_TABLE}__pill`}>
                                                {collection.name}
                                            </div>
                                        )
                                    )}
                                </td>
                                <td
                                    className={`${BASE_CLASS_TABLE}__row-cell__actions`}>
                                    <MenuButton
                                        buttonAccessibleText={t(
                                            "workgroup-manage-workgroups"
                                        )}
                                        offlineAlertOptions={{
                                            enabled: true,
                                            tooltipHeader: t(
                                                "workgroup-manage-workgroups"
                                            ),
                                            tooltipDescription: t(
                                                "workgroup-manage-workgroups"
                                            ),
                                            tooltipPlacement:
                                                TooltipPlacement.Top,
                                        }}
                                        triggerButtonSize={ButtonSizes.Small}>
                                        <Button
                                            onClick={() =>
                                                handleManageWorkGroupClick(
                                                    workGroup
                                                )
                                            }
                                            style={ButtonStyles.Tertiary}>
                                            {manageWorkGroup}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                handleDeleteConfirmation(
                                                    workGroup.id!
                                                );
                                            }}
                                            style={ButtonStyles.Tertiary}>
                                            {t("workgroup-delete")}
                                        </Button>
                                    </MenuButton>
                                </td>
                                <ConfirmationModal
                                    confirmButtonText={confirmButtonText}
                                    cancelButtonText={cancelButtonText}
                                    isVisible={
                                        deleteWorkGroupId === workGroup.id
                                    }
                                    label={deleteConfirmationLabel}
                                    message={deleteWorkGroupMessage}
                                    onCancel={() => setDeleteWorkGroupId(null)}
                                    onConfirm={handleDeleteConfirmed}
                                />
                            </tr>
                        ))}
                    </tbody>
                </DataTable>
            </div>
            {showModal && (
                <WorkGroupModal
                    heading={manageWorkGroup}
                    isVisible={isOpen}
                    closeDialog={handleClose}
                    existingWorkGroup={existingWorkGroup}
                    loading={loading}
                    onSave={updateWorkGroup}
                />
            )}
        </>
    );
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

export default WorkGroupManageTable;

const updateWorkGroupWithSortedMembers = (workGroup: WorkGroupRecord) =>
    workGroup.with({
        ...workGroup,
        workGroupMembers: workGroup.workGroupMembers?.sort((a, b) =>
            a.name.localeCompare(b.name)
        ),
    });
