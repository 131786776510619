import { RouteUtils } from "utilities/route-utils";
import { siteMap } from "internal-sitemap";
import { useCallback } from "react";
import { useAuthentication } from "utilities/contexts/authentication/authentication-provider";
import { AuthenticationStrategyType } from "utilities/enumerations/authorization";

const useAzure = () => {
    const { signUpRedirect, forgotPasswordRedirect } = useAuthentication();

    const signUp = useCallback(
        async (redirectStartPage) => {
            //call the login method

            signUpRedirect(
                AuthenticationStrategyType.AzureB2C,
                handleRedirect(redirectStartPage)
            );
        },

        [signUpRedirect]
    );

    const forgotPassword = useCallback(() => {
        forgotPasswordRedirect(AuthenticationStrategyType.AzureB2C, true);
    }, [forgotPasswordRedirect]);

    return {
        signUp,
        forgotPassword,
    };
};

export default useAzure;

const isComingFromLogin = () => RouteUtils.assertCurrentUrl(siteMap.home);

const handleRedirect = (redirectStartPage: string) => {
    if (redirectStartPage) {
        return redirectStartPage;
    } else if (isComingFromLogin()) {
        return siteMap.dashboards.user;
    } else {
        return window.location.pathname + window.location.search;
    }
};
