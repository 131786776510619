import ServiceFactory from "utilities/services/service-factory";
import UserLoginRecord from "../../../models/view-models/user-login-record";
import QueryHookFactory from "../../hooks/queries/query-hook-factory";
import ServiceHookFactory from "../service-hook-factory";

export interface AuthQueryParams {}

export interface AuthPathParams {
    id?: number;
}

const baseEndpoint = "auth";
const resourceEndpoint = `${baseEndpoint}/:id`;
const resourceType = UserLoginRecord;

const AuthService = {
    get: ServiceFactory.get<AuthQueryParams, AuthPathParams>(
        resourceType,
        resourceEndpoint
    ),
    useGetQuery: QueryHookFactory.useGetQuery<UserLoginRecord, AuthPathParams>(
        baseEndpoint,
        resourceType,
        resourceEndpoint
    ),

    create: ServiceFactory.create(resourceType, baseEndpoint),

    useCreate: ServiceHookFactory.useCreate(resourceType, baseEndpoint),

    useGet: ServiceHookFactory.useGet<UserLoginRecord, AuthPathParams>(
        resourceType,
        resourceEndpoint
    ),
};

export default AuthService;
