import Anchor from "atoms/anchors/anchor";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import Icon from "atoms/icons/icon";
import RemoteImage from "atoms/images/remote-image";
import Paragraph from "atoms/typography/paragraph";
import PublicationRecord from "models/view-models/publication-record";
import UserPublicationFavoriteRecord from "models/view-models/user-publication-favorite-record";
import { ConfirmationModal } from "molecules/modals/confirmation-modal";
import OfflineBookStatusIcon from "molecules/offline-book-status-icon/offline-book-status-icon";
import Tooltip, { TooltipPlacement } from "molecules/tooltips/tooltip";
import PublicationOptionsMenu from "organisms/modals/publication-selection-modal/publication-options-menu";
import React from "react";
import { CollectionUtils } from "utilities/collection-utils";
import { CustomColorUtils } from "utilities/custom-color-utils";
import { PublicationColor } from "utilities/enumerations/publication-color";
import useOfflineBooks from "utilities/hooks/domain/offline/use-offline-books";
import useOfflineDevice from "utilities/hooks/domain/offline/use-offline-device";
import useUpdatePublicationFavorite from "utilities/hooks/domain/user-publication-favorites/use-update-publication-favorite";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import NumberUtils from "utilities/number-utils";
import BookService from "utilities/services/books/book-service";
import FileService from "utilities/services/file-service";
import StringUtils from "utilities/string-utils";

// -------------------------------------------------------------------------------------------------
// #region Interfaces
// -------------------------------------------------------------------------------------------------

export interface PublicationCardProps {
    publication: PublicationRecord;
    favorites: Array<UserPublicationFavoriteRecord>;
    onChangeFavorites: (
        favorites: Array<UserPublicationFavoriteRecord>
    ) => void;
}

// #endregion Interfaces

// -------------------------------------------------------------------------------------------------
// #region Component
// -------------------------------------------------------------------------------------------------

const PublicationCard: React.FC<PublicationCardProps> = (
    props: PublicationCardProps
) => {
    const { publication, favorites, onChangeFavorites } = props;

    const CSS_CLASS_NAME = "c-publication-card";

    const anchorId = `publication-card-${publication.id!}`;

    const { get: getFileApi } = FileService.useGetQuery();
    const getFileResult = getFileApi(
        { id: publication.coverImageFileId! },
        undefined,
        { enabled: !NumberUtils.isDefault(publication.coverImageFileId) }
    );
    const imageFile = getFileResult.data?.resultObject;

    const { list: listBooks } = BookService.useListQuery();
    const { data: books } = listBooks({
        code: publication.code,
        edition: publication.edition,
    });
    const { resultObject: currentDevice } = useOfflineDevice();
    const { resultObject: offlineBooks } = useOfflineBooks({
        offlineDeviceId: currentDevice?.id,
    });

    const currentBook = CollectionUtils.first(books?.resultObjects);
    const offlineBook = offlineBooks.find(
        (b) =>
            b.bookId === currentBook?.id &&
            b.offlineDeviceId === currentDevice?.id
    );

    const { unfavorite, isFavorited } = useUpdatePublicationFavorite(
        publication,
        favorites,
        onChangeFavorites
    );

    const {
        handleClose: handleUnfavoriteConfirmationClose,
        isOpen: isUnfavoriteConfirmationOpen,
    } = useModalActions();

    const { t } = useLocalization();

    const handleUnfavoritePublication = async () => {
        handleUnfavoriteConfirmationClose();
        await unfavorite();
    };

    const classNames = [CSS_CLASS_NAME];
    if (publication.coverImageFileId == null) {
        classNames.push("-no-image");
    }

    const colorModifier = CustomColorUtils.getClassModifier(
        PublicationColor,
        publication.getColorOrDefault(),
        "publication"
    );

    return (
        <div className={classNames.join(" ")} data-test-favorite={isFavorited}>
            <OfflineBookStatusIcon
                book={currentBook}
                offlineBook={offlineBook}
            />
            <Anchor
                id={anchorId}
                dataTestCode={publication.code}
                dataTestEdition={publication.edition}
                to={publication.getRoute()}>
                <div
                    className={`${CSS_CLASS_NAME}__color-bar ${colorModifier}`}
                />
                <div className={`${CSS_CLASS_NAME}__detail`}>
                    <div className={`${CSS_CLASS_NAME}__detail__top`}>
                        <label className="-edition">
                            {publication.edition}
                            {publication.getIsLegacy() && (
                                <Tooltip
                                    content={t("readOnly")}
                                    cssClassName={``}
                                    disabled={false}
                                    durationInMs={0}
                                    hideOnClick={true}
                                    placement={TooltipPlacement.Right}>
                                    <div
                                        className={`${CSS_CLASS_NAME}__detail__top__legacy-icon-container`}>
                                        <Icon
                                            cssClassName={`${CSS_CLASS_NAME}__detail__top__legacy-icon`}
                                            type={Icons.Scroll}
                                            size={IconSizes.Base}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </label>
                        <Paragraph>
                            {StringUtils.hasValue(publication.code)
                                ? `NFPA ${publication.code}`
                                : publication.getDisplayTitle()}
                        </Paragraph>
                        <label className="-title">
                            {StringUtils.hasValue(publication.code)
                                ? publication.title
                                : ""}
                        </label>
                    </div>
                </div>
                <div
                    className={`${CSS_CLASS_NAME}__image-container ${colorModifier}`}>
                    {imageFile != null && <RemoteImage file={imageFile} />}
                </div>
            </Anchor>
            <PublicationOptionsMenu
                favorites={favorites}
                onChangeFavorites={onChangeFavorites}
                publication={publication}
            />
            <ConfirmationModal
                isVisible={isUnfavoriteConfirmationOpen}
                message={t("confirmationMessage", {
                    action: t("unfavorite").toLowerCase(),
                    item: t("publication").toLowerCase(),
                })}
                onCancel={handleUnfavoriteConfirmationClose}
                onConfirm={handleUnfavoritePublication}
            />
        </div>
    );
};

// #endregion Component

// -------------------------------------------------------------------------------------------------
// #region Exports
// -------------------------------------------------------------------------------------------------

export default PublicationCard;

// #endregion Exports
