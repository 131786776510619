import { Record } from "immutable";
import BookviewLayoutContext from "./bookview-layout-context";

const defaultValues: BookviewLayoutContext = {
    bookmarkReadonlyOpenForExternalId: undefined,
    bookmarkSettingsOpenForExternalId: undefined,
    bookviewContentExpanded: true, //default to expanded
};

export default class BookviewLayoutContextRecord
    extends Record(defaultValues)
    implements BookviewLayoutContext
{
    constructor(params?: BookviewLayoutContext) {
        if (params == null) params = Object.assign({}, defaultValues);

        super(params);
    }

    /**
     * Merges new values into the record and returns a new instance.
     *
     * @param {Partial<BookviewLayoutContext>} values
     */
    public with(
        values: Partial<BookviewLayoutContext>
    ): BookviewLayoutContextRecord {
        return new BookviewLayoutContextRecord(
            Object.assign(this.toJS(), values)
        );
    }
}
