import Button from "atoms/buttons/button";
import { ParagraphSizes } from "atoms/constants/paragraph-sizes";
import Loader from "atoms/loaders/loader";
import Paragraph from "atoms/typography/paragraph";
import AlertLevels from "constants/alert-levels";
import { OfflineSearchIndexStatus } from "models/enumerations/offline/offline-search-index-status";
import PublicationRecord from "models/view-models/publication-record";
import AlertBanner from "molecules/alerts/alert-banner";
import SearchForm from "molecules/forms/search-form";
import OfflineAlertModal from "organisms/modals/offline-alert-modal/offline-alert-modal";
import { SearchModalResultType } from "organisms/modals/search-modal/utils/search-modal-reducer";
import React from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { AriaRole } from "utilities/enumerations/aria-role";
import { Breakpoints } from "utilities/enumerations/breakpoints";
import useOfflineSearchIndexStatus from "utilities/hooks/aspect/search/offline/use-offline-search-index";
import useBreakpoint, {
    BreakpointComparer,
} from "utilities/hooks/use-breakpoint";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import CultureResources from "utilities/interfaces/culture-resources";
import StringUtils from "utilities/string-utils";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

interface SearchModalHeaderProps {
    directResultsCount?: number;
    directSearching: boolean;
    favoritePublications?: Array<PublicationRecord>;
    hintText?: string;
    onSearchClear: () => void;
    onSearchTextChange: (text: string) => void;
    onSubmit: () => void;
    publicationResultsCount?: number;
    publications?: Array<PublicationRecord>;
    publicationSearching: boolean;
    searchText: string;
    selectedResultType: SearchModalResultType;
    setSelectedResultType: (type: SearchModalResultType) => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-search-modal-header";
const CODES_AND_STANDARDS_KEY: keyof CultureResources =
    "searchModal-CodesAndStandards";
const DIRECT_KEY: keyof CultureResources = "nav-tooltip-direct-title";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SearchModalHeader: React.FunctionComponent<SearchModalHeaderProps> =
    React.memo((props) => {
        const {
            directResultsCount,
            directSearching,
            hintText,
            onSearchClear,
            onSearchTextChange,
            onSubmit,
            publicationResultsCount,
            publicationSearching,
            searchText,
            selectedResultType,
            setSelectedResultType,
        } = props;
        const { t } = useLocalization();
        const { isOnline } = useNetworkInformation();
        const isMobile = useBreakpoint(
            Breakpoints.Tablet,
            BreakpointComparer.MaxWidth
        );

        const {
            isOpen: isOfflineAlertModalOpen,
            handleClose: handleOfflineAlertModalClose,
            handleOpen: handleOfflineAlertModalOpen,
        } = useModalActions();

        const indexStatus = useOfflineSearchIndexStatus();

        const getActiveClassName = (type: SearchModalResultType) =>
            selectedResultType === type ? "-active" : "";

        const handleDirectClick = () => {
            if (isOnline) {
                setSelectedResultType(SearchModalResultType.Direct);
                return;
            }

            if (!isMobile) {
                return;
            }

            handleOfflineAlertModalOpen();
        };

        const handleSearchFieldChange = (
            e: React.ChangeEvent<HTMLInputElement>
        ) => {
            const value = e.target.value;
            onSearchTextChange(value);
        };

        const codesTabClassName = getActiveClassName(
            SearchModalResultType.Codes
        );

        const getDirectTabText = (count?: number): string => {
            if (isOnline) {
                return getTabText(DIRECT_KEY, count);
            }

            return `${t(DIRECT_KEY)} (${t("unavailableOffline")})`;
        };

        const getTabAccessibleText = (
            key: keyof CultureResources,
            count?: number,
            searching: boolean = false
        ): string => {
            if (searching) {
                return "";
            }

            return t("searchModal-SearchResultsFound", {
                count: count,
                key: t(key),
            });
        };

        const getTabText = (
            key: keyof CultureResources,
            count?: number
        ): string => {
            if (StringUtils.isEmpty(searchText)) {
                return t(key);
            }

            return `${t(key)} (${count ?? 0})`;
        };

        const codesTabAccessibleText = getTabAccessibleText(
            CODES_AND_STANDARDS_KEY,
            publicationResultsCount,
            publicationSearching
        );
        const codesTabText = getTabText(
            CODES_AND_STANDARDS_KEY,
            publicationResultsCount
        );
        const directTabAccessibleText = getTabAccessibleText(
            DIRECT_KEY,
            directResultsCount,
            directSearching
        );
        const directTabText = getDirectTabText(directResultsCount);
        const directTabClassName = getActiveClassName(
            SearchModalResultType.Direct
        );

        const offlineLoadingText = `${t("offline-loading-text")}...`;

        return (
            <div className={BASE_CLASS_NAME}>
                <SearchForm
                    aria-label={t("search")}
                    ariaRole={AriaRole.Search}
                    hintText={hintText}
                    onClear={onSearchClear}
                    onSearchClick={onSubmit}
                    onSearchTextChange={handleSearchFieldChange}
                    placeholder={t("searchPlaceholder")}
                    searchText={searchText}
                />
                {!isOnline && (
                    <div className={`${BASE_CLASS_NAME}__offline-warning`}>
                        <AlertBanner alertLevel={AlertLevels.Warning}>
                            {t("searchModal-OfflineWarning")}
                        </AlertBanner>
                        {indexStatus === OfflineSearchIndexStatus.INDEXING && (
                            <div
                                className={`${BASE_CLASS_NAME}__offline-warning__loader`}>
                                <Paragraph size={ParagraphSizes.Small}>
                                    {offlineLoadingText}
                                </Paragraph>
                                <Loader />
                            </div>
                        )}
                    </div>
                )}
                <div
                    aria-live={publicationSearching ? "off" : "polite"}
                    className="sr-only"
                    role={AriaRole.Status}>
                    {publicationResultsCount != null && (
                        <Paragraph>{codesTabAccessibleText}</Paragraph>
                    )}
                </div>
                <div
                    aria-live={directSearching ? "off" : "polite"}
                    className="sr-only"
                    role={AriaRole.Status}>
                    {directResultsCount != null && isOnline && (
                        <Paragraph>{directTabAccessibleText}</Paragraph>
                    )}
                </div>
                <div
                    className={`${BASE_CLASS_NAME}__tabs-container`}
                    role={AriaRole.TabList}>
                    <Button
                        accessibleText={codesTabAccessibleText}
                        ariaRole={AriaRole.Tab}
                        cssClassName={`${BASE_CLASS_NAME}__tab ${codesTabClassName}`}
                        onClick={() =>
                            setSelectedResultType(SearchModalResultType.Codes)
                        }>
                        {codesTabText}
                        <div className="-active-border" />
                    </Button>
                    <Button
                        accessibleText={directTabAccessibleText}
                        ariaRole={AriaRole.Tab}
                        cssClassName={`${BASE_CLASS_NAME}__tab ${directTabClassName}`}
                        disabled={!isOnline && !isMobile}
                        onClick={handleDirectClick}>
                        {directTabText}
                        <div className="-active-border" />
                    </Button>
                </div>
                {isOfflineAlertModalOpen && (
                    <OfflineAlertModal
                        isOpen={true}
                        handleClose={handleOfflineAlertModalClose}
                    />
                )}
            </div>
        );
    });

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default SearchModalHeader;

// #endregion Export
