import Button from "atoms/buttons/button";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import { IconSizes } from "atoms/constants/icon-sizes";
import { Icons } from "atoms/constants/icons";
import ChangeIndicatorIcon from "atoms/icons/change-indicator-icon";
import TiaIndicatorIcon from "atoms/icons/tia-indicator-icon";
import { XmlChangeNotationConstants } from "constants/xml-change-notation-constants";
import { List } from "immutable";
import { PartRecord } from "internal";
import TiaType from "models/enumerations/tia-type";
import AnnexRecord from "models/view-models/annex-record";
import ArticleRecord from "models/view-models/article-record";
import ChapterRecord from "models/view-models/chapter-record";
import EnhancedContentRecord from "models/view-models/enhanced-content-record";
import EnhancedContentResourceRecord from "models/view-models/enhanced-content-resource-record";
import SectionRecord from "models/view-models/section-record";
import TiaRecord from "models/view-models/tia-record";
import MenuButton from "molecules/menu-button/menu-button";
import EnhancedContentModal, {
    EnhancedContentModalProps,
} from "organisms/enhanced-content/enhanced-content-modal";
import AddEditChangeSummariesModal from "organisms/panels/change-indicator-diff/change-summaries/add-edit-change-summaries-modal";
import React from "react";
import useNetworkInformation from "utilities/contexts/network-information/use-network-information";
import { useGlobalState } from "utilities/contexts/use-global-state-context";
import PublicationComponentType from "utilities/enumerations/publication-component-type";
import { useLocalization } from "utilities/hooks/use-localization";
import useModalActions from "utilities/hooks/use-modal-actions";
import CultureResources from "utilities/interfaces/culture-resources";
import { EnhancedContentRouteParams } from "utilities/services/enhanced-content-service-routes";
import EnhancedContentServiceFactory from "utilities/services/enhanced-content/enhanced-content-service-factory";

// -----------------------------------------------------------------------------------------
// #region Interfaces
// -----------------------------------------------------------------------------------------

type PublicationComponentRecord =
    | SectionRecord
    | ChapterRecord
    | AnnexRecord
    | ArticleRecord
    | PartRecord;

export interface PublicationComponentActionsProps
    extends Pick<EnhancedContentModalProps, "onSuccess"> {
    enhancedContent?: EnhancedContentRecord;
    handleTiaClick: (tiaExternalId: string) => void;
    onToggleEnhancedContentModal?: (isOpen: boolean) => void;
    parentId?: number;
    record: PublicationComponentRecord;
    recordLabel: keyof CultureResources;
    refreshResources?: () => void;
    resources?: List<EnhancedContentResourceRecord>;
    showEnhancedContentModal: boolean;
    tias: TiaRecord[];
    type: PublicationComponentType;
    visible: boolean;
    code?: string;
    edition?: string;
    handleDiffPanelOpen?: () => void;
}

// #endregion Interfaces

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

const BASE_CLASS_NAME = "c-publication-component-actions";

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PublicationComponentActions: React.FunctionComponent<
    PublicationComponentActionsProps
> = (props: PublicationComponentActionsProps) => {
    const {
        code,
        edition,
        enhancedContent,
        handleTiaClick,
        onSuccess,
        onToggleEnhancedContentModal,
        record,
        recordLabel,
        refreshResources,
        resources,
        showEnhancedContentModal,
        tias,
        type,
        visible,
        handleDiffPanelOpen,
    } = props;

    const { globalState } = useGlobalState();
    const { currentIdentity } = globalState;
    const { t } = useLocalization();
    const { isOnline } = useNetworkInformation();
    const { create } = EnhancedContentServiceFactory.useCreate(type);
    const { update } = EnhancedContentServiceFactory.useUpdate(type);

    const isAdminOrPublisher =
        currentIdentity?.isAdmin() || currentIdentity?.isAuthorOrPublisher();

    const menuButtonClassName =
        showEnhancedContentModal || !visible ? "-hidden" : "";
    const hasElevatedPermissions =
        currentIdentity?.isAdmin() || currentIdentity?.isAuthorOrPublisher();

    const handleCreate = (enhancedContent: EnhancedContentRecord) =>
        create(enhancedContent, buildRouteParams(record));

    const handleUpdate = (enhancedContent: EnhancedContentRecord) =>
        update(
            enhancedContent,
            buildRouteParams(record, { id: enhancedContent.id! })
        );

    const handleEnhancedContentSaveModalClose = () => {
        if (onToggleEnhancedContentModal) onToggleEnhancedContentModal(false);
    };

    const handleEnhancedContentSaveModalOpen = () => {
        if (onToggleEnhancedContentModal) onToggleEnhancedContentModal(true);
    };

    const {
        isOpen: isChangeSummaryModalOpen,
        handleClose: onAddChangeSummaryClose,
        handleOpen: onAddChangeSummaryClick,
    } = useModalActions();

    const showChangeSummariesModal =
        isChangeSummaryModalOpen && code != null && edition != null;

    const enhancedContentActionLabel = enhancedContent?.isPersisted()
        ? t("editItem", { item: t("enhancedContent") })
        : t("addItem", { item: t("enhancedContent") });
    const viewPriorVersionButtonText = t("viewChanges");

    const showViewPriorVersionsButton = record.doesTitleOrBodyHaveChanges();

    const changeNotation =
        record.changes === XmlChangeNotationConstants.NEW_MATERIAL
            ? XmlChangeNotationConstants.NEW_MATERIAL
            : XmlChangeNotationConstants.HAS_CHANGES;

    const showTias = tias!.length > 0;

    return (
        <div className={BASE_CLASS_NAME}>
            <MenuButton
                buttonAccessibleText={t("actionsForItem", {
                    item: t(recordLabel),
                })}
                cssClassName={menuButtonClassName}
                disabled={!isOnline}
                icon={Icons.MoreHorizontal}
                iconSize={IconSizes.Base}
                key={`menu-button-${menuButtonClassName}`}
                triggerButtonSize={ButtonSizes.Small}
                triggerButtonStyle={ButtonStyles.Tertiary}>
                {hasElevatedPermissions && recordLabel !== "part" && (
                    <Button onClick={handleEnhancedContentSaveModalOpen}>
                        {enhancedContentActionLabel}
                    </Button>
                )}
                {showViewPriorVersionsButton &&
                    (showTias ? (
                        <Button
                            accessibleText={t("viewChanges")}
                            ariaControls={"diff-panel"}
                            onClick={() =>
                                handleTiaClick(tias![0].externalId!)
                            }>
                            <TiaIndicatorIcon
                                cssClassName="c-icon"
                                type={tias![0].type ?? TiaType.Change}
                            />
                            {viewPriorVersionButtonText}
                        </Button>
                    ) : (
                        <Button
                            accessibleText={t("viewChanges")}
                            ariaControls={"diff-panel"}
                            onClick={handleDiffPanelOpen}>
                            <ChangeIndicatorIcon
                                cssClassName="c-icon"
                                changes={changeNotation}
                            />
                            {viewPriorVersionButtonText}
                        </Button>
                    ))}
                {showViewPriorVersionsButton && isAdminOrPublisher && (
                    <Button onClick={onAddChangeSummaryClick}>
                        {t("add-change-summary")}
                    </Button>
                )}
            </MenuButton>

            {showEnhancedContentModal && (
                <EnhancedContentModal
                    enhancedContent={enhancedContent}
                    externalId={record.externalId}
                    files={resources?.map((r) => r.fileDraft!).toList()}
                    onClose={handleEnhancedContentSaveModalClose}
                    onCreate={handleCreate}
                    onEditClick={handleEnhancedContentSaveModalOpen}
                    onSuccess={onSuccess}
                    onUpdate={handleUpdate}
                    refreshResources={refreshResources}
                    resources={resources}
                    title={record.getDisplayTitle()}
                />
            )}
            {showChangeSummariesModal && (
                <AddEditChangeSummariesModal
                    externalId={record.externalId}
                    title={record.getDisplayTitle()}
                    code={code!}
                    edition={edition!}
                    publicationComponentType={type}
                    onClose={onAddChangeSummaryClose}
                />
            )}
        </div>
    );
};

// #endregion Component

// -----------------------------------------------------------------------------------------
// #region Functions
// -----------------------------------------------------------------------------------------

const buildRouteParams = (
    record: PublicationComponentRecord,
    extraParams: Partial<EnhancedContentRouteParams> = {}
): EnhancedContentRouteParams => {
    if (record instanceof SectionRecord) {
        return extraParams;
    }

    if (record instanceof ChapterRecord) {
        return Object.assign({ chapterId: record.id }, extraParams);
    }

    if (record instanceof AnnexRecord) {
        return Object.assign({ annexId: record.id }, extraParams);
    }

    if (record instanceof ArticleRecord) {
        return Object.assign({ articleId: record.id }, extraParams);
    }

    throw TypeError("Unsupported type.");
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default PublicationComponentActions;

// #endregion Export
