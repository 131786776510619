import { IPublicClientApplication } from "@azure/msal-browser";
import { AzureTokenInformation } from "utilities/services/auth/azure-strategies/azure-token-information";

export async function createAzureAccessToken(
    instance: IPublicClientApplication,
    clientId: string,
    propertyPath: string = "ACCESSTOKEN"
): Promise<AzureTokenInformation> {
    const result = await instance.ssoSilent({
        scopes: [clientId], // TODO: do we need to provide scope for this?
    });
    return {
        accessToken:
            propertyPath === "ACCESSTOKEN"
                ? result.accessToken
                : result.idToken,
        expiresOn: result.expiresOn ?? undefined,
    };
}
