import { ServiceResponse } from "@rsm-hcd/javascript-core";
import SupportUserSubscriptionRecord from "models/view-models/support-user-subscription-record";
import React, { useState } from "react";
import { t } from "utilities/localization-utils";
import { ButtonSizes } from "atoms/constants/button-sizes";
import { ButtonStyles } from "atoms/constants/button-styles";
import Button from "atoms/buttons/button";

interface ExtendFreeTrialProps {
    supportUserSubscription: SupportUserSubscriptionRecord;
    updateFreeTrialExpDate: (
        supportUserSubscriptionRecord: SupportUserSubscriptionRecord,
        expiresOn: string
    ) => Promise<ServiceResponse<SupportUserSubscriptionRecord> | undefined>;
}

const BASE_CLASS = `c-support-user-detail-page__user-details__data`;
const USER_DETAIL_ITEM = `${BASE_CLASS}__item`;
const USER_DETAIL_HEADING = `${BASE_CLASS}__item__heading`;
const USER_DETAIL_VALUE = `${BASE_CLASS}__item__value`;
const USER_DETAIL_EXTEND = `${BASE_CLASS}__extend`;
const DATE_PICKER = `${USER_DETAIL_EXTEND}__date-picker`;

const dateFormat = "YYYY-MM-DD";

const ExtendFreeTrial = ({
    supportUserSubscription,
    updateFreeTrialExpDate,
}: ExtendFreeTrialProps) => {
    const [freeTrialExpirationDate, setFreeTrialExpirationDate] = useState(
        supportUserSubscription.formatExpirationDate(dateFormat)
    );
    const [showExtendFreeTrialInput, setShowExtendFreeTrialInput] =
        useState(false);

    const date = supportUserSubscription.formatExpirationDate();
    const text = supportUserSubscription.isExpired() ? "Ended" : "Ends";
    const freeTrialData = `Yes (${text} ${date})`;

    const onExtendExpirationDate = (e: any) => {
        setFreeTrialExpirationDate(e.target.value);
    };

    const showDateInput = () => {
        setShowExtendFreeTrialInput(true);
    };

    const onSubmit = async (e: any) => {
        await updateFreeTrialExpDate(
            supportUserSubscription,
            freeTrialExpirationDate
        );

        setShowExtendFreeTrialInput(false);
    };
    const onCancel = () => {
        setShowExtendFreeTrialInput(false);
    };

    return showExtendFreeTrialInput ? (
        <div className={USER_DETAIL_EXTEND}>
            <input
                className={DATE_PICKER}
                type="date"
                value={freeTrialExpirationDate}
                onChange={onExtendExpirationDate}
            />
            <Button
                size={ButtonSizes.Small}
                style={ButtonStyles.Tertiary}
                onClick={onSubmit}>
                {t("submit")}
            </Button>
            <Button
                size={ButtonSizes.Small}
                style={ButtonStyles.Tertiary}
                onClick={onCancel}>
                {t("cancel")}
            </Button>
        </div>
    ) : (
        <div className={USER_DETAIL_ITEM}>
            <span className={USER_DETAIL_HEADING}>free trial</span>
            <span className={USER_DETAIL_VALUE}>
                {freeTrialData}
                <>
                    &nbsp;|&nbsp;
                    <Button
                        onClick={showDateInput}
                        size={ButtonSizes.Medium}
                        style={ButtonStyles.Anchor}>
                        Extend
                    </Button>
                </>
            </span>
        </div>
    );
};

export default ExtendFreeTrial;
