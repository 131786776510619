import { useCallback } from "react";
import { AuthenticationStrategyType } from "utilities/enumerations/authorization";
import { AuthenticationStrategyResult } from "utilities/services/auth/authentication-strategy-result";
import { useAzureAuthenticationStrategy } from "utilities/services/auth/azure-strategies/azure-authentication/use-azure-authentication-strategy.hooks";
import { useMultiTenantAuthenticationStrategy } from "utilities/services/auth/azure-strategies/multi-tenant-authentication/use-multi-tenant-authentication-strategy.hooks";
import { useEmulationAuthenticationStrategy } from "utilities/services/auth/emulation-authentication/use-emulation-authentication-strategy.hook";

export function useAuthenticationFactory() {
    const { create: createAzureAuthenticationStrategy } =
        useAzureAuthenticationStrategy();
    const { create: createEmulationAuthenticationStrategy } =
        useEmulationAuthenticationStrategy();
    const { create: createMultiTenantAuthenticationStrategy } =
        useMultiTenantAuthenticationStrategy();

    const authenticationFactory = useCallback(
        async (
            authenticationStrategyType: AuthenticationStrategyType
        ): Promise<AuthenticationStrategyResult> => {
            switch (authenticationStrategyType) {
                case AuthenticationStrategyType.AzureB2C:
                    return await createAzureAuthenticationStrategy();
                case AuthenticationStrategyType.Emulation:
                    return await createEmulationAuthenticationStrategy();
                case AuthenticationStrategyType.MultiTenantAuth:
                    return createMultiTenantAuthenticationStrategy();
                default:
                    throw new Error("Invalid authentication strategy type");
            }
        },
        [
            createAzureAuthenticationStrategy,
            createEmulationAuthenticationStrategy,
            createMultiTenantAuthenticationStrategy,
        ]
    );

    return { authenticationFactory };
}
